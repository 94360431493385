import * as PIXI from 'pixi.js'
import { app } from "./appPixi.js";
import {
  dispatching,
  playLeftDrop,
  playOrangeBox,
  playRedBox,
  playRightDrop,
  playYellowBox,
} from "./bridge.js";
import { ballPool, balls, boxes, pegsForShine, totalLines } from "./gameUI.js";
import { config, isPixiStandAlone } from "./settings.js";

const appWidth = app.screen.width;
const appHeight = app.screen.height;

const aspectRatio = appWidth / appHeight;

const aspectRatioX = appWidth / config.originalWidth;
const aspectRatioY = appHeight / config.originalHeight;

function responsiveSettings (sprite) {
  const xResponsive = (x) => x * aspectRatioX + sprite.width / 2;
  const yResponsive = (y) => y * aspectRatioY + sprite.height / 2;
  sprite.xResp = (x) => xResponsive(x);
  sprite.yResp = (y) => yResponsive(y);

  sprite.positionResponsive = (x, y) =>
    sprite.position.set(xResponsive(x), yResponsive(y));

  const ar = aspectRatioX > aspectRatioY ? aspectRatioY : aspectRatioX;
  sprite.scaleResponsive = (s) => sprite.scale.set(s * ar);
}

export function spriteSettings (sprite, textureName, containerName) {
  sprite.texture = textureName;
  containerName.addChild(sprite);
  sprite.anchor.set(0.5);

  responsiveSettings(sprite);

  return sprite;
}

export function animatedSpriteSettings (sprite, frames, containerName) {
  sprite = new PIXI.AnimatedSprite(frames);
  sprite.textures = frames;
  containerName.addChild(sprite);
  sprite.anchor.set(0.5);
  responsiveSettings(sprite);
  return sprite;
}


export const updateBallRow = ({ ball, peg }) => {
  if (ball.currentRow < peg.rowNumber) ball.currentRow = peg.rowNumber;
};


export class ObjectPool {
  constructor (objectFactory, initialSize) {
    this.objectFactory = objectFactory;
    this.pool = [];

    // Create initial objects and add them to the pool
    for (let i = 0; i < initialSize; i++) {
      this.pool.push(this.objectFactory());
    }
  }

  // Get an object from the pool
  getObject () {
    if (this.pool.length > 0) {
      return this.pool.pop();
    } else {
      // If the pool is empty, create a new object
      return this.objectFactory();
    }
  }

  // Return an object to the pool
  releaseObject (object) {
    this.pool.push(object);
  }
}

export function countCharacters (str) {
  let charCount = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] !== ".") {
      charCount++;
    }
  }
  return charCount;
}
export const getDirection = (testPath, index) => (testPath[index] < 1 ? -1 : 1);

export class AnimationSprite {
  constructor (pegHolder, spriteY) {
    this.animationSprite = new PIXI.AnimatedSprite(
      PIXI.Assets.get("plinkoAnim").animations.frames
    );
    this.sprite = new PIXI.Sprite(PIXI.Assets.get("betCoin"));
    this.sprite.visible = false;

    this.animationSprite.visible = false;
    this.spriteY = spriteY;
    pegHolder.addChild(this.animationSprite, this.sprite);
    this.animationSprite.anchor.set(0.5);
    this.animationSprite.x = 0;
    this.animationSprite.y = spriteY - 150;
    this.animationSprite.animationSpeed = config.ball.animationSpeed;
    this.bounceRow = 0;
    this.bounceCol = 1;
    // this.animationSprite.play();
  }
  doNothing = (delta) => { }
  ballEnd = (delta) => {
    if (this.sprite.visible && boxes[0].sprite.y + boxes[0].width * 0.5 < this.sprite.y) {
      this.sprite.visible = false;
      this.animationSprite.drop = this.doNothing;
    }
  };
  moveBall = (delta) => {
    if (this.animationSprite.y <= this.spriteY - 10) {
      this.animationSprite.y += delta * 16;
    } else {
      this.animationSprite.y = this.spriteY - 10;
      this.animationSprite.play();
      this.animationSprite.drop = this.ballEnd;
    }
  };
  onLoop = function (testPathAnim) {
    this.animationSprite.animationSpeed = config.ball.animationSpeed;
    let scaleDir = getDirection(testPathAnim, this.bounceRow + 1);
    this.animationSprite.scale.set(
      scaleDir * config.ball.scaleForAnimation[totalLines - 8] * config.ball.ballScale,
      config.ball.scaleForAnimation[totalLines - 8] * config.ball.ballScale
    );
    this.sprite.scale.set(
      scaleDir * config.ball.scaleForAnimation[totalLines - 8],
      config.ball.scaleForAnimation[totalLines - 8]
    );

    let distanceDir = getDirection(testPathAnim, this.bounceRow);
    this.animationSprite.x +=
      config.pegs.spacing.width *
      0.5 *
      config.ball.scaleForAnimation[totalLines - 8] *
      distanceDir;
    this.animationSprite.y +=
      config.pegs.spacing.height *
      config.ball.scaleForAnimation[totalLines - 8];
    this.sprite.y = this.animationSprite.y;

    this.bounceCol += testPathAnim[this.bounceRow] < 1 ? 0 : 1;
    this.bounceRow++;
    if (this.bounceRow < pegsForShine.length) {
      pegsForShine[this.bounceRow][this.bounceCol].startShine();
      distanceDir < 0 ? playLeftDrop() : playRightDrop();
    }

    if (this.bounceRow + 1 > totalLines) {
      const index = balls.indexOf(this.animationSprite);
      if (index > -1) balls.splice(index, 1);
      this.bounceCol += testPathAnim[this.bounceRow] < 1 ? -1 : 0;
      boxes[this.bounceCol - 1].sprite.position.y = config.topMargin + 50;
      boxes[this.bounceCol - 1].sprite.boxState =
        boxes[this.bounceCol - 1].sprite.boxBounceEffectState;
      playSoundForBoxDrop(this.bounceCol - 1, totalLines + 1);
      this.releaseSpriteSetup();
      return;
    }
  };

  setVisiblity = (visible) => {
    this.animationSprite.visible = visible;
  };
  releaseSpriteSetup = () => {
    this.animationSprite.visible = false;
    this.sprite.x = this.animationSprite.x;
    this.sprite.anchor.set(0.5);
    this.sprite.visible = true;
    this.animationSprite.stop();
    this.sprite.visible = false;
    this.animationSprite.anchor.set(0.5);
    this.animationSprite.x = 0;
    this.animationSprite.y = this.spriteY - 150;
    this.animationSprite.animationSpeed = config.ball.animationSpeed;
    this.bounceRow = 0;
    this.bounceCol = 1;
    ballPool.releaseObject(this);
    dispatching();
  };
}

function playSoundForBoxDrop (index, totalBox) {
  if (index <= totalBox * 0.1 || index >= totalBox * 0.9) {
    playRedBox();
  } else if (index <= totalBox * 0.3 || index >= totalBox * 0.7) {
    playOrangeBox();
  } else {
    playYellowBox();
  }
}
