import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMyBetsPlinkoService, placeBetPlinkoGameService, postLightningBoardDetailsService } from 'network/services/plinkoGame.service'
import { fastModeBox, pixiCancelBall, pixiCreateBall, pixiStartMovingBall } from 'pixi-js-scripts/plinko-game/bridge'
import { setClientSeed, setDemoWallet, setServerSeedHash } from 'redux-thunk/redux/slices/gameSetting.slice'
import { setBetLock } from 'redux-thunk/redux/slices/plinkoGame.slice'
import { generateClientSeed } from 'utils/helperFunctions.utils'

export const placedBetPlinkoGame = createAsyncThunk('plinko-game/place-bet',
  async ({ payload, fastMode, isDemoGame = false }, thunkApi) => {
    try {
      const res = await placeBetPlinkoGameService({ ...payload, demo: isDemoGame })
      if (isDemoGame) {
        thunkApi.dispatch(setDemoWallet(res?.demoAmount))
      }
      if (res?.nextServerSeedHash) {
        const newClientSeed = generateClientSeed()
        thunkApi.dispatch(setClientSeed(newClientSeed))
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }
      if (fastMode) {
        fastModeBox(res.dropDetails)
        thunkApi.dispatch(setBetLock())
      } else {
        pixiStartMovingBall(res.dropDetails)
        pixiCreateBall()
      }
      return res
    } catch (error) {
      if (!fastMode) {
        pixiCancelBall()
      }
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBetsPlinko = createAsyncThunk('plinko-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsPlinkoService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const postLightningBoardDetails = createAsyncThunk('plinko-game/post-lightning-board-details', async (params, thunkApi) => {
  try {
    const res = await postLightningBoardDetailsService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0]?.description)
  }
})
