import { createSlice } from '@reduxjs/toolkit'
import { generateMoonpaySignature } from 'redux-thunk/thunk/moonpay.thunk'

const initialState = {
  signature: null
}

const moonpaySlice = createSlice({
  name: 'moonpaySlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateMoonpaySignature.fulfilled, (state, action) => {
        return {
          ...state,
          signature: action.payload
        }
      })
  }
})

// export const { } = moonpaySlice.actions

export default moonpaySlice.reducer
