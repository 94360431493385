export const isPixiStandAlone = false

const basePath = isPixiStandAlone ? '/public/assets/pixi/plinko-game/' : '/assets/pixi/plinko-game/';
export const path = {
  imagePath: basePath + "images/",
  spriteSheetsPath: basePath + "spriteSheets/",
  fontPath: basePath + "fonts/",
  audioPath: basePath + "audios/",

}


export const config = {
  maxLines: 16,
  originalWidth: 1080,
  originalHeight: 1080,
  topMargin: 740,
  rows: 8,
  pegs: {
    spacing: {
      width: 110,
      height: 100
    },
    pegsWidth: 30,
    shine: {
      animationSpeed: 1.3
    }
  },
  ball: {
    path: [0, 1, 0, 1, 0, 1, 0, 1],
    currentRow: 0,
    ballScale:1.6,
    animationSpeed: 0.80,// 0.9,
    scaleForAnimation: [1.9, 1.7, 1.52, 1.376, 1.26, 1.16, 1.054, 1, 0.93],
    bounceEffect: {
      //                 8    9    10     11     12     13     14    15    16
      scaleFactorScale: [1, 0.99, 0.89, 0.80, 0.79, 0.76, 0.74, 0.72, 0.56],
      scaleFactorX: [1, 0.888, 0.78, 0.73, 0.667, 0.65, 0.59, 0.542, 0.52],
      scaleFactorY: [1.01, 0.888, 0.796, 0.72, 0.655, 0.6, 0.555, 0.517, 0.48],
    }
  },
  box: {
   boxSpeed : 2.2,
   height:120,
   width:182,
   heightScale:4.5, //75
   widthScale:6.3
  },
  pinInFirstLine: 3,
  bgAssets: {
    speed: 0.06,
  },
}

export const gameDisplaySettings = {
  size: 1000,
  x: config.originalWidth / 2,
  y: (config.originalHeight / 2) - 40
}
