import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import { setShowPasswordUpdatedPopup } from 'redux-thunk/redux/slices/settings.slice'
import { updatePasswordImg } from 'components/ui-kit/Icons/png/index'
import './passwordUpdated.scss'

const PasswordUpdation = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)
  const { t } = useTranslation()

  const handleClosePopup = useCallback(() => {
    dispatch(setShowPasswordUpdatedPopup(false))
  }, [])

  return (
    <CommonPopup
      classes='update-password-modal'
      modalCloseHandler={handleClosePopup}
    >
      <div className='d-flex justify-content-center flex-column text-center'>
        <div className='popup-bg d-flex align-items-center justify-content-center'>
          <img src={updatePasswordImg} />
        </div>
        <div className='popup-content'>
          <h2 className='text-uppercase'>{t('passwordUpdated')}</h2>
          <p>{t('thatsOkayItHappens')} - <span> {user?.email || 'Email not available'} </span> </p>
          <button onClick={handleClosePopup} className='btn btn-secondary'>{t('play')}</button>
        </div>
      </div>
    </CommonPopup>
  )
}

export default PasswordUpdation
