import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { CasinoMenuIcon, ChatIcon, CryptoMenuIcon, HamburgerIcon, HeaderSearchIcon, SportsMenuIcon } from 'components/ui-kit/Icons/svg/index'
import { ROUTE_PATHS } from 'constants/index'
import { setShowChat, setShowNotification, setShowSearchPopup, toggleChat, toggleSearchPopup } from 'redux-thunk/redux/slices/settings.slice'
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'
import { setShowHowItWorksPopup, setShowRoiCalculatorPopup } from 'redux-thunk/redux/slices/crypto.slice'

const ResponsiveButtonBar = ({ sidenavExpand }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { width } = useWindowSize()
  const { showSideNav, showChat, showSearchPopup } = useSelector((state) => state?.settings)
  const { loginData } = useSelector((state) => state.auth)
  const { cryptoCoinsList, showRoiCalculator, showHowItWorksPopup } = useSelector((state) => state.crypto)
  // const { newAnnouncements } = useSelector(state => state.announcement)
  const { pathname } = useLocation()
  const history = useHistory()

  const handleMenuClick = () => {
    sidenavExpand()
    dispatch(setShowSearchPopup(false))
    if (width < 768) {
      showChat && dispatch(setShowChat(false))
    }
  }

  const handleSearchClick = useCallback(() => {
    dispatch(setShowChat(false))
    showSideNav && sidenavExpand()
    showRoiCalculator && dispatch(setShowRoiCalculatorPopup(false))
    showHowItWorksPopup && dispatch(setShowHowItWorksPopup(false))
    dispatch(toggleSearchPopup())
  }, [showSideNav, showRoiCalculator, showHowItWorksPopup])

  const handleChatClick = useCallback(() => {
    dispatch(toggleChat())
    history.push(ROUTE_PATHS.HOME)
    dispatch(setShowNotification(false))
    dispatch(setShowSearchPopup(false))
    showSideNav && sidenavExpand()
  }, [showSideNav])

  const handleClickPages = useCallback((element) => {
    dispatch(setShowChat(false))
    dispatch(setShowSearchPopup(false))
    showSideNav && sidenavExpand()
    if ('isCrypto' in element) {
      if (loginData?.accessToken) {
        history.push(element.path)
        dispatch(setCryptoCoin({ name: cryptoCoinsList?.[0]?.name, symbol: cryptoCoinsList?.[0].symbol, id: cryptoCoinsList?.[0]?.id }))
        dispatch(setShowChat(false))
      } else {
        dispatch(setShowLoginPopup(true))
      }
    }
    history.push(element.path)
  }, [showSideNav, loginData?.accessToken, cryptoCoinsList])

  const responsiveBarOptions = [
    {
      label: t('menu'),
      id: 1,
      path: pathname,
      iconComponent: HamburgerIcon,
      isSideNav: true,
      onClick: handleMenuClick
    },
    {
      label: t('casino'),
      id: 2,
      path: ROUTE_PATHS.CASINO,
      iconComponent: CasinoMenuIcon,
      onClick: handleClickPages
    },
    {
      label: t('sports'),
      id: 3,
      path: ROUTE_PATHS.SPORTS_BOOK,
      iconComponent: SportsMenuIcon,
      onClick: handleClickPages
    },
    {
      label: t('crypto'),
      id: 4,
      path: `${ROUTE_PATHS.CRYPTO}/${cryptoCoinsList?.[0]?.id}`,
      iconComponent: CryptoMenuIcon,
      onClick: handleClickPages,
      isCrypto: true
    },
    {
      label: t('search'),
      id: 5,
      path: pathname,
      iconComponent: HeaderSearchIcon,
      isSearch: true,
      onClick: handleSearchClick
    },
    {
      label: t('chat'),
      id: 6,
      path: pathname,
      iconComponent: ChatIcon,
      isChat: true,
      onClick: handleChatClick
    }
  ]

  const handleActiveSate = (element) => {
    if ('isSideNav' in element) {
      return showSideNav ? 'active' : ''
    }
    if ('isSearch' in element) {
      return showSearchPopup ? 'active' : ''
    }
    if ('isChat' in element) {
      return showChat ? 'active' : ''
    }
    return pathname === element.path ? 'active' : ''
  }

  return (
    <div className='Mobile-bottom-bar d-block d-xl-none'>
      <ul className='list-inline mb-0 d-flex justify-content-between overflow-auto flex-nowrap'>
        {responsiveBarOptions.map(element =>
          <li key={element.id} className='list-inline-item'>
            <button
              type='button'
              onClick={() => element?.onClick(element)}
              className={`btn btn-primary justify-content-end ${handleActiveSate(element)}`}
            >
              <element.iconComponent />
              <span>{element.label}</span>
            </button>
          </li>
        )}

        {/* //TODO UNCOMMENT WHEN NEED NOTIFICATION BUTTON */}
        {/* {loginData?.accessToken &&
          <li className='list-inline-item'>
            <button
              className='btn btn-primary'
              onClick={() => {
                dispatch(setShowChat(false))
                dispatch(setShowNotification(true))
              }}
            >
              {newAnnouncements &&
                <div className='chat-notification-icon' />}
              <ReawardIcon />
              <span>Rewards</span>
            </button>
          </li>} */}
        {/* {loginData?.accessToken && */}
      </ul>
    </div>
  )
}

export default ResponsiveButtonBar
