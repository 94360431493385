import React, { useEffect, useState } from 'react'
import { ArrowDownRedIcon, GreenArrowUpIcon, VectorIcon } from 'components/ui-kit/Icons/svg/index'
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useRollerCoasterSocketNameSpace from 'socket-resources/hooks/useRollerCoasterSpace'
import { ROLLER_COASTER, ROUTE_PATHS } from 'constants/index'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'

export const RollerCoasterComp = ({ disablePrice }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [rollerCoasterPrice, setRollerCoasterPrice] = useState([0])
  const { listenRollerCoasterUpdateEvent } = useRollerCoasterSocketNameSpace()
  const { loginData } = useSelector(state => state.auth)
  const handleRollercoasterPriceUpdate = (data) => {
    setRollerCoasterPrice((prevState) => {
      return [
        prevState.length > 1 ? prevState[1] : prevState[0],
        data?.data?.price
      ]
    })
  }

  useEffect(() => {
    const cleanerRollerCoasterEvent = listenRollerCoasterUpdateEvent(
      handleRollercoasterPriceUpdate
    )
    return () => {
      cleanerRollerCoasterEvent()
    }
  }, [])

  return (
    <li
      className={`crypto-list-wrap ${
        pathname === `${ROUTE_PATHS.CRYPTO}/${ROLLER_COASTER.id}` ? 'active' : ''
      }`}
    >
      <NavLink
        to={`${ROUTE_PATHS.CRYPTO}/${ROLLER_COASTER.id}`}
        onClick={() => {
          if (!loginData?.accessToken) {
            dispatch(setShowLoginPopup(true))
          }
          dispatch(setCryptoCoin({ name: 'Roller Coaster', symbol: 'rollercoaster', id: ROLLER_COASTER.id }))
        }}
        className='crypto-list d-flex align-items-center justify-content-between crypto-list'
      >
        <div className='d-flex align-items-center crypto-list-left'>
          <VectorIcon />
          <span>{t('rollercoaster')}</span>
        </div>
        {!disablePrice &&
          <div className='d-flex align-items-center crypto-list-right'>
            <span>
              {rollerCoasterPrice.length > 1
                ? rollerCoasterPrice[1]
                : rollerCoasterPrice[0]}
            </span>
            {rollerCoasterPrice.length > 1 && (
              <span>
                {rollerCoasterPrice[0] > rollerCoasterPrice[1]
                  ? (
                    <ArrowDownRedIcon />
                    )
                  : (
                    <GreenArrowUpIcon />
                    )}
              </span>
            )}
          </div>}
      </NavLink>
    </li>
  )
}

export default RollerCoasterComp
