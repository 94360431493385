import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadMoonPay } from '@moonpay/moonpay-js'
import _, { omitBy, toLower } from 'lodash'
import { generateMoonpaySignature } from 'redux-thunk/thunk/moonpay.thunk'
import config from 'config/app.config'

export const useMoonPay = () => {
  const [widget, setWidget] = useState(null)
  const [walletAddresses, setWalletAddresses] = useState('')
  const { user } = useSelector(state => state.user)
  const { signature } = useSelector(state => state.moonpay)
  const dispatch = useDispatch()

  const initializeMoonPay = async (walletWithAddresses) => {
    const moonPay = await loadMoonPay()
    const newWidget = moonPay?.({
      flow: 'buy',
      environment: config.MOONPAY_ENVIRONMENT,
      params: {
        apiKey: config.MOONPAY_PUB_KEY,
        baseCurrencyCode: 'usd',
        defaultCurrencyCode: 'usd',
        walletAddresses: walletWithAddresses,
        showAllCurrencies: true,
        showWalletAddressForm: true,
        email: user?.email || ''
      },
      variant: 'overlay',
      handlers: {
        async onTransactionCompleted (props) {
          console.log('onTransactionCompleted', props)
        }
      }
    })

    const signatureUrl = newWidget?.generateUrlForSigning()

    if (signatureUrl) {
      dispatch(generateMoonpaySignature({ payload: { signatureUrl } }))
    }

    setWidget(newWidget)
  }

  useEffect(() => {
    if (user?.wallets) {
      const walletAddresses = user?.wallets?.reduce((accumulator, currentWallet) => {
        return {
          ...accumulator,
          [toLower(currentWallet?.currency?.code)]: currentWallet?.walletAddress
        }
      }, { })

      const walletWithAddresses = omitBy(walletAddresses, _.isNull)
      setWalletAddresses(JSON.stringify(walletWithAddresses))
    }
  }, [JSON.stringify(user?.wallets?.map(wallet => wallet?.walletAddress))])

  useEffect(() => {
    initializeMoonPay(walletAddresses)
  }, [walletAddresses])

  useEffect(() => {
    if (widget && signature) {
      widget.updateSignature(signature)
    }
  }, [widget, signature])

  const showMoonPayWidget = useCallback(() => {
    widget?.show()
  }, [widget])

  return {
    showMoonPayWidget
  }
}
