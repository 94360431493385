import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setGameTab } from 'redux-thunk/redux/slices/lobby.slice'
import { getCasinoFavoriteGamesList } from 'redux-thunk/thunk/game.thunk'
import { setCasinoFavoriteGamesList } from 'redux-thunk/redux/slices/games.slice'

const ContentHeader = (props) => {
  const dispatch = useDispatch()
  const {
    title,
    subtitle,
    iconType,
    Icon,
    subtitleOnClickHandler,
    showRecentlyPlayed,
    showFavourites,
    gameCategory = '',
    provider = '',
    id
  } = props
  const { t } = useTranslation()
  const { loginData } = useSelector((state) => state.auth)
  const { gameTab } = useSelector((state) => state?.lobby)
  const { favouriteGamesList } = useSelector((state) => state.games)

  const isPng = Icon && (iconType === 'png')

  return (
    <div
      className={`inner-heading d-block d-flex align-items-center justify-content-between ${showRecentlyPlayed || (showFavourites && loginData) ? 'game-slider-header' : ''}`}
    >
      <div className='d-flex align-items-center game-category'>
        {isPng && <img src={Icon} width='20' className='heading-icon png-icon' />}
        {(Icon && (iconType !== 'png')) && <Icon width='20' height='20' className='heading-icon svg-icon' />}
        <h4 className='text-uppercase'>{title}</h4>
        <a
          href='#'
          className='heading-link'
          onClick={(e) => {
            subtitleOnClickHandler(e, { title, provider, gameCategory })
          }}
        >
          {subtitle}
        </a>
      </div>
      <div className='heading-right-listing recently-played'>
        <ul className='d-flex align-items-center m-0'>
          {showRecentlyPlayed &&
            <li className={!gameTab?.includes(title) ? 'active' : ''}>
              <a
                href=''
                onClick={(e) => {
                  e.preventDefault()
                  if (gameTab?.includes(title)) {
                    dispatch(
                      setGameTab(gameTab.filter((item) => item !== title))
                    )
                    dispatch(
                      setCasinoFavoriteGamesList(
                        favouriteGamesList.filter((item) => item.type !== title)
                      )
                    )
                  }
                }}
              >
                {t('recentlyPlayed')}
              </a>
            </li>}
          {(showFavourites && loginData?.accessToken) && (
            <li className={gameTab?.includes(title) ? 'active' : ''}>
              <a
                href=''
                onClick={(e) => {
                  e.preventDefault()
                  if (!gameTab?.includes(title)) {
                    dispatch(setGameTab([...gameTab, title]))
                    dispatch(
                      getCasinoFavoriteGamesList({
                        data: {
                          offset: 0,
                          limit: 12,
                          gameCategory: title,
                          gameTab
                        }
                      })
                    )
                  }
                }}
              >
                {t('favourites')}
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className='swiper-navigation custom-arrow'>
        <span
          className={`swiper-navigation-arrow swiper-navigation-prev ${id}-prev`}
        />
        <span
          className={`swiper-navigation-arrow swiper-navigation-next ${id}-next`}
        />
      </div>
    </div>
  )
}

export default React.memo(ContentHeader)
