import { createSlice } from '@reduxjs/toolkit'
import { getDemoGameIframe, getGameIframe } from 'redux-thunk/thunk/casino.thunk'
import { roundVerifyHash, getBanner, getEncourageWordsSetting, getEvolutionGameIframe, getGameSetting, getGameThemeSetting, getSponser } from 'redux-thunk/thunk/gameSetting'
import { generateClientSeed } from 'utils/helperFunctions.utils'

const initialState = {
  systemGameData: null,
  allCurrencies: [],
  gameSettings: [],
  banner: [],
  gameVolume: 1,
  gameMusic: false,
  showLoginPopup: false,
  showSignupPopup: false,
  affilateCode: null,
  homeBanner: [],
  gameThemeSetting: null,
  encourageSettings: [],
  isDemoGame: false,
  demoWallet: 3000,
  gameIframeLink: null,
  evolutionGameIframe: null,
  clientSeed: generateClientSeed(),
  serverSeedHash: '',
  roundHashDetails: null
}

const {
  actions: {
    setAllcurrency,
    setSystemData,
    setGameSettings,
    setMusicSound,
    setGameVolume,
    setShowLoginPopup,
    setShowSignupPopup,
    setAffilateCode,
    setNewSeed,
    setIsDemoGame,
    setDemoWallet,
    setClientSeed,
    setServerSeedHash
  },
  reducer
} = createSlice({
  name: 'gameSetting',
  initialState,
  reducers: {
    setSystemData: (state, action) => ({
      ...state,
      systemGameData: [...action.payload]
    }),
    setGameVolume: (state, action) => ({
      ...state,
      gameVolume: action.payload
    }),
    setMusicSound: (state, action) => ({
      ...state,
      gameMusic: action.payload
    }),
    setAllcurrency: (state, action) => ({
      ...state,
      allCurrencies: action.payload
    }),
    setGameSettings: (state, action) => ({
      ...state,
      gameSettings: action.payload
    }),
    setShowLoginPopup: (state, action) => ({
      ...state,
      showLoginPopup: action.payload
    }),
    setShowSignupPopup: (state, action) => ({
      ...state,
      showSignupPopup: action.payload
    }),
    setAffilateCode: (state, action) => ({
      ...state,
      affilateCode: action.payload
    }),
    setNewSeed: (state, action) => ({
      ...state,
      clientSeed: action.payload
    }),
    setIsDemoGame: (state, action) => ({
      ...state,
      isDemoGame: action.payload
    }),
    setDemoWallet: (state, action) => ({
      ...state,
      demoWallet: action.payload
    }),
    setClientSeed: (state, action) => ({
      ...state,
      clientSeed: action.payload
    }),
    setServerSeedHash: (state, action) => ({
      ...state,
      serverSeedHash: action.payload
    })
  },
  extraReducers: (builder) => {
    builder

      .addCase(getGameSetting.fulfilled, (state, action) => {
        return {
          ...state,
          systemGameData: action.payload
        }
      })

      .addCase(getGameThemeSetting.fulfilled, (state, action) => {
        return {
          ...state,
          gameThemeSetting: action.payload
        }
      })
      .addCase(getEncourageWordsSetting.fulfilled, (state, action) => {
        return {
          ...state,
          encourageSettings: action.payload
        }
      })

      .addCase(getBanner.fulfilled, (state, action) => {
        return {
          ...state,
          homeBanner: action.payload
        }
      })
      .addCase(getBanner.rejected, (state, action) => {
        return {
          ...state,
          homeBanner: action.payload
        }
      })
      .addCase(getSponser.fulfilled, (state, action) => {
        return {
          ...state,
          banner: action.payload
        }
      })
      .addCase(getGameIframe.fulfilled, (state, action) => {
        return {
          ...state,
          gameIframeLink: action.payload
        }
      })
      .addCase(getDemoGameIframe.fulfilled, (state, action) => {
        return {
          ...state,
          gameIframeLink: action.payload
        }
      })
      .addCase(getEvolutionGameIframe.fulfilled, (state, action) => {
        return {
          ...state,
          evolutionGameIframe: action.payload
        }
      })
      .addCase(roundVerifyHash.fulfilled, (state, action) => {
        return {
          ...state,
          roundHashDetails: action.payload
        }
      })
  }
})

export default reducer
export {
  setAllcurrency,
  setSystemData,
  setGameSettings,
  setGameVolume,
  setMusicSound,
  setShowLoginPopup,
  setShowSignupPopup,
  setAffilateCode,
  setNewSeed,
  setIsDemoGame,
  setDemoWallet,
  setClientSeed,
  setServerSeedHash
}
