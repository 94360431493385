import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import useSlideGesture from './useSlideGesture'

const ARROW_WIDTH_WITHOUT_LEFT_PADDING = 36

const NavScrollWrapper = ({ children }) => {
  const navRef = useRef(null)
  const [showArrow, setShowArrow] = useState(false)
  const {
    onMouseUp,
    onMouseDown,
    onMouseLeave,
    onMouseMove
  } = useSlideGesture({ containerRef: navRef })

  useEffect(() => {
    const navElement = navRef.current

    if (!navElement) {
      return
    }

    const handleButtonVisibility = () => {
      const fullWidth = navElement.scrollWidth
      const visibleWidth = navElement.offsetWidth
      const scrollPosition = navElement.scrollLeft
      const isArrowVisible = Math.ceil(visibleWidth + scrollPosition) < fullWidth - 1
      setShowArrow(isArrowVisible)
    }

    const navResize = new ResizeObserver(handleButtonVisibility)
    navElement.addEventListener('scroll', handleButtonVisibility)
    navResize.observe(navElement)

    return () => {
      navResize.disconnect()
      navElement.removeEventListener('scroll', handleButtonVisibility)
    }
  }, [navRef.current, navRef.current?.children?.length])

  const handleOnClick = () => {
    const visibleWidth = navRef.current.offsetWidth
    for (const child of navRef.current.children) {
      if (navRef.current.scrollLeft + visibleWidth > child.offsetLeft + child.offsetWidth) {
        continue
      }
      navRef.current.scrollLeft = child.offsetLeft
      break
    }
  }

  const handleOnItemSelect = (event) => {
    const isElementVisibleInRightPart = navRef.current.scrollLeft + navRef.current.offsetWidth - ARROW_WIDTH_WITHOUT_LEFT_PADDING < event.currentTarget.offsetLeft + event.currentTarget.offsetWidth
    const isElementVisibleInLeftPart = navRef.current.scrollLeft > event.currentTarget.offsetLeft && navRef.current.scrollLeft < event.currentTarget.offsetLeft + event.currentTarget.offsetWidth

    if (isElementVisibleInRightPart || isElementVisibleInLeftPart) {
      navRef.current.scrollLeft = event.currentTarget.offsetLeft
    }
  }

  const renderChildrenWithProps = () => {
    return React.Children.map(children, (child) => (
      React.isValidElement(child) && React.cloneElement(child, {
        onClick: (event) => {
          child.props.onClick?.()
          handleOnItemSelect(event)
        }
      })
    ))
  }

  useEffect(() => {
    if (!navRef.current) {
      return
    }

    const selectedItem = Array.from(navRef.current.children).find((child) => child.classList.contains('active'))

    if (!selectedItem) {
      return
    }
    const isActiveElementNotFullVisible = navRef.current.scrollLeft + navRef.current.offsetWidth - 36 > selectedItem.offsetLeft + selectedItem.offsetWidth

    if (isActiveElementNotFullVisible) {
      return
    }

    navRef.current.scrollLeft = selectedItem.offsetLeft
  }, [])

  return (
    <div className='nav-scroll-wrapper'>
      <ul
        className='nav nav-pills nav-scroll-smooth'
        id='pills-tab'
        role='tablist'
        ref={navRef}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        {renderChildrenWithProps()}
      </ul>
      {showArrow && (
        <div className='nav-scroll-arrow-wrapper'>
          <button className='nav-scroll-arrow' onClick={handleOnClick} type='button' />
        </div>
      )}
    </div>
  )
}

export default NavScrollWrapper
