import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { GameImg } from 'pages/GameImage/index'
import Loader from 'components/ui-kit/Loader/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import FavouriteIcon from 'components/molecules/FavouriteIcon/index'

const AllGamesCard = (props) => {
  const {
    game,
    item,
    onGamePlayRedirect,
    isLoggedIn,
    favouriteGameHandler,
    gameId,
    actionLoading,
    isFavourite,
    searchPopup,
    cardClasses
  } = props

  return (
    <div key={item?.id || uuidv4()} className={`view-card ${cardClasses || ''}`}>
      <div className='theme-card-wrap'>
        <div className='theme-card'>
          <div
            className='casino-img-wrap'
            onClick={() => onGamePlayRedirect(item)}
          >
            <GameImg imgSrc={item?.image} />
            <div className='card-overlay'>
              <h4>Play Now</h4>

              {isLoggedIn && (
                <a
                  href=''
                  onClick={(e) => {
                    e.preventDefault()
                    favouriteGameHandler(e, item)
                  }}
                >
                  {actionLoading && gameId === game?.id
                    ? <Loader variant={LOADER_HANDLER_TYPES.submit} />
                    : <FavouriteIcon
                        searchPopup={searchPopup}
                        fill={game?.isFavourite || isFavourite
                          ? 'red'
                          : 'none'}
                      />}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllGamesCard
