import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'

export const generateMoonpaySignatureService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/moonpay/signature', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
