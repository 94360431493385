import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  // ArrowRightShortIcon,
  // RedditChatIcon,
  SteamChatIcon,
  TwitchChatIcon,
  // TwitterChatIcon,
  SocialIcon,
  XIcon
} from 'components/ui-kit/Icons/svg/index'
import { useSelector } from 'react-redux'
import Tooltip from 'rc-tooltip'
import { SOCIAL_ACCOUNTS, SOCIAL_LINKS } from 'constants/index'

const SocialMedia = () => {
  const { t } = useTranslation()
  const { userData } = useSelector((state) => state.chat)

  const usersSocialAccounts = userData?.linkedAccounts?.reduce(
    (accountsAccumulator, currentAccount) => {
      accountsAccumulator[currentAccount.socialAccount] = currentAccount.username
      return accountsAccumulator
    }, {})

  return (
    <div className='chat-modal-content'>
      <div className={`chat-badge-wrap ${userData?.isPrivate
        ? 'private-link'
        : ''}`}
      >
        <div
          className='chat-inner-header d-flex align-items-center justify-content-between'
        >
          <div className='chat-badge-header-left d-flex align-items-center'>
            <SocialIcon />
            <h4 className='m-0'>{t('socialMedia')}</h4>
          </div>
          {/* <Tooltip
            placement='bottom'
            trigger={['hover']}
            overlayStyle={{ fontSize: '0.7rem', zIndex: userData?.isPrivate ? 9999 : 0 }}
            overlay={<span>{t('private')}</span>}
            showArrow={false}
          >
            <a
              href={!userData?.isPrivate && 'javascript:void(0);'}
              className='d-flex align-items-center'
            >
              {t('allLinks')}
              <ArrowRightShortIcon />
            </a>
          </Tooltip> */}
        </div>
        <div className='user-social-list-wrap d-flex align-items-center gap-1'>
          <Tooltip
            placement='bottom'
            trigger={['hover']}
            overlayStyle={{ fontSize: '0.7rem', zIndex: userData?.isPrivate ? 9999 : 0 }}
            overlay={<span>{t('private')}</span>}
            showArrow={false}
          >
            <a
              href={(!userData?.isPrivate && usersSocialAccounts?.[SOCIAL_ACCOUNTS.TWITTER])
                ? `${SOCIAL_LINKS[SOCIAL_ACCOUNTS.TWITTER]}${usersSocialAccounts?.[SOCIAL_ACCOUNTS.TWITTER]}`
                : 'javascript:void(0);'}
              target='_blank'
              rel='noreferrer'
              className='d-flex align-items-center justify-content-center'
              onClick={(e) => {
                if (userData?.isPrivate || !usersSocialAccounts?.[SOCIAL_ACCOUNTS.TWITTER]) {
                  e.preventDefault()
                }
              }}
            >
              {usersSocialAccounts?.[SOCIAL_ACCOUNTS.TWITTER]
                ? <XIcon fill='var(--blue-300)' />
                : <XIcon />}
            </a>
          </Tooltip>
          <Tooltip
            placement='bottom'
            trigger={['hover']}
            overlayStyle={{ fontSize: '0.7rem', zIndex: userData?.isPrivate ? 9999 : 0 }}
            overlay={<span>{t('private')}</span>}
            showArrow={false}
          >
            <a
              href={!userData?.isPrivate && usersSocialAccounts?.[SOCIAL_ACCOUNTS.TWITCH]
                ? `${SOCIAL_LINKS[SOCIAL_ACCOUNTS.TWITCH]}${usersSocialAccounts?.[SOCIAL_ACCOUNTS.TWITCH]}`
                : 'javascript:void(0);'}
              target='_blank'
              rel='noreferrer'
              className='d-flex align-items-center justify-content-center'
              onClick={(e) => {
                if (userData?.isPrivate || !usersSocialAccounts?.[SOCIAL_ACCOUNTS.TWITCH]) {
                  e.preventDefault()
                }
              }}
            >
              {usersSocialAccounts?.[SOCIAL_ACCOUNTS.TWITCH]
                ? <TwitchChatIcon fill='var(--blue-300)' />
                : <TwitchChatIcon />}
            </a>
          </Tooltip>
          <Tooltip
            placement='bottom'
            trigger={['hover']}
            overlayStyle={{
              fontSize: '0.7rem',
              zIndex: userData?.isPrivate ? 9999 : 0
            }}
            overlay={<span>{t('private')}</span>}
            showArrow={false}
          >
            <a
              href={!userData?.isPrivate && usersSocialAccounts?.[SOCIAL_ACCOUNTS.STEAM]
                ? `${SOCIAL_LINKS[SOCIAL_ACCOUNTS.STEAM]}${usersSocialAccounts?.[SOCIAL_ACCOUNTS.STEAM]}`
                : 'javascript:void(0);'}
              target='_blank'
              rel='noreferrer'
              className='d-flex align-items-center justify-content-center'
              onClick={(e) => {
                if (userData?.isPrivate || !usersSocialAccounts?.[SOCIAL_ACCOUNTS.STEAM]) {
                  e.preventDefault()
                }
              }}
            >
              {usersSocialAccounts?.[SOCIAL_ACCOUNTS.STEAM]
                ? <SteamChatIcon fill='var(--blue-300)' />
                : <SteamChatIcon />}
            </a>
          </Tooltip>
          {/* <Tooltip
            placement='bottom'
            trigger={['hover']}
            overlayStyle={{
              fontSize: '0.7rem',
              zIndex: userData?.isPrivate ? 9999 : 0
            }}
            overlay={<span>{t('private')}</span>}
            showArrow={false}
          >
            <a
              href={!userData?.isPrivate && 'javascript:void(0);'}
              className='d-flex align-items-center justify-content-center'
            >
              <RedditChatIcon />
            </a>
          </Tooltip> */}
        </div>
      </div>
    </div>
  )
}

export default React.memo(SocialMedia)
