import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { openInfoToaster } from 'helpers/toaster.helpers'
import { TRANSACTION_TABS } from 'constants/index'
import { getHighRollers } from 'redux-thunk/thunk/casino.thunk'
import AllBets from './components/AllBets/index'
import HighRoller from './components/HighRoller/index'

const TransactionsTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [tab, setTab] = useState(TRANSACTION_TABS.ALL_BETS)

  useEffect(() => {
    if (tab === TRANSACTION_TABS.HIGH_ROLLER) {
      dispatch(getHighRollers({ limit: 10 }))
    }
  }, [tab])

  return (
    <>
      <section className='table-section'>
        <div className='inner-heading d-block d-lg-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center mb-3 mb-lg-0'>
            <img src='/assets/images/homePage/white-refresh.svg' alt='Live' />
            <h4 className='text-uppercase'>LIVE BETS</h4>
          </div>
          <div className='heading-right-listing'>
            <ul className='nav nav-tabs d-flex align-items-center m-0 p-0' role='tablist'>
              <li
                className={`nav-item ${tab === TRANSACTION_TABS.ALL_BETS ? 'active' : ''}`}
                role='presentation'
              >
                <NavLink
                  to='#'
                  onClick={(e) => {
                    setTab(TRANSACTION_TABS.ALL_BETS)
                  }}
                >
                  {t('allBets')}
                </NavLink>
              </li>
              <li
                className={`nav-item ${tab === TRANSACTION_TABS.HIGH_ROLLER ? 'active' : ''}`}
                role='presentation'
              >
                <NavLink
                  to='#'
                  onClick={(e) => {
                    setTab(TRANSACTION_TABS.HIGH_ROLLER)
                  }}
                >
                  {t('highRollers')}
                </NavLink>
              </li>
              <li className='nav-item' role='presentation'>
                <NavLink
                  to='#'
                  onClick={(e) => {
                    openInfoToaster({ message: 'Coming Soon!' })
                  }}
                >
                  {t('luckyBets')}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className='tab-content'>
          <AllBets tab={tab} />
          <HighRoller tab={tab} />
        </div>
      </section>
    </>
  )
}

export default React.memo(TransactionsTable)
