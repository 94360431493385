import { Suspense } from 'react'
import Loader from 'components/ui-kit/Loader/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'

const SuspenseWrapper = ({ Component, style, properties }) => {
  return (
    <Suspense fallback={<Loader variant={LOADER_HANDLER_TYPES.content} style={style} />}>
      <Component {...properties} />
    </Suspense>
  )
}

export default SuspenseWrapper
