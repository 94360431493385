import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import createSocketConnection from 'socket-resources/socket-connection/index'
import NAMESPACES from 'socket-resources/namespaces/index'
import { LIVE_WINS } from 'socket-resources/event-constants/liveWins/index'

let liveWinsSocketInstance

const useLiveWinsSocketNameSpace = () => {
  const { loginData } = useSelector(state => state.auth)

  useEffect(() => {
    if (!liveWinsSocketInstance) {
      liveWinsSocketInstance = createSocketConnection(NAMESPACES.LIVE_WINS, loginData?.accessToken)
    }
  }, [loginData?.accessToken])

  const listenLiveWinsUpdateEvent = (cb) => {
    if (liveWinsSocketInstance && cb) {
      liveWinsSocketInstance?.on(LIVE_WINS, cb)
      return () => {
        liveWinsSocketInstance.off(LIVE_WINS, cb)
      }
    }
  }

  return { listenLiveWinsUpdateEvent }
}

export default useLiveWinsSocketNameSpace
