import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setPwaInstalled } from 'redux-thunk/redux/slices/settings.slice'

export default function useAddToHomeScreenPrompt () {
  const dispatch = useDispatch()
  const [prompt, setState] = useState(null)
  const [isInstalled, setIsInstalled] = useState(false)

  const promptToInstall = () => {
    if (!prompt) {
      console.log('installed')
      return null
      // return Promise.reject(
      //   new Error(
      //     'Tried installing before browser sent "beforeinstallprompt" event'
      //   )
      // );
    }

    // Defer prompt display for user experience (optional)
    // return prompt.prompt(); // Immediate prompt

    return new Promise((resolve) => {
      prompt?.prompt().then(() => resolve(prompt))
    })
  }

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true)
    }
    const ready = (e) => {
      e.preventDefault()
      setState(e)
    }

    window.addEventListener('beforeinstallprompt', ready)

    return () => {
      window.removeEventListener('beforeinstallprompt', ready)
    }
  }, [])
  useEffect(() => {
    dispatch(setPwaInstalled(isInstalled))
  }, [isInstalled])

  return [prompt, promptToInstall, isInstalled]
}
