import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getItem } from 'helpers/localstorage.helpers'
import { getAllSiteinfo } from 'redux-thunk/thunk/gameSetting'
import { SHOW_SIDE_NAV } from 'constants/index'
import { NewClutchLogoIcon } from 'components/ui-kit/Icons/svg/index'
import AccordionItem from 'components/ui-kit/AccordionItem/index'
import Platform from './components/Platform/index'
import AboutUs from './components/AboutUs/index'
import Community from './components/Community/index'
import LanguageDropdown from './components/LanguageDropdown/index'
import moment from 'moment'
import config from 'config/app.config'
import './footer.scss'

const Footer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { siteInfo } = useSelector(state => state.user)
  const { showSideNav, showChat } = useSelector(
    (state) => state?.settings
  )
  const showSidebarNav = getItem(SHOW_SIDE_NAV)
  const [isCollapseAccordion, setIsCollapseAccordion] = useState({
    accordionOne: false,
    accordionTwo: false,
    accordionThree: false
  })
  const currentYear = moment().year()

  const accordionClickHandler = useCallback((accordionId) => {
    setIsCollapseAccordion((prevState) => ({
      ...prevState,
      [accordionId]: !prevState[accordionId]
    }))
  }, [])

  useEffect(() => {
    if (!siteInfo) { dispatch(getAllSiteinfo()) }
  }, [])

  return (
    <footer className={`mt-3 footer ${!showSidebarNav ? 'sidebar-close' : ''} ${showSideNav && showChat ? 'footer--sidebars' : ''}`}>
      <div className='footer-top d-none d-sm-block'>
        <div className='content-wrapper custom-spacer d-flex flex-wrap'>
          <div className='footer-content'>
            <div className='footer-about mb-3'>
              <div className='footer-logo'>
                <NewClutchLogoIcon />
              </div>
              <p className='m-0'>{t('copyrightText', { year: currentYear })}</p>
            </div>
            <div className='footer-about my-3'>
              <p className='m-0'>{t('tradingDefinition')}</p>
            </div>
          </div>
          <div className='footer-menu d-flex justify-content-between flex-wrap'>
            <div className='footer-menu-col'>
              <div className='footer-linking d-flex justify-content-between'>
                <h4 className='m-0'>{t('platform')}</h4>
                <Platform />
              </div>
            </div>
            <div className='footer-menu-col'>
              <div className='footer-linking d-flex justify-content-between'>
                <h4 className='m-0'>{t('aboutUs')}</h4>
                <AboutUs />
              </div>
            </div>
            <div className='footer-menu-col'>
              <div className='footer-linking d-flex justify-content-between'>
                <h4 className='m-0'>{t('community')}</h4>
                <Community />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-top mob-footer d-block d-sm-none'>
        <div className='col-xl-4 col-lg-4 col-md-12 order-3 order-lg-0'>
          <div className='footer-about mb-3'>
            <div className='footer-logo'>
              <NewClutchLogoIcon />
            </div>
            <p className='m-0'>{t('copyrightText', { year: currentYear })}</p>
          </div>
          <div className='footer-about my-3'>
            <p className='m-0'>{t('tradingDefinition')}</p>
          </div>
        </div>
        <div className='desktop-language d-block d-lg-none'>
          <LanguageDropdown />
        </div>
        <div className='accordion' id='accordionExample'>
          <AccordionItem
            accordionId='accordionOne'
            isCollapseAccordion={isCollapseAccordion}
            accordionClickHandler={accordionClickHandler}
            title={t('platform')}
          >
            <Platform />
          </AccordionItem>
          <AccordionItem
            accordionId='accordionTwo'
            isCollapseAccordion={isCollapseAccordion}
            accordionClickHandler={accordionClickHandler}
            title={t('aboutUs')}
          >
            <AboutUs />
          </AccordionItem>
          <AccordionItem
            accordionId='accordionThree'
            isCollapseAccordion={isCollapseAccordion}
            accordionClickHandler={accordionClickHandler}
            title={t('community')}
          >
            <Community />
          </AccordionItem>
        </div>
      </div>
      <div className='license-section'>
        <div className='row'>
          <div
            className='col-12 col-lg-3 d-flex align-items-center gap-3 justify-content-start'
          >
            <Link
              to={{ pathname: `https://cert.gcb.cw/certificate?id=${config.DOMAIN_TOKEN}` }}
              target='_blank'
              className='curacao-link'
            >
              <img
                height={49.5}
                width={87}
                className=''
                src='https://portal.gamingcontrolcuracao.org/uploads/documents/GCB_Temp_Seal.svg'
              />
            </Link>
            <p className='age-text'>18+</p>
          </div>
          <div className='col-12 col-lg-10 d-none'>
            <p className='mb-0 text-center license-text text-md-start'>
              Clutch.app is operated by Prolific Trade N.V., company
              registration number 150731, with registered address at Groot
              Kwartierweg 10, Curaçao and is licensed andauthorized by the
              Government of Curacao and operates under the Master License of
              Gaming Services Provider, N.V. #365/JAZ License Number:<span className='highlight-txt'>GLH- OCCHKTW0709172018</span>.
              Payment agent company is Horangi Trading Limited with address Chytron, 30,
              2nd floor, Flat/Office A22, 1075, Nicosia, Cyprus and Registration
              number: HE 411494.
            </p>
          </div>
        </div>
      </div>
      <div className='desktop-language d-none d-sm-block pt-0'>
        <LanguageDropdown currentYear={currentYear} />
      </div>
      <div
        className='footer-bottom-linking mob-linking d-md-flex justify-content-center align-items-center'
      >
        <p className='m-0'>
          {t('support')}
          <a href='mailto:support@clutch.app'>support@clutch.app</a>
        </p>
        <p className='m-0'>
          {t('partners')}
          <a href='mailto:partners@clutch.app'>partners@clutch.app</a>
        </p>
        <p className='m-0'>
          {t('legal')}
          <a href='mailto:legal@clutch.app'>legal@clutch.app</a>
        </p>
      </div>
      <div
        className='footer-bottom-linking  d-flex d-sm-none justify-content-center align-items-center'
      >
        <p className='mb-0'>© {currentYear} Clutch.app All rights reserved.</p>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
