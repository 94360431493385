import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { userBetaSignUp } from 'redux-thunk/thunk/auth.thunk'
import { openErrorToaster } from 'helpers/toaster.helpers'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import Loader from 'components/ui-kit/Loader/index'
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index'
import { XIcon, TelegramIcon } from 'components/ui-kit/Icons/svg/index'

const BetaFilled = ({ setShowBetaThankYouPopup, showSignupPopup }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('signupErrorsEmailRequired'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signupErrorsEmailPattern')
      ),
    telegram: yup
      .string()
      .matches(/^\w+$/, { message: t('signupErrorsUserNamePattern'), excludeEmptyString: true })
      .nullable(),
    twitter: yup
      .string()
      .matches(/^\w+$/, { message: t('signupErrorsUserNamePattern'), excludeEmptyString: true })
      .nullable()
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  })

  const handleRegisterSubmit = async (userDetails) => {
    try {
      dispatch(userBetaSignUp({ userDetails, setShowBetaThankYouPopup }))
      reset()
    } catch (e) {
      openErrorToaster({ message: e.message })
    }
  }

  useEffect(() => {
    return () => {
      if (location.pathname === ROUTE_PATHS.SIGNUP) {
        history.push(ROUTE_PATHS.HOME)
      }
    }
  }, [])

  return (
    <div
      className={`tab-pane fade ${showSignupPopup && 'show active'}`}
      id='pills-profile'
      role='tabpanel'
      aria-labelledby='pills-profile-tab'
    >
      <div className='register-wrap pb-5 pb-sm-0'>
        <div className='auth-hint-text mb-3'>
          <p>
            {t('betaFilledText')}
          </p>
        </div>
        <form onSubmit={handleSubmit(handleRegisterSubmit)}>
          <div className='mb-3 form-group'>
            <label htmlFor='email' className='form-label text-uppercase'>
              {t('email')}
            </label> <span className='color-red'>*</span>
            <input
              type='email'
              placeholder={t('emailPlaceholder')}
              className={`form-control form-control-dark shadow-none ${errors?.email ? 'error-input' : ''}`}
              id='email'
              aria-describedby=''
              name='email'
              maxLength={50}
              {...register('email')}
            />
            {errors && errors.email && (
              <div className='error-container'>
                <ErrorMessage
                  className='color-red text-danger error-msg login-input ms-2 d-flex'
                  message={errors.email.message}
                />
              </div>
            )}
          </div>

          <div className='mb-3 form-group'>
            <label htmlFor='telegram' className='form-label text-uppercase'>
              {t('telegram')}
            </label>
            <span className='input-wrapper input-wrapper--withIcon'>
              <span className='input-icon'>
                <TelegramIcon />
              </span>
              <input
                type='text'
                placeholder={t('telegramPlaceholder')}
                className={`form-control form-control-dark shadow-none ${errors?.telegram ? 'error-input' : ''}`}
                id='telegram'
                name='telegram'
                {...register('telegram')}
                aria-describedby=''
                maxLength={50}
              />
            </span>
            {errors && errors.telegram && (
              <div className='error-container'>
                <ErrorMessage
                  className='color-red text-danger error-msg login-input ms-2 d-flex'
                  message={errors.telegram.message}
                />
              </div>
            )}
          </div>

          <div className='mb-4 form-group'>
            <label htmlFor='telegram' className='form-label text-uppercase'>
              {t('twitterX')}
            </label>
            <span className='input-wrapper input-wrapper--withIcon'>
              <span className='input-icon'>
                <XIcon />
              </span>
              <input
                type='text'
                placeholder={t('twitterPlaceholder')}
                className={`form-control form-control-dark shadow-none ${errors?.twitter ? 'error-input' : ''}`}
                id='twitter'
                name='twitter'
                {...register('twitter')}
                aria-describedby=''
                maxLength={50}
              />
            </span>
            {errors && errors.twitter && (
              <div className='error-container'>
                <ErrorMessage
                  className='color-red text-danger error-msg login-input ms-2 d-flex'
                  message={errors.twitter.message}
                />
              </div>
            )}
          </div>

          <div className='auth-btn-wrap auth-btn-wrap--betaFilled'>
            <button
              type='submit'
              className='btn btn-secondary w-100'
              disabled={loading}
            >
              {loading
                ? (
                  <Loader variant={LOADER_HANDLER_TYPES.submit} />
                  )
                : (
                  <span>{t('signUp')}</span>
                  )}
            </button>
          </div>
        </form>
        <div className='auth-mob-instructions auth-mob-instructions--betaFilled accordion pb-5 pb-md-0'>
          <Trans>
            <p>
              Follow Clutch on <a href='#'><XIcon /></a><br />
              for more updates and announcements.
            </p>
          </Trans>
        </div>
      </div>
    </div>
  )
}

export default BetaFilled
