import { createAsyncThunk } from '@reduxjs/toolkit'
import { setCasinoFavoriteGamesList, setFavouriteGame, setGamesList, setViewAllFavouriteGames, setViewAllGames } from '../redux/slices/games.slice'
import { getGamesListService, getCasinoProvidersService, getCasinoAllCategoriesService, getUsersCasinoTransactionsService, removeFavouriteGameService, addFavouriteGameService, getAllFavouriteGamesService, getCustomGameTransactionsService, getCasinoTransactionsService, getSportsBookTransactionsService, getFavouriteGamesListService } from 'network/services/sportsBook.service'
import { openInfoToaster } from 'helpers/toaster.helpers'
import { setGameTab } from 'redux-thunk/redux/slices/lobby.slice'
import { startCase } from 'lodash'
import { setViewAllPopupGames } from 'redux-thunk/redux/slices/searchPopup.slice'
import { getTranslation } from 'helpers/translations.helpers'

export const getCasinoGamesList = createAsyncThunk(
  'fetch/casino-category-games',
  async ({ data }, thunkApi) => {
    try {
      const res = await getGamesListService({ ...data })
      const { categoryWiseGamesList } = thunkApi?.getState()?.games
      const updatedList = [
        ...categoryWiseGamesList,
        { type: (data?.type), sortOrder: data?.sortOrder, isVisible: data?.isVisible, ...res }]?.sort((a, b) => a?.sortOrder - b?.sortOrder)
      return updatedList
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCasinoFavoriteGamesList = createAsyncThunk(
  'fetch/favourite-category-games',
  async ({ data }, thunkApi) => {
    try {
      const res = await getFavouriteGamesListService({ ...data })
      res.count === 0 && openInfoToaster({ message: `${getTranslation('noFavouritesIn')} ${startCase(data?.gameCategory)}` })
      res.count === 0 && thunkApi?.dispatch(setGameTab(data?.gameTab.filter(item => item !== data?.gameCategory)))
      const { favouriteGamesList } = thunkApi?.getState()?.games
      const updatedList = [...favouriteGamesList, { type: (data?.gameCategory), ...res }]
      return res.count === 0 ? updatedList.filter(item => item.type !== data?.gameCategory) : updatedList
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllGames = createAsyncThunk(
  'fetch/games-list',
  async ({ data }, thunkApi) => {
    try {
      let res = await getGamesListService({ ...data })
      if (data?.offset > 0) {
        const { viewAllGames } = thunkApi?.getState()?.games
        res = { count: res?.count, rows: [...viewAllGames?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getSearchedGames = createAsyncThunk(
  'fetch/searched-games-list',
  async ({ data }, thunkApi) => {
    try {
      let res = await getGamesListService({ ...data })
      if (data?.offset > 0) {
        const { filteredGames } = thunkApi?.getState()?.games
        res = { count: res?.count, rows: [...filteredGames?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCasinoProvidersList = createAsyncThunk(
  'fetch/providers-list',
  async (data, thunkApi) => {
    try {
      let res = await getCasinoProvidersService(data)
      if (data?.offset > 0) {
        const { providers } = thunkApi?.getState()?.games
        res = { count: res?.count, rows: [...providers?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCasinoAllCategories = createAsyncThunk(
  'fetch/all-categories-list',
  async (_, thunkApi) => {
    try {
      const res = await getCasinoAllCategoriesService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUsersCasinoTransactions = createAsyncThunk(
  'fetch/users-casino-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getUsersCasinoTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllFavouriteGames = createAsyncThunk(
  'fetch/favourite-games-list',
  async ({ data }, thunkApi) => {
    try {
      let res = await getAllFavouriteGamesService(data)
      if (data?.offset > 0) {
        const { favouriteGames } = thunkApi?.getState()?.games
        res = { count: res?.count, rows: [...favouriteGames?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getViewAllFavoriteGamesList = createAsyncThunk(
  'fetch/favourite-viewall-games',
  async ({ data }, thunkApi) => {
    try {
      let res = await getFavouriteGamesListService({ ...data })
      if (data.offset > 0) {
        const { allFavouriteGames } = thunkApi?.getState()?.games
        res = { count: res?.count, rows: [...allFavouriteGames?.rows, ...res?.rows] }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const addFavouriteGame = createAsyncThunk(
  'fetch/add-favourite-games',
  async ({ game }, thunkApi) => {
    try {
      const res = await addFavouriteGameService({ gameId: game?.id })
      const { favouriteGames, categoryWiseGamesList, viewAllGames } = thunkApi?.getState()?.games
      const { viewAllGames: allGamesSearch } = thunkApi?.getState()?.searchPopup
      const addFavGame = favouriteGames?.rows
        ? { count: favouriteGames?.count + 1, rows: [{ CasinoGame: { ...game, isFavourite: true } }, ...favouriteGames?.rows] }
        : {
            count: favouriteGames?.count ? favouriteGames?.count + 1 : 1, rows: [{ CasinoGame: { ...game, isFavourite: true } }]
          }
      thunkApi?.dispatch(setFavouriteGame(addFavGame))

      const updatedCategoryWiseGameList = categoryWiseGamesList.map(item => {
        if (item?.type === game?.gameCategory?.gameCategory) {
          const updatedRow = item?.rows?.map(ele => {
            if (ele?.id === game?.id) {
              return { ...ele, isFavourite: true }
            }
            return ele
          })
          return { ...item, rows: updatedRow }
        }
        return item
      })
      thunkApi?.dispatch(setGamesList(updatedCategoryWiseGameList))

      const updatedRow = viewAllGames?.rows?.map(item => {
        if (item?.id === game?.id) {
          return { ...item, isFavourite: true }
        } else {
          return item
        }
      })
      thunkApi?.dispatch(setViewAllGames({ ...viewAllGames, rows: updatedRow }))

      const updatedGamesInSearchPopup = allGamesSearch?.rows?.map(item => {
        if (item?.id === game?.id) {
          return { ...item, isFavourite: true }
        } else {
          return item
        }
      })
      thunkApi?.dispatch(setViewAllPopupGames({ ...viewAllGames, rows: updatedGamesInSearchPopup }))

      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const removeFavouriteGame = createAsyncThunk(
  'fetch/remove-favourite-game',
  async ({ game, gameTab }, thunkApi) => {
    try {
      const res = await removeFavouriteGameService({ gameId: game?.id })
      const { favouriteGames, categoryWiseGamesList, viewAllGames, allFavouriteGames, favouriteGamesList } = thunkApi?.getState()?.games
      const { viewAllGames: allGamesSearch } = thunkApi?.getState()?.searchPopup

      const removeFavGame = { count: favouriteGames?.count - 1, rows: favouriteGames?.rows?.filter(ele => ele?.CasinoGame?.id !== game?.id) }
      const removeAllFavGame = { count: allFavouriteGames?.count - 1, rows: allFavouriteGames?.rows?.filter(ele => ele?.CasinoGame?.id !== game?.id) }
      const rowGame = favouriteGamesList?.filter(item => item.type === game?.gameCategory?.gameCategory)?.[0]
      const removeRowGame = { count: rowGame?.count - 1, type: rowGame?.type, rows: rowGame?.rows?.filter(ele => ele?.CasinoGame?.id !== game?.id) }
      thunkApi?.dispatch(setFavouriteGame(removeFavGame))
      thunkApi?.dispatch(setViewAllFavouriteGames(removeAllFavGame))
      removeRowGame.count === 0 ? thunkApi?.dispatch(setGameTab(gameTab.filter(item => item !== game?.gameCategory?.gameCategory))) : thunkApi?.dispatch(setCasinoFavoriteGamesList([...favouriteGamesList?.filter(item => item.type !== game?.gameCategory?.gameCategory), removeRowGame]))

      const updatedCategoryWiseGameList = categoryWiseGamesList.map(item => {
        if (item?.type === game?.gameCategory?.gameCategory) {
          const updatedRow = item?.rows?.map(ele => {
            if (game?.id === ele?.id) {
              return { ...ele, isFavourite: false }
            }
            return ele
          })
          return { ...item, rows: updatedRow }
        }
        return item
      })

      thunkApi?.dispatch(setGamesList(updatedCategoryWiseGameList))

      const updatedRow = viewAllGames?.rows?.map(item => {
        if (item?.id === game?.id) {
          return { ...item, isFavourite: false }
        } else {
          return item
        }
      })
      thunkApi?.dispatch(setViewAllGames({ ...viewAllGames, rows: updatedRow }))

      const updatedGamesInSearchPopup = allGamesSearch?.rows?.map(item => {
        if (item?.id === game?.id) {
          return { ...item, isFavourite: false }
        } else {
          return item
        }
      })
      thunkApi?.dispatch(setViewAllPopupGames({ ...viewAllGames, rows: updatedGamesInSearchPopup }))

      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCustomGameTransactions = createAsyncThunk(
  'fetch/custom-game-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getCustomGameTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCasinoTransactions = createAsyncThunk(
  'fetch/get-casino-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getSportsBookTransactions = createAsyncThunk(
  'fetch/get-sports-book-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getSportsBookTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
