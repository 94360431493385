export const SuccessMessage = {
  login: 'loggedInSuccess',
  logout: 'loggedOutSuccess',
  changePassword: 'profileMessagesChangePassword',
  resetPassword: 'passwordResetSuccess',
  signup: 'signup',
  betPlacedSuccessfully: 'betPlacedSuccessfully',
  PlayerEscapedSuccessfully: 'PlayerEscapedSuccessfully',
  tokenVerify: 'tokenVerify',
  emailVerify: 'emailVerify',
  emailUpdateOtp: 'emailUpdateOtp',
  emailChangedSuccess: 'emailChangedSuccess',
  profileUpdatedSuccess: 'profileUpdatedSuccess',
  otpVerified: 'otpVerified',
  twoFADeactivated: 'twoFADeactivated',
  withdrawRequestSent: 'withdrawRequestSent',
  userReportedSuccessfully: 'userReportedSuccessfully',
  tipSendSuccessfully: 'tipSendSuccessfully',
  limitUpdatedSuccessFully: 'limitUpdatedSuccessFully',
  cancelBetSuccessfully: 'cancelBetSuccessfully',
  connected: 'connected',
  kycRequestSubmittedSuccessfully: 'kycRequestSubmittedSuccessfully',
  bonusClaimedSuccessfully: 'bonusClaimedSuccessfully',
  betCashedOut: 'betCashedOut',
  bonusActivatedSuccessfully: 'bonusActivatedSuccessfully'
}
