import { createSlice } from '@reduxjs/toolkit'
import { mineGamePlacedBet, mineGameAutoBet, getMyBetsMineGame, mineGameOpenTile, mineGameCashOut, getMineGamePreviousRoundState, minGameTopBets, getMineGameLiveStats } from 'redux-thunk/thunk/mineGame.thunk'
import { minus, plus } from 'number-precision'
import pickBy from 'lodash/pickBy'
import { getPrecisionNumber } from 'utils/helperFunctions.utils'
import { BET_RESULT, DEFAULT_MINE_GAME_AUTOBET_ROUNDS } from 'constants/index'

const initialState = {
  loading: false,
  currentPlacedBet: null,
  currentSelectedTiles: [],
  betLock: false,
  gameEnd: false,
  myBetsDataLoading: false,
  myBetsData: [],
  topBetsData: [],
  autoBetNoOfRounds: `${DEFAULT_MINE_GAME_AUTOBET_ROUNDS}`,
  autoBetCurrentPlacedBet: null,
  isAutoPlayStarted: false,
  autoBetSuccess: false,
  strategyInputs: {
    stopOnProfit: 0,
    stopOnLoss: 0,
    maxBetAmount: 0,
    onWinEnabled: false,
    onWinIncreasePercent: 0,
    onLossEnabled: false,
    onLossIncreasePercent: 0
  },
  strategyWatchers: {
    totalBetAmount: 0,
    autoBetAmount: 0,
    netReturn: 0
  },
  isLastWin: false,
  isLastLoss: false,
  liveStats: {
    totalProfit: 0,
    totalWagered: 0,
    totalWins: 0,
    totalLost: 0
  }
}

const {
  actions: {
    setAutoBetNoOfRounds,
    addCurrentSelectedTiles,
    removeCurrentSelectedTiles,
    callMineGameRoundEnd,
    stopAutoBet,
    setAutoPlayEndAfterAnimation,
    setStrategyInputs,
    setAutoBetAmount,
    setRoundResult,
    setLiveStats
  },
  reducer
} = createSlice({
  name: 'mineGame',
  initialState,
  reducers: {
    setLiveStats: (state, action) => {
      const statistics = action.payload?.statistics
      if (statistics) {
        return {
          ...state,
          liveStats: {
            ...statistics
          }
        }
      }
      return state
    },
    setAutoPlayEndAfterAnimation: (state, action) => {
      return {
        ...state,
        autoBetCurrentPlacedBet: null,
        autoBetSuccess: false,
        isLastWin: false,
        isLastLoss: false
      }
    },
    setAutoBetNoOfRounds: (state, action) => {
      return {
        ...state,
        autoBetNoOfRounds: action.payload
      }
    },
    setRoundResult: (state, action) => {
      return {
        ...state,
        isLastWin: action.payload.isLastWin,
        isLastLoss: action.payload.isLastLoss
      }
    },
    setStrategyInputs: (state, action) => {
      return {
        ...state,
        strategyInputs: action.payload
      }
    },
    callMineGameRoundEnd: (state, action) => {
      return {
        ...state,
        betLock: false,
        gameEnd: false,
        currentSelectedTiles: initialState.currentSelectedTiles,
        currentPlacedBet: initialState.currentPlacedBet,
        isLastWin: false,
        isLastLoss: false
      }
    },
    setAutoBetAmount: (state, action) => {
      return {
        ...state,
        strategyWatchers: { ...state.strategyWatchers, autoBetAmount: action.payload }
      }
    },
    addCurrentSelectedTiles: (state, action) => {
      return {
        ...state,
        currentSelectedTiles: action.payload
      }
    },
    removeCurrentSelectedTiles: (state, action) => {
      return {
        ...state,
        currentSelectedTiles: initialState.currentSelectedTiles
      }
    },
    stopAutoBet: (state, action) => {
      return {
        ...state,
        loading: false,
        autoBetNoOfRounds: initialState.autoBetNoOfRounds,
        isAutoPlayStarted: initialState.isAutoPlayStarted,
        autoBetSuccess: initialState.autoBetSuccess,
        autoBetCurrentPlacedBet: initialState.autoBetCurrentPlacedBet,
        isLastLoss: false,
        isLastWin: false
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMineGameLiveStats.fulfilled, (state, action) => {
        const statistics = action.payload?.statistics
        if (statistics) {
          return {
            ...state,
            liveStats: {
              ...statistics
            }
          }
        }
        return state
      })
      .addCase(getMineGamePreviousRoundState.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getMineGamePreviousRoundState.fulfilled, (state, action) => {
        if (action.payload?.hasUnfinishedGame) {
          const currentSelectedTiles = action.payload?.unfinishedGameBetDetails?.playStates?.map(item => item.tile)
          return {
            ...state,
            loading: false,
            currentSelectedTiles,
            currentPlacedBet: action?.payload?.unfinishedGameBetDetails,
            betLock: true
          }
        }

        return {
          ...state,
          loading: false
        }
      })
      .addCase(getMineGamePreviousRoundState.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(mineGamePlacedBet.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(mineGamePlacedBet.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          currentPlacedBet: action.payload,
          betLock: true
        }
      })
      .addCase(mineGamePlacedBet.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(mineGameAutoBet.pending, (state, action) => {
        return {
          ...state,
          autoBetSuccess: false,
          isAutoPlayStarted: true,
          autoBetCurrentPlacedBet: initialState.autoBetCurrentPlacedBet,
          loading: true,
          isLastWin: false,
          isLastLoss: false
        }
      })
      .addCase(mineGameAutoBet.fulfilled, (state, action) => {
        const truthyStrategyInputs = pickBy(state.strategyInputs, value => Boolean(value) === true)
        let isStop = false

        let _netReturn = state.strategyWatchers.netReturn

        const _previousBetAmount = +(action.payload?.betAmount ?? 0)

        let _nextBetAmount = +(action.payload?.betAmount ?? 0)

        const _winningAmount = +(action.payload?.winningAmount ?? 0)

        if (action.payload?.result === BET_RESULT.WON) {
          _netReturn = getPrecisionNumber(plus(_netReturn, minus(_winningAmount, _previousBetAmount)))
        } else if (action.payload?.result === BET_RESULT.LOST) {
          _netReturn = getPrecisionNumber(minus(_netReturn, _previousBetAmount))
        }

        if (action.payload?.result === BET_RESULT.WON && state.strategyInputs.onWinEnabled) {
          const winPerc = +state.strategyInputs.onWinIncreasePercent ?? 0
          _nextBetAmount = getPrecisionNumber(plus(_previousBetAmount, _previousBetAmount * winPerc / 100))
        } else if (action.payload?.result === BET_RESULT.LOST && state.strategyInputs.onLossEnabled) {
          const lossPerc = +state.strategyInputs.onLossIncreasePercent ?? 0
          _nextBetAmount = getPrecisionNumber(plus(_previousBetAmount, _previousBetAmount * lossPerc / 100))
        }

        const _strategyWatchers = {
          totalBetAmount: getPrecisionNumber(plus(state.strategyWatchers.totalBetAmount, _previousBetAmount)),
          netReturn: _netReturn,
          autoBetAmount: _nextBetAmount
        }

        Object.keys(truthyStrategyInputs).forEach(strategy => {
          switch (strategy) {
            case 'stopOnProfit':
              isStop = _netReturn >= truthyStrategyInputs[strategy]
              break
            case 'stopOnLoss':
              isStop = Math.abs(_netReturn) >= truthyStrategyInputs[strategy]
              break
            case 'maxBetAmount':
              isStop = _strategyWatchers.totalBetAmount >= truthyStrategyInputs[strategy]
              break
          }
        })

        if ((+state.autoBetNoOfRounds - 1 === 0) || isStop) {
          return {
            ...state,
            loading: false,
            autoBetSuccess: true,
            isAutoPlayStarted: false,
            autoBetCurrentPlacedBet: action.payload,
            autoBetNoOfRounds: initialState.autoBetNoOfRounds,
            strategyWatchers: initialState.strategyWatchers,
            currentSelectedTiles: initialState.currentSelectedTiles
          }
        }
        return {
          ...state,
          autoBetSuccess: true,
          loading: false,
          autoBetCurrentPlacedBet: action.payload,
          autoBetNoOfRounds: state.autoBetNoOfRounds - 1,
          strategyWatchers: _strategyWatchers
        }
      })
      .addCase(mineGameAutoBet.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          autoBetSuccess: false,
          isAutoPlayStarted: false,
          autoBetNoOfRounds: initialState.autoBetNoOfRounds,
          isLastWin: false,
          isLastLoss: false
        }
      })
      .addCase(getMyBetsMineGame.pending, (state, action) => {
        return {
          ...state,
          myBetsDataLoading: true
        }
      })
      .addCase(getMyBetsMineGame.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsDataLoading: false,
          myBetsData: action.payload.rows
        }
      })
      .addCase(getMyBetsMineGame.rejected, (state, action) => {
        return {
          ...state,
          myBetsDataLoading: false
        }
      })
      .addCase(mineGameOpenTile.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(mineGameOpenTile.fulfilled, (state, action) => {
        if (action.payload.mineTile) {
          return {
            ...state,
            currentPlacedBet: action.payload,
            currentSelectedTiles: [...state.currentSelectedTiles, action.payload.tile],
            myBetsData: [action.payload, ...state.myBetsData],
            gameEnd: true,
            loading: false
          }
        }
        return {
          ...state,
          currentSelectedTiles: [...state.currentSelectedTiles, action.payload.tile],
          loading: false
        }
      })
      .addCase(mineGameOpenTile.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          isLastWin: false
        }
      })
      .addCase(mineGameCashOut.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(mineGameCashOut.fulfilled, (state, action) => {
        return {
          ...state,
          currentPlacedBet: action.payload,
          myBetsData: [action.payload, ...state.myBetsData],
          loading: false,
          gameEnd: true
        }
      })
      .addCase(mineGameCashOut.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(minGameTopBets.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(minGameTopBets.fulfilled, (state, action) => {
        return {
          ...state,
          topBetsData: action.payload?.topBets,
          loading: false
        }
      })
      .addCase(minGameTopBets.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export default reducer

export {
  stopAutoBet,
  setStrategyInputs,
  setAutoBetNoOfRounds,
  setAutoPlayEndAfterAnimation,
  callMineGameRoundEnd,
  addCurrentSelectedTiles,
  removeCurrentSelectedTiles,
  setAutoBetAmount,
  setRoundResult,
  setLiveStats
}
