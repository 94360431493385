import React, { lazy, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, withRouter, NavLink } from 'react-router-dom'
import moment from 'moment'
import { capitalize } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Howler } from 'howler'
import { userLogout } from 'redux-thunk/thunk/auth.thunk'
import { useWindowSize } from 'react-use'
import {
  setAffilateCode,
  setShowLoginPopup,
  setShowSignupPopup
} from 'redux-thunk/redux/slices/gameSetting.slice'
import { setLoginData } from 'redux-thunk/redux/slices/auth.slice'
import { setAccumulatedBonusWagering, setTotalRewards, setUserBonus } from 'redux-thunk/redux/slices/bonus.slice'
import { fetchUserInformation, getReferralState, getSignupLimit } from 'redux-thunk/thunk/user.thunk'
import {
  getAllCurrency,
  getGameSetting,
  setUpdateAffilateCount
} from 'redux-thunk/thunk/gameSetting'
import {
  setShowCashierPopup,
  setShowChat,
  setShowNotification,
  setShowSearchPopup,
  setShowSideNav,
  toggleChat,
  toggleNotification
} from 'redux-thunk/redux/slices/settings.slice'
import Wallet from 'pages/Wallet'
import Login from 'pages/Login'
import WalletSubscription from './components/WalletSubscription/index'
import {
  ProfileUserIcon,
  RefferralsProfileIcon,
  SettingProfileIcon,
  BalanceProfileIcon,
  DepositProfileIcon,
  LogoutProfileIcon,
  HeaderSearchIcon,
  BrandLogoIcon,
  MobLogoIcon,
  ChatIcon,
  DepositIcon,
  WithdrawalIcon,
  VerificationIcon,
  BellSolidIcon,
  HeaderMenuIconIcon,
  TelegramIcon,
  ReawardIcon,
  TwitterChatIcon,
  TwitchIconIcon,
  LimitIcon
} from 'components/ui-kit/Icons/svg/index'
import SearchPopup from '../SearchPopup/index'
import { openInfoToaster } from 'helpers/toaster.helpers'
import { getAuthToken } from 'helpers/cookie.helpers'
import {
  ANNOUNCEMENTS,
  LIVE_DATA,
  ROUTE_PATHS,
  SHOW_SIDE_NAV,
  TOOLTIP_VARIANT
} from 'constants/index'
import { getItem, setItem } from 'helpers/localstorage.helpers'
import { setLiveData } from 'redux-thunk/redux/slices/casino.slice'
import UserDetailsPopup from './components/Chat/components/UserDetailsPopup/index'
import TippingPopup from './components/Chat/components/UserDetailsPopup/components/TippingPopup/index'
import CashierPopup from './components/CashierPopup/index'
import ReportUserPopup from './components/Chat/components/ReportUser/index'
import useLiveAnnouncementSocketNameSpace from 'socket-resources/hooks/useAnnouncement'
import { getAnnouncements } from 'redux-thunk/thunk/announcement.thunk'
import {
  resetAnnouncements,
  setAnnouncements,
  setNewAnnouncement
} from 'redux-thunk/redux/slices/announcement.slice'
import WalletSetting from './components/CurrencyDropdown/components/WalletSettings/index'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import RightSidebar from './components/RightSidebar/index'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import ChangeProfilePopup from './components/Chat/components/ChangeProfilePopup/index'
import { getAccumulatedBonus, getTotalRewards } from 'redux-thunk/thunk/bonus.thunk'
import { usePreLogin } from 'hooks/usePreLogin'
import SuspenseWrapper from 'components/molecules/SuspenseWrapper/index'
import AcknowledgementPopup from 'pages/Crypto/components/AcknowledgementPopup/index'
import useKycSocketNameSpace from 'socket-resources/hooks/useKycSocketNameSpace'
import { setUserRank, updateKycData } from 'redux-thunk/redux/slices/user.slice'
import ForgotPasswordPopup from '../Popups/ForgotPasswordPopup/index'
import PasswordUpdation from '../Popups/PasswordUpdatedPopup/index'
import VerifyEmailPopup from '../Popups/VerifyEmailPopup/index'
import useRankingSocketNameSpace from 'socket-resources/hooks/useRankingNameSpace'
import { useMoonPay } from 'hooks/useMoonPay'
import OtpPopup from 'pages/Profile/Settings/ChangeEmail/components/OtpPopup/index'
import './header.scss'
import { formatPrice } from 'utils/helperFunctions.utils'
// import useWalletSocketNameSpace from 'socket-resources/hooks/useWalletSocketNameSpace'
import useTotalBonusAmountsSocketNameSpace from 'socket-resources/hooks/useTotalBonusAmounts'
import BetaSignupSuccessPopup from '../Popups/BetaSignupSuccessPopup'

import LottieIconButton from '../../ui-kit/LottieIconButton'
import useWageringSocketNameSpace from 'socket-resources/hooks/useWageringNameSpace'
import { chatAnimation, notificationAnimation, searchAnimation } from 'assets/animations/index'
const RewardsMenu = lazy(() => import('./components/RewardsMenu'))

const RIGHT_SIDE_BAR_SECTIONS = {
  CHAT_SECTION: 'chatSection',
  NOTIFICATION_SECTION: 'notificationSection'
}

const Header = (props) => {
  const { t } = useTranslation()
  const token = !!getAuthToken()
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const { listenLiveAnnouncementUpdateEvent } = useLiveAnnouncementSocketNameSpace()
  const { listenWageringUpdateEvent } = useWageringSocketNameSpace()
  const { showMoonPayWidget } = useMoonPay()
  const { listenRankingUpdateEvent } = useRankingSocketNameSpace()
  const { listenKycEvent } = useKycSocketNameSpace()
  const { listenTotalBonusAmountsUpdateEvent } = useTotalBonusAmountsSocketNameSpace()
  const { handleRegister } = usePreLogin()
  const isDemoGame = window?.location?.pathname?.match('/demo')
  const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false)
  const [showBetaThankYouPopup, setShowBetaThankYouPopup] = useState(false)
  const [showResetMailPopup, setShowResetMailPopup] = useState(false)
  const [showWalletSetting, setShowWalletSetting] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { width } = useWindowSize()
  const affiliateCode = new URLSearchParams(search).get('affiliateCode')
  const { loginData } = useSelector(state => state.auth)
  const { showLoginPopup, showSignupPopup, demoWallet, gameVolume } = useSelector(
    (state) => state.gameSetting
  )
  const { showWalletPopup } = useSelector((state) => state.wallet)
  const { announcements, newAnnouncements } = useSelector(
    (state) => state.announcement
  )

  const { user } = useSelector((state) => state.user)

  const { totalRewards } = useSelector((state) => state.bonus)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleBlur = useCallback(() => {
    setIsDropdownOpen(false)
  }, [])

  const handleLinkClick = () => {
    setIsDropdownOpen(false)
    if (width < 768) {
      showChat && dispatch(setShowChat(false))
    }
  }

  const accountsMenuOptions = useMemo(
    () => [
      {
        label: t('accountMenuProfile'),
        id: uuidv4(),
        path: ROUTE_PATHS.PROFILE,
        iconComponent: ProfileUserIcon
      },
      {
        label: t('accountMenuTransactions'),
        id: uuidv4(),
        path: ROUTE_PATHS.TRANSACTIONS,
        iconComponent: DepositProfileIcon
      },
      {
        label: t('balance'),
        id: uuidv4(),
        path: ROUTE_PATHS.BALANCE,
        iconComponent: BalanceProfileIcon
      },
      {
        label: t('withdrawals'),
        id: uuidv4(),
        path: ROUTE_PATHS.WITHDRAWALS,
        iconComponent: WithdrawalIcon
      },
      {
        label: t('deposit'),
        id: uuidv4(),
        path: ROUTE_PATHS.DEPOSIT,
        iconComponent: DepositIcon
      },
      {
        label: t('referrals'),
        id: uuidv4(),
        path: ROUTE_PATHS.REFERRALS,
        iconComponent: RefferralsProfileIcon
      },
      {
        label: t('settings'),
        id: uuidv4(),
        path: ROUTE_PATHS.SETTINGS,
        iconComponent: SettingProfileIcon
      },
      {
        label: t('accountVerification'),
        id: uuidv4(),
        path: ROUTE_PATHS.ACCOUNT_VERIFICATION,
        iconComponent: VerificationIcon
      },
      {
        label: t('limits'),
        id: uuidv4(),
        path: ROUTE_PATHS.LIMITS,
        iconComponent: LimitIcon
      }
    ],
    []
  )

  const {
    showSideNav,
    showChat,
    showSearchPopup,
    showUserDetailsPopup,
    showTipPopup,
    showCashierPopup,
    showReportUserPopup,
    showNotification,
    showProfileChangePopup,
    showCryptoAcknowledgementPopup,
    showForgotPasswordPopup,
    showPasswordUpdationPopup,
    showOtpPopup
  } = useSelector((state) => state?.settings)

  const handleLiveAnnouncementData = useCallback((e) => {
    dispatch(setAnnouncements(e?.data))
  }, [])

  const handleBonusWageringData = useCallback((e) => {
    dispatch(setAccumulatedBonusWagering(e?.data))
  }, [])

  const handleKycStatusData = useCallback((data) => {
    dispatch(updateKycData(data?.data))
  }, [])

  const handleRightSideBar = useCallback((section) => {
    if (section === RIGHT_SIDE_BAR_SECTIONS.CHAT_SECTION) {
      dispatch(toggleChat())
      dispatch(setShowNotification(false))
    } else if (section === RIGHT_SIDE_BAR_SECTIONS.NOTIFICATION_SECTION) {
      dispatch(toggleNotification())
      dispatch(setShowChat(false))
    }
  }, [])

  const handleRankingChange = useCallback((e) => {
    dispatch(setUserRank(e?.data))
  }, [])

  const handleTotalAmountsUpdate = useCallback((data) => {
    dispatch(setTotalRewards(data?.data))
  }, [])

  const sidenavExpand = () => {
    setItem(SHOW_SIDE_NAV, !showSideNav)
    dispatch(setShowSideNav(!showSideNav))
    document
      .getElementsByClassName('sidebar-wrap')[0]
      ?.classList.toggle('sidebar-close')
    document
      .getElementsByClassName('page-container')[0]
      ?.classList.toggle('sidebar-close')
    document
      .getElementsByClassName('header')[0]
      ?.classList.toggle('sidebar-close')
  }

  useEffect(() => {
    dispatch(
      getAnnouncements({
        startDate: moment().subtract(7, 'd').format(),
        endDate: moment().format(),
        limit: 50
      })
    )

    dispatch(
      getSignupLimit()
    )

    // TODO remove this when got the permanent solution for resizeObserver error
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  useEffect(() => {
    dispatch(resetAnnouncements())
    if (token) {
      dispatch(fetchUserInformation())
      dispatch(setLoginData({ accessToken: getAuthToken() }))
      dispatch(getTotalRewards())
      dispatch(getAccumulatedBonus())
      dispatch(getReferralState())
    }
  }, [token])

  useEffect(() => {
    if (affiliateCode) {
      dispatch(setAffilateCode(affiliateCode))
      dispatch(setUpdateAffilateCount({ affiliateCode }))
    }
  }, [window.location.pathname, affiliateCode])

  useEffect(() => {
    if (window.location.pathname === ROUTE_PATHS.SIGNUP) {
      dispatch(setShowSignupPopup(true))
    } else if (window.location.pathname === ROUTE_PATHS.LOGIN) {
      dispatch(setShowLoginPopup(true))
    }
    const cleanUpListener = listenLiveAnnouncementUpdateEvent(
      handleLiveAnnouncementData
    )
    return () => {
      cleanUpListener()
    }
  }, [])

  useEffect(() => {
    if (loginData?.accessToken) {
      const wageringListener = listenWageringUpdateEvent(handleBonusWageringData)
      const kycListenerCleaner = listenKycEvent(handleKycStatusData)
      const rankingListenerCleaner = listenRankingUpdateEvent(handleRankingChange)
      const bonusListenerCleaner = listenTotalBonusAmountsUpdateEvent(handleTotalAmountsUpdate)
      return () => {
        wageringListener()
        kycListenerCleaner()
        rankingListenerCleaner()
        bonusListenerCleaner()
      }
    }
  }, [loginData?.accessToken])

  useEffect(() => {
    dispatch(getAllCurrency())
    dispatch(getGameSetting())
    const liveDataStorage = getItem(LIVE_DATA)
    if (liveDataStorage?.length > 0) {
      for (
        let elementIndex = 0;
        elementIndex <
        (liveDataStorage?.length > 20 ? 20 : liveDataStorage?.length);
        elementIndex++
      ) {
        dispatch(setLiveData(liveDataStorage[elementIndex]))
      }
    }
  }, [])

  useEffect(() => {
    if (announcements?.rows?.length > 0) {
      setItem(ANNOUNCEMENTS, {
        ...announcements,
        rows: announcements?.rows?.slice(0, 10)
      })
      const newAnnouncement = announcements?.rows
        ?.slice(0, 3)
        ?.filter((ele) => ele?.seen || !ele?.status)
      dispatch(
        setNewAnnouncement(
          announcements?.rows?.length === newAnnouncement?.length
            ? false
            : newAnnouncement?.length < 3
        )
      )
    }
  }, [announcements])

  const handleLogOut = () => {
    dispatch(userLogout({ history }))
    localStorage.clear()
    dispatch(setUserBonus([]))
  }

  useEffect(() => {
    Howler.volume(Number(gameVolume))
  }, [gameVolume])

  const searchPopupAndChatButtonSection = (
    <div className='top-header-right d-none d-xl-flex ms-4'>
      <LottieIconButton
        active={showSearchPopup}
        lottie={searchAnimation}
        icon={<HeaderSearchIcon fill='var(--gray-20)' />}
        onClick={() => dispatch(setShowSearchPopup(true))}
      />
      <div className='ms-2' />
      <LottieIconButton
        active={showChat}
        lottie={chatAnimation}
        icon={<ChatIcon fill='var(--gray-20)' />}
        onClick={() =>
          handleRightSideBar(RIGHT_SIDE_BAR_SECTIONS.CHAT_SECTION)}
      />
      {token && (
        <div className='ms-2 position-relative'>
          <LottieIconButton
            active={showNotification}
            lottie={notificationAnimation}
            icon={<BellSolidIcon fill='var(--gray-20)' />}
            onClick={() =>
              handleRightSideBar(RIGHT_SIDE_BAR_SECTIONS.NOTIFICATION_SECTION)}
          />
          {newAnnouncements && <div className='chat-notification-icon' />}

        </div>
      )}

    </div>
  )

  return (
    <>
      <header
        className={`d-flex align-items-center  header ${
          !showSideNav ? 'sidebar-close' : ''
        }`}
      >
        <div className='d-flex align-items-center justify-content-between header-content w-100'>
          <div className='d-flex align-items-center'>
            <NavLink
              to={ROUTE_PATHS.HOME}
              className='brand-logo align-self-center d-none d-xl-block'
            >
              <BrandLogoIcon />
            </NavLink>
            <CustomTooltip
              variant={TOOLTIP_VARIANT.SIDEBAR_BUTTON}
              message={showSideNav ? t('collapse') : t('expand')}
              showTooltip
            >
              <NavLink
                to='#'
                className='header-toggle-icon d-none d-xl-block'
                onClick={sidenavExpand}
              >
                <HeaderMenuIconIcon />
              </NavLink>
            </CustomTooltip>
            <NavLink
              to={ROUTE_PATHS.HOME}
              className='brand-logo align-self-center d-block d-xl-none'
              onClick={() => {
                if (width < 768) {
                  dispatch(setShowChat(false))
                }
              }}
            >
              {/* <MobLogoIcon /> */}
              {loginData?.accessToken ? <MobLogoIcon /> : <BrandLogoIcon className='before-login-logo' />}
            </NavLink>
            {/* TODO REMOVE IT FROM THE PLACE WHEN FIGMA UPDATED */}
            <div className='dropdown reward-btn header-user-menu d-none d-md-block'>
              <button
                className='btn btn-primary account-btn dropdown-toggle d-none align-items-center'
                type='button'
              >
                <ReawardIcon />
                <div className='account-btn-mid-content px-2 d-none d-lg-block'>
                  <p className='m-0 text-uppercase'>{t('rewards')}</p>
                </div>
                <div className='dropdown-icon'>
                  <i className='fa fa-caret-down' aria-hidden='true' />
                </div>
              </button>
              {token && <SuspenseWrapper Component={RewardsMenu} style={{ height: '50px', width: '50px' }} />}
            </div>
          </div>
          {token
            ? (
              <>
                <div className='header-middle-btn d-flex align-items-center'>
                  <WalletSubscription
                    setShowWalletSetting={setShowWalletSetting}
                    sidenavExpand={sidenavExpand}
                  />
                  <button
                    className='btn btn-secondary text-uppercase d-none d-xxl-block'
                    onClick={() => dispatch(setShowCashierPopup(true))}
                  >
                    {t('cashier')}
                  </button>
                  <button
                    className='btn btn-primary  text-uppercase buy-crypto d-none d-xxl-block'
                    onClick={showMoonPayWidget}
                  >
                    {t('buyCrypto')}
                  </button>
                </div>

                <div className='header-right d-flex align-items-center'>
                  <div className='dropdown reward-btn header-user-menu d-block d-md-none ms-0'>
                    <button
                      className='btn btn-primary account-btn dropdown-toggle d-none align-items-center'
                      type='button'
                    >
                      <ReawardIcon />
                      <div className='account-btn-mid-content px-2 d-none d-lg-block'>
                        <p className='m-0 text-uppercase'>{t('rewards')}</p>
                      </div>
                      <div className='dropdown-icon'>
                        <i className='fa fa-caret-down' aria-hidden='true' />
                      </div>
                    </button>
                    {token && <SuspenseWrapper Component={RewardsMenu} style={{ height: '50px', width: '50px' }} />}
                  </div>
                  <div className={`dropdown account-btn-wrap header-user-menu${isDropdownOpen ? ' show' : ''}`}>
                    <button
                      className='btn btn-primary account-btn d-flex align-items-center border-0'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded={isDropdownOpen}
                      onClick={toggleDropdown}
                      onBlur={handleBlur}
                    >
                      {user?.userRank?.imageLogo && (
                        <img
                          src={user?.userRank?.imageLogo}
                          alt='Badge'
                        />
                      )}
                      <div className='account-btn-mid-content px-2 d-sm-block d-none'>
                        <p className='m-0'>{t('account')}</p>
                        <div className='progress'>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            aria-valuenow={`${totalRewards?.totalWager}`}
                            aria-valuemin='0'
                            aria-valuemax={`${user?.nextRankingLevels?.[0]?.wagerRequirement || 10000}`}
                            style={{
                              width: `${
                    (+totalRewards?.totalWager / +user?.nextRankingLevels?.[0]?.wagerRequirement) * 100
                  }%`,
                              backgroundColor: user?.userRank?.moreDetails?.color
                            }}
                          />
                        </div>
                      </div>
                      <div className='dropdown-icon d-sm-flex d-none'>
                        <i className={`fa fa-caret-${isDropdownOpen ? 'up' : 'down'}`} aria-hidden='true' />
                      </div>
                    </button>
                    <ul className='dropdown-menu'>
                      <div className='user-name'>
                        <h4 className='m-0'>
                          {t('hello')}, {capitalize(user?.userName) || t('user')}
                        </h4>
                      </div>
                      {accountsMenuOptions.map((item) => (
                        <li key={item.id}>
                          <NavLink to={item.path} className='dropdown-item' onClick={handleLinkClick}>
                            <item.iconComponent /> {item.label}
                          </NavLink>
                        </li>
                      ))}
                      <li>
                        <NavLink
                          to='#'
                          activeClassName='dropdown-item'
                          onClick={() => {
                            handleLogOut()
                            setIsDropdownOpen(false)
                          }}
                        >
                          <LogoutProfileIcon /> {t('accountMenuLogout')}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  {/* Chat and Games popup */}
                  {searchPopupAndChatButtonSection}
                  {(showChat || showNotification) && <RightSidebar />}
                </div>
                <div className='header-right d-none'>
                  {isDemoGame && (
                    <button className='btn btn-secondary me-2'>
                      {t('demoWallet')}: {formatPrice(Number(demoWallet), false, 3)}
                    </button>
                  )}
                </div>
              </>
              )
            : (
              <div className='header-right d-flex align-items-center'>
                <div className='header-social-links d-none d-md-flex align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault()
                      openInfoToaster({ message: 'Coming Soon!' })
                    }}
                  >
                    <TwitchIconIcon fill='var(--gray-20)' />
                  </a>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault()
                      openInfoToaster({ message: 'Coming Soon!' })
                    }}
                  >
                    <TelegramIcon fill='var(--gray-20)' />
                  </a>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault()
                      openInfoToaster({ message: 'Coming Soon!' })
                    }}
                  >
                    <TwitterChatIcon fill='var(--gray-20)' />
                  </a>
                </div>

                {isDemoGame && (
                  <button className='btn btn-secondary border-2 me-2'>
                    {t('demoWallet')}: {formatPrice(Number(demoWallet), false, 3)}
                  </button>
                )}
                <button
                  className='btn auth-btn btn-primary ms-4 me-2'
                  onClick={() => handleRegister({ showLoginPopup: true })}
                >
                  {t('login')}
                </button>
                <button
                  className='btn auth-btn btn-secondary'
                  onClick={handleRegister}
                >
                  {t('signupTitle')}
                </button>
                {/* Chat and Games popup */}
                {searchPopupAndChatButtonSection}
                {(showChat || showNotification) && <RightSidebar />}
              </div>
              )}
        </div>
      </header>

      {showVerifyEmailPopup && (
        <VerifyEmailPopup handleClose={setShowVerifyEmailPopup} />
      )}

      {showBetaThankYouPopup && (
        <BetaSignupSuccessPopup handleClose={setShowBetaThankYouPopup} />
      )}

      {showSignupPopup && (
        <Login
          showSignupPopup={showSignupPopup}
          setShowVerifyEmailPopup={setShowVerifyEmailPopup}
          setShowBetaThankYouPopup={setShowBetaThankYouPopup}
        />
      )}
      {showLoginPopup && (
        <Login setShowVerifyEmailPopup={setShowVerifyEmailPopup} setShowBetaThankYouPopup={setShowBetaThankYouPopup} />
      )}
      {showResetMailPopup && (
        <CommonPopup
          modalCloseHandler={() => {
            setShowResetMailPopup(false)
          }}
        >
          <div className='d-flex justify-content-center text-center py-4'>
            <h4>{t('signinResetMailSentSuccessContent')}</h4>
          </div>
        </CommonPopup>
      )}
      {showWalletPopup && <Wallet />}
      {showSearchPopup && (
        <SearchPopup setShowSearchPopup={setShowSearchPopup} />
      )}
      {showUserDetailsPopup && <UserDetailsPopup />}
      {showProfileChangePopup && <ChangeProfilePopup />}
      {showTipPopup && <TippingPopup />}
      {showCashierPopup && <CashierPopup showMoonPayWidget={showMoonPayWidget} />}
      {showReportUserPopup && <ReportUserPopup />}
      {showWalletSetting && (
        <WalletSetting onClose={() => setShowWalletSetting(false)} />
      )}
      {showCryptoAcknowledgementPopup && <AcknowledgementPopup />}
      {showForgotPasswordPopup && <ForgotPasswordPopup />}
      {showPasswordUpdationPopup && <PasswordUpdation />}
      {showOtpPopup && <OtpPopup />}
    </>
  )
}

export default React.memo(withRouter(Header))
