import { createSlice } from '@reduxjs/toolkit'
import { getBetByToken } from 'redux-thunk/thunk/gameSetting'

const initialState = {
  betByToken: null,
  searchParam: ''
}

const {
  actions: { setBetbyToken, setSearchParam },
  reducer
} = createSlice({
  name: 'sportsbook',
  initialState,
  reducers: {
    setBetbyToken: (state, action) => ({
      ...state,
      betByToken: action.payload
    }),
    setSearchParam: (state, action) => ({
      ...state,
      searchParam: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBetByToken.fulfilled, (state, action) => {
        return {
          ...state,
          betByToken: action.payload
        }
      })
  }
})

export default reducer
export { setBetbyToken, setSearchParam }
