import * as PIXI from 'pixi.js'
import { path, config } from "./settings.js"
import { app } from "./appPixi.js"

const progressBarContainer = new PIXI.Container()
progressBarContainer.name = 'ProgressBar'
const progressBarBG = new PIXI.Sprite()
const progressBar = new PIXI.Sprite()
const loadingTxtStyle = new PIXI.TextStyle({
  fill: 0xffffff,
  fontWeight: 'bold',
  fontSize: 17
})

const loadingPercent = new PIXI.Text('0%', loadingTxtStyle)
const loadingTxt = new PIXI.Text('Loading ', loadingTxtStyle)

export async function createProgressBar () {
  app.stage.addChild(progressBarContainer)

  PIXI.Assets.add('progressBar', path.spriteSheetsPath + 'progress_bar.png')
  await PIXI.Assets.load('progressBar')

  progressBarBG.texture = await PIXI.Assets.load('progressBar')
  progressBarBG.anchor.set(0.5)
  progressBarBG.tint = 0x2d3436
  progressBarBG.x = app.screen.width / 2
  progressBarBG.y = app.screen.height / 2

  progressBar.texture = await PIXI.Assets.load('progressBar')
  progressBar.x = app.screen.width / 2 - progressBarBG.width / 2
  progressBar.y = app.screen.height / 2 - progressBar.height / 2
  progressBar.scale.x = 0

  loadingTxt.x = progressBarBG.x - loadingTxt.width / 2 - loadingPercent.width
  loadingTxt.y = progressBarBG.y + loadingTxt.height

  loadingPercent.x = loadingTxt.x + loadingTxt.width
  loadingPercent.y = loadingTxt.y

  progressBarContainer.addChild(progressBarBG)
  progressBarContainer.addChild(loadingTxt, loadingPercent, progressBar)
}

export function assetsLoadProgress (progress) {
  loadingPercent.text = Math.floor(progress * 100) + '%'

  progressBar.scale.x = progress
  if (progress === 1) {
    progressBarContainer.visible = false
  }
}
