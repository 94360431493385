import * as PIXI from 'pixi.js'
import { createProgressBar } from "./progress";
import { config } from "./settings";

export const app = new PIXI.Application({
  width: config.originalWidth,
  height: config.originalHeight,
  backgroundColor: 0x000000,
  antialias: true,
  backgroundAlpha: 0,
});

globalThis.__PIXI_APP__ = app;

function appTransform (x, y) {
  app.view.style.width = x + "px";
  app.view.style.height = y + "px";
}

createProgressBar()
appTransform(700);
