import { createAsyncThunk } from '@reduxjs/toolkit'
import { generateMoonpaySignatureService } from 'network/services/moonpay.service'

export const generateMoonpaySignature = createAsyncThunk(
  'moonpay/generate-signature', async ({ payload }, thunkApi) => {
    try {
      const res = await generateMoonpaySignatureService(payload)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
