import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import NAMESPACES from 'socket-resources/namespaces/index'
import { BONUS_AMOUNTS } from 'socket-resources/event-constants/totalBonusAmounts/index'
import config from 'config/app.config'

const useTotalBonusAmountsSocketNameSpace = () => {
  const { loginData } = useSelector(state => state.auth) // Fetch the latest token

  const listenTotalBonusAmountsUpdateEvent = (cb) => {
    const totalBonusAmountsInstance = io(`${config.SOCKET_URL}${NAMESPACES.TOTAL_BONUS_AMOUNTS}`, {
      auth: {
        authorization: `Bearer ${loginData?.accessToken}`
      }
    })

    totalBonusAmountsInstance.on('connect', () => {
      // console.log('Connected to TOTAL BONUS namespace with new token')
    })

    totalBonusAmountsInstance.on('disconnect', () => {
      // console.log('Socket disconnected from TOTAL BONUS namespace')
    })
    if (cb) {
      totalBonusAmountsInstance.on(BONUS_AMOUNTS, cb)
    }
    return () => {
      totalBonusAmountsInstance?.off(BONUS_AMOUNTS, cb)
      totalBonusAmountsInstance?.disconnect()
    }
  }

  return { listenTotalBonusAmountsUpdateEvent }
}

export default useTotalBonusAmountsSocketNameSpace
