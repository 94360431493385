import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { startCase } from 'lodash'
import { useReferral } from 'hooks/useReferral'
import './referralSection.scss'

const CashierReferrals = () => {
  const { t } = useTranslation()
  const { inviteFriendsStatus } = useSelector(state => state.settings)
  const {
    err,
    handleChangeInput,
    handleClick,
    isReferralLinkAvailable,
    referralLink
  } = useReferral()

  return (
    <div
      className={`tab-pane fade ${inviteFriendsStatus ? 'show active' : ''}`}
      id='pills-cashier-referrals'
      role='tabpanel'
      aria-labelledby='pills-cashier-referrals-tab'
      tabIndex='0'
    >
      <div className='coupon-section referral-section'>
        <img
          src='/assets/images/homePage/coingold.svg'
          alt='Gold'
        />
        <h4 className='text-uppercase'>{t('applyReferralCode')}</h4>
        <div className='modal-inner-card'>
          <div className='change-username'>
            <div className='form-group text-start'>
              <label htmlFor='newUser' className='form-label'>
                {startCase(t('referralLink'))}
              </label>
              <div className='position-relative'>
                <input
                  type='text'
                  className={`form-control form-copy shadow-none ${err ? 'error-input' : ''}`}
                  id='newUser'
                  value={referralLink}
                  placeholder={isReferralLinkAvailable ? referralLink : t('setReferralLink')}
                  readOnly={isReferralLinkAvailable}
                  onChange={handleChangeInput}
                />
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={handleClick}
                >

                  <span>{isReferralLinkAvailable
                    ? (
                      <>
                        <span className='d-none d-md-block'>{t('copyLink')}</span>
                        <span className='d-md-none'>{t('copy')}</span>
                      </>
                      )
                    : t('create')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CashierReferrals)
