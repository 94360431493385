import { createSlice } from '@reduxjs/toolkit'
import { getItem, setItem } from 'helpers/localstorage.helpers'

const initialState = {
  showSideNav: true,
  showChat: false,
  isChatCollapse: true,
  showSearchPopup: false,
  showUserDetailsPopup: false,
  showTipPopup: false,
  showCashierPopup: false,
  showOtpPopup: false,
  showReportUserPopup: false,
  showNotification: false,
  inviteFriendsStatus: false,
  showProfileChangePopup: false,
  showCryptoAcknowledgementPopup: false,
  showCryptoAcknowledgement: getItem('showCryptoAcknowledgement') ?? true,
  showTwoFactorAuthorizationPopup: false,
  isPwaInstalled: false,
  showForgotPasswordPopup: false,
  showPasswordUpdationPopup: false
}

const {
  actions: {
    setShowSideNav,
    setChatCollapse,
    setShowChat,
    setShowSearchPopup,
    setShowUserDetailsPopup,
    setShowTipPopup,
    setShowCashierPopup,
    setShowOtpPopup,
    setShowReportUserPopup,
    setShowNotification,
    setInviteFriendsStatus,
    setShowProfileChangePopup,
    setShowCryptoAcknowledgementPopup,
    setShowTwoFactorAuthorizationPopup,
    setPwaInstalled,
    setShowForgotPasswordPopup,
    setShowPasswordUpdatedPopup,
    toggleSearchPopup,
    toggleChat,
    toggleNotification
  },
  reducer
} = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setShowProfileChangePopup: (state, { payload }) => ({
      ...state,
      showProfileChangePopup: payload
    }),
    setShowNotification: (state, { payload }) => ({
      ...state,
      showNotification: payload
    }),
    toggleNotification: (state, { payload }) => ({
      ...state,
      showNotification: !state.showNotification
    }),
    setInviteFriendsStatus: (state, { payload }) => ({
      ...state,
      inviteFriendsStatus: payload
    }),
    setShowCashierPopup: (state, { payload }) => ({
      ...state,
      showCashierPopup: payload
    }),
    setShowOtpPopup: (state, { payload }) => ({
      ...state,
      showOtpPopup: payload
    }),
    setShowTipPopup: (state, { payload }) => ({
      ...state,
      showTipPopup: payload
    }),
    setShowUserDetailsPopup: (state, { payload }) => ({
      ...state,
      showUserDetailsPopup: payload
    }),
    setShowSideNav: (state, { payload }) => ({
      ...state,
      showSideNav: payload
    }),
    setChatCollapse: (state, { payload }) => ({
      ...state,
      isChatCollapse: payload
    }),
    setShowChat: (state, { payload }) => ({
      ...state,
      showChat: payload
    }),
    toggleChat: (state, { payload }) => ({
      ...state,
      showChat: !state.showChat
    }),
    setShowSearchPopup: (state, { payload }) => ({
      ...state,
      showSearchPopup: payload
    }),
    toggleSearchPopup: (state, payload) => ({
      ...state,
      showSearchPopup: !state.showSearchPopup
    }),
    setShowReportUserPopup: (state, action) => ({
      ...state,
      showReportUserPopup: action.payload
    }),
    setShowCryptoAcknowledgementPopup: (state, action) => {
      setItem('showCryptoAcknowledgement', action.payload)
      return {
        ...state,
        showCryptoAcknowledgementPopup: action.payload,
        showCryptoAcknowledgement: action.payload
      }
    },
    setShowTwoFactorAuthorizationPopup: (state, { payload }) => ({
      ...state,
      showTwoFactorAuthorizationPopup: payload
    }),
    setPwaInstalled: (state, { payload }) => ({
      ...state,
      isPwaInstalled: payload
    }),
    setShowForgotPasswordPopup: (state, { payload }) => ({
      ...state,
      showForgotPasswordPopup: payload
    }),
    setShowPasswordUpdatedPopup: (state, { payload }) => ({
      ...state,
      showPasswordUpdationPopup: payload
    })
  }
})

export default reducer
export {
  setShowSideNav,
  setChatCollapse,
  setShowChat,
  setShowSearchPopup,
  setShowUserDetailsPopup,
  setShowTipPopup,
  setShowCashierPopup,
  setShowOtpPopup,
  setShowReportUserPopup,
  setShowNotification,
  setInviteFriendsStatus,
  setShowProfileChangePopup,
  setShowCryptoAcknowledgementPopup,
  setShowTwoFactorAuthorizationPopup,
  setPwaInstalled,
  setShowForgotPasswordPopup,
  setShowPasswordUpdatedPopup,
  toggleSearchPopup,
  toggleChat,
  toggleNotification
}
