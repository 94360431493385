import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'
import { SuccessMessage } from 'network/messages/successMessages'

export const getSumsubTokenService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/kyc-verification/init-access-token', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const updateUserInformationLevelOneKycService = (data) => {
  return axiosInstanceService(METHOD_TYPES.put, '/kyc-verification/update-kyc', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.kycRequestSubmittedSuccessfully
  })
}
