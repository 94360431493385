const config = {
  apiGateways: {
    // BASE_URL_1: 'https://user-api.clutch.app/api/v1'
    BASE_URL_1: `${process.env.REACT_APP_BACKEND_URL_HTTP}`
  },
  // SOCKET_URL: 'wss://user-api.clutch.app',
  SOCKET_URL: `${process.env.REACT_APP_BACKEND_URL_WS}`,
  META_MASK_SIGN_MESSAGE: `${process.env.REACT_APP_META_MASK_SIGN_MESSAGE}`,
  TWITCH_CLIENT_ID: `${process.env.REACT_APP_TWITCH_CLIENT_ID}`,
  GOOGLE_CLIENT_ID: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
  LOGIN_REDIRECT_URL: `${process.env.REACT_APP_LOGIN_REDIRECT_URL}`,
  CONNECTION_REDIRECT_URL: `${process.env.REACT_APP_CONNECTION_REDIRECT_URL}`,
  MOONPAY_PUB_KEY: `${process.env.REACT_APP_MOONPAY_PUBLIC_KEY}`,
  S3_PATH: `${process.env.REACT_APP_S3_URL}`,
  DOMAIN_TOKEN: `${process.env.REACT_APP_DOMAIN_TOKEN}`,
  MOONPAY_ENVIRONMENT: `${process.env.REACT_APP_MOONPAY_ENVIRONMENT}`,
  BETBY_BRAND_ID: `${process.env.REACT_APP_BETBY_BRAND_ID}`
}

export default config
