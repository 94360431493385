import { createSlice } from '@reduxjs/toolkit'
import {
  getAllFavouriteGames,
  getAllGames,
  getCasinoAllCategories,
  getCasinoFavoriteGamesList,
  getCasinoGamesList,
  getCasinoProvidersList,
  getCasinoTransactions,
  getCustomGameTransactions,
  getSearchedGames,
  getSportsBookTransactions,
  getUsersCasinoTransactions,
  getViewAllFavoriteGamesList
} from 'redux-thunk/thunk/game.thunk'

const initialState = {
  providers: null,
  casinoCategoriesList: null,
  categoryWiseGamesList: [],
  favouriteGamesList: [],
  favouriteGames: null,
  allFavouriteGames: null,
  usersCasinoTransactions: null,
  viewAllGames: null,
  showAllFavGames: false,
  customFavourites: {},
  customGameTransactions: {},
  filteredGames: null
}

const {
  actions: {
    setFavouriteGame,
    setShowAllFavGames,
    setGamesList,
    setViewAllGames,
    setCustomFavGames,
    setCustomGameTransactions,
    setFilteredGames,
    setCasinoFavoriteGamesList,
    setViewAllFavouriteGames
  },
  reducer
} = createSlice({
  name: 'games',
  initialState: initialState,
  reducers: {
    setFilteredGames: (state, action) => ({
      ...state,
      filteredGames: action.payload
    }),
    setCustomGameTransactions: (state, action) => ({
      ...state,
      customGameTransactions: action.payload
    }),
    setFavouriteGame: (state, action) => ({
      ...state,
      favouriteGames: action.payload
    }),
    setShowAllFavGames: (state, action) => ({
      ...state,
      showAllFavGames: action.payload
    }),
    setGamesList: (state, action) => ({
      ...state,
      categoryWiseGamesList: action?.payload
    }),
    setViewAllGames: (state, action) => ({
      ...state,
      viewAllGames: action?.payload
    }),
    setCustomFavGames: (state, action) => ({
      ...state,
      customFavourites: action?.payload
    }),
    setCasinoFavoriteGamesList: (state, action) => ({
      ...state,
      favouriteGamesList: action.payload
    }),
    setViewAllFavouriteGames: (state, action) => ({
      ...state,
      allFavouriteGames: action?.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCasinoGamesList.fulfilled, (state, action) => {
        return {
          ...state,
          categoryWiseGamesList: action?.payload
        }
      })
      .addCase(getCasinoFavoriteGamesList.fulfilled, (state, action) => {
        return {
          ...state,
          favouriteGamesList: action?.payload
        }
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        return {
          ...state,
          viewAllGames: action?.payload
        }
      })
      .addCase(getSearchedGames.fulfilled, (state, action) => {
        return {
          ...state,
          filteredGames: action?.payload
        }
      })
      .addCase(getCasinoProvidersList.fulfilled, (state, action) => {
        return {
          ...state,
          providers: action?.payload
        }
      })
      .addCase(getCasinoAllCategories.fulfilled, (state, action) => {
        state.casinoCategoriesList = action?.payload?.rows
      })
      .addCase(getUsersCasinoTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersCasinoTransactions: action?.payload
        }
      })
      .addCase(getAllFavouriteGames.fulfilled, (state, action) => {
        return {
          ...state,
          favouriteGames: action?.payload
        }
      })
      .addCase(getViewAllFavoriteGamesList.fulfilled, (state, action) => {
        return {
          ...state,
          allFavouriteGames: action?.payload
        }
      })
      .addCase(getCustomGameTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          customGameTransactions: action?.payload

        }
      })
      .addCase(getCasinoTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          casinoTransactions: action?.payload
        }
      })
      .addCase(getSportsBookTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          sportsBookTransactions: action?.payload
        }
      })
  }
})

export default reducer
export {
  setFilteredGames,
  setCustomGameTransactions,
  setFavouriteGame,
  setShowAllFavGames,
  setGamesList,
  setViewAllGames,
  setCustomFavGames,
  setCasinoFavoriteGamesList,
  setViewAllFavouriteGames
}
