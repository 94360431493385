import { useState } from 'react'
import { usePreLogin } from './usePreLogin'

export const useLoginBeforeRedirect = () => {
  const [path, setPath] = useState('')
  const { handleRegister } = usePreLogin({ path })

  const handleLoginBeforeRedirect = (redirectPath) => {
    setPath(redirectPath)
    handleRegister({ showLoginPopup: true })
  }

  return { handleLoginBeforeRedirect }
}
