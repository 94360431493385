import React, { useState, useRef, useEffect } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setShowProfileChangePopup } from 'redux-thunk/redux/slices/settings.slice'
import ErrorMessage from 'components/layout/ErrorMessage'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { openErrorToaster } from 'helpers/toaster.helpers'
import { updateProfilePic } from 'redux-thunk/thunk/user.thunk'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import Loader from 'components/ui-kit/Loader/index'
import { DragIcon, UploadFilesIcon } from 'components/ui-kit/Icons/svg/index'
import 'cropperjs/dist/cropper.css'
import './profilePopup.scss'

const format = ['png', 'jpeg', 'jpg']

const ChangeProfilePopup = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [fileUrl, setFileUrl] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const [error, setError] = useState('')
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  )
  const [cropper, setCropper] = useState(null)
  const cropperContainerRef = useRef(null)
  const [zoomValue, setZoomValue] = useState(0.1)

  const zoomEventListener = (event) => {
    const newZoomValue = event.detail.ratio

    if (parseInt(newZoomValue) > 10) {
      event.preventDefault() // Prevent zooming in
    } else {
      setZoomValue(newZoomValue)
    }
  }

  useEffect(() => {
    if (cropper) {
      const instance = cropperContainerRef.current

      instance.addEventListener('zoom', zoomEventListener)

      return () => {
        instance.removeEventListener('zoom', zoomEventListener)
      }
    }
  }, [cropper])

  const fileChangedHandler = (event) => {
    setError('')
    const currentFileSizeInKB =
      event.target.files[0] && event.target.files[0].size / 1000
    const currentFileFormat =
      event.target.files[0] && event.target.files[0].name.split('.')[1]
    if (currentFileSizeInKB <= 2048) {
      if (format.indexOf(currentFileFormat) > -1) {
        const [file] = event.target.files
        setSelectedFile(file)
        setFileUrl(URL.createObjectURL(event.target.files[0]))
      } else {
        setError(t('imageSizeFormat'))
      }
    } else {
      setError(t('imageSizeMustLessThan', { size: 2 }))
    }
  }

  const uploadProfilePicHandler = async () => {
    if (!error.length) {
      if (selectedFile && cropper) {
        try {
          const croppedImageBlob = await getCroppedImageBlob(
            cropper,
            selectedFile
          )
          const data = new FormData()
          data.append('imageFile', croppedImageBlob, 'profile_pic.jpg')
          dispatch(updateProfilePic({ data }))
        } catch (e) {
          openErrorToaster({ message: e.message })
        }
      } else {
        openErrorToaster({ message: t('pleaseSelectImage') })
      }
    }
  }

  const modalCloseHandler = () => {
    dispatch(setShowProfileChangePopup(false))
    const element = document.querySelector('.user-profile-modal')
    element?.classList?.remove('remove-blur')
  }

  const getCroppedImageBlob = (cropper) => {
    return new Promise((resolve, reject) => {
      const croppedCanvas = cropper.getCroppedCanvas({
        width: 160,
        height: 160
      })
      if (croppedCanvas) {
        croppedCanvas.toBlob((blob) => {
          if (blob) {
            resolve(blob)
          } else {
            reject(new Error('Failed to get the cropped image blob.'))
          }
        })
      } else {
        reject(new Error('No cropping area selected.'))
      }
    })
  }

  const handleZoomChange = (event) => {
    const newZoom = parseFloat(event.target.value)
    setZoomValue(newZoom)
    cropper.zoomTo(newZoom)
  }

  const handleZoomIn = () => {
    const newZoom = Math.min(zoomValue + 0.5, 10)
    setZoomValue(newZoom)
    cropper.zoomTo(newZoom)
  }

  const handleZoomOut = () => {
    const newZoom = Math.max(zoomValue - 0.5, 0.1)
    setZoomValue(newZoom)
    cropper.zoomTo(newZoom)
  }

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      classes='chat-modal tip-modal upload-modal'
      id='profile-pic-modal'
      modalBodyClasses='p-0'
    >
      <div className='pt-4 pb-0 px-4'>
        <h3 className='text-uppercase'>{t('profileUploadProfile')}</h3>
      </div>
      <form>
        <div className='px-4'>
          <div className='mb-2 form-group profile-pic-wrapper'>
            {!fileUrl && (
              <div className='image-upload-box d-flex flex-column gap-1 justify-content-center align-items-center '>
                <UploadFilesIcon />
                <div>
                  <p className='drop-title'>
                    Drop your file’s here or{' '}
                    <span className='position-relative'>
                      browse
                      <input
                        type='file'
                        accept='image/*'
                        onChange={(e) => {
                          fileChangedHandler(e)
                        }}
                      />
                    </span>
                    {!!error.length && (
                      <ErrorMessage
                        className='color-red text-danger error-msg login-input mt-2'
                        message={error}
                      />
                    )}
                  </p>
                </div>
                <p className='drop-limit'>{t('maxFileSize')}</p>
              </div>
            )}
            {fileUrl && (
              <div
                className='preview-wrap d-flex flex-column justify-content-center align-items-center gap-2'
                ref={cropperContainerRef}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                  left: 0
                }}
              >
                <p className='preview-heading'>
                  <DragIcon />
                  {t('dragPosition')}
                </p>
                <div style={{ width: '160px', maxWidth: '160px', maxHeight: '160px', height: '160px' }}>
                  <Cropper
                    src={fileUrl}
                    aspectRatio={1}
                    guides={false}
                    cropBoxResizable={false}
                    cropBoxMovable={false}
                    autoCropArea={1}
                    viewMode={3}
                    background={false}
                    zoomable
                    zoomOnWheel
                    dragMode='move'
                    // style={{ width: '160px', height: '160px' }}
                    minContainerWidth={124}
                    minContainerHeight={124}
                    minCropBoxWidth={160}
                    minCropBoxHeight={160}
                    // cropBoxStyle={{ width: '124px', height: '124px' }}
                    onInitialized={(instance) => {
                      setCropper(instance)
                    }}
                  />
                </div>
                <div
                  className='d-flex align-items-center justify-content-center '
                  style={{ width: '100%', gap: '4px' }}
                >
                  <FontAwesomeIcon
                    icon={faMinus}
                    onClick={handleZoomOut}
                    disabled={zoomValue === 0.1}
                    style={{
                      color:
                        zoomValue === 0.1 ? 'rgba(107, 117, 139, 1)' : '#fff'
                    }}
                  />
                  <input
                    type='range'
                    min='0.1'
                    max='10'
                    step='0.1'
                    value={zoomValue}
                    style={{
                      width: '280px',
                      height: '4px',
                      margin: '0 5px',
                      background: 'rgba(29, 44, 60, 1)',
                      outline: 'none',
                      border: 'none',
                      WebkitAppearance: 'none'
                    }}
                    onChange={handleZoomChange}
                  />
                  <FontAwesomeIcon
                    icon={faPlus}
                    onClick={handleZoomIn}
                    disabled={zoomValue === 10}
                    style={{
                      color:
                        zoomValue === 10 ? 'rgba(107, 117, 139, 1)' : '#fff'
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='modal-footer border-0 justify-content-end '>
          <button
            type='button'
            className='btn btn-primary me-2'
            onClick={modalCloseHandler}
            disabled={loading}
          >
            <span>{t('cancel')}</span>
          </button>
          <button
            type='submit'
            className='btn btn-secondary'
            disabled={loading || !!error.length || !fileUrl}
            onClick={(e) => {
              e.preventDefault()
              uploadProfilePicHandler()
            }}
          >
            {loading
              ? (
                <Loader variant={LOADER_HANDLER_TYPES.submit} />
                )
              : (
                <span>{t('upload')}</span>
                )}
          </button>
        </div>
      </form>
    </CommonPopup>
  )
}

export default React.memo(ChangeProfilePopup)
