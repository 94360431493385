import { SuccessMessage } from 'network/messages/successMessages'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'

export const allRoundPlacedBets = ({ params }) => {
  return axiosInstanceService(METHOD_TYPES.get, 'crash-game/get-all-round-placed-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
    // headers: { 'demo-game': isDemoGame }
  })
}

export const cancelCrashBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/crash-game/cancel-bet-crash-game', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.cancelBetSuccessfully
  })
}

export const crashCheckFairnessService = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'crash-game/check-provable-fair', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const escapeCrashBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/crash-game/player-escape-crashGame', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.PlayerEscapedSuccessfully
  })
}

export const getAllBetDataService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/system/get-total-bets', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getAllRoundHistoryService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-history', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getCrashGameRoundDetailService = (roundId) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-round-detail', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: { roundId }
  })
}

export const getHighrollerCrashService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/top-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const getMyBetsService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-status', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getTotalWinningAmountService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/system/get-total-winning-amount', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const placeBetCrashGameService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/crash-game/place-bet-crash-game', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.betPlacedSuccessfully
  })
}

export const getAllbetOfOneRoundService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/all-placed-bets', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}
