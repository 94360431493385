// import {
//   configureChains, createClient
// } from 'wagmi'

// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider
// } from '@web3modal/ethereum'
// import { arbitrum, mainnet, polygon } from 'wagmi/chains'
// import { customize, customSettings } from 'components/Game/scripts/customizations'
import CryptoConvert from 'crypto-convert'
import { DEFAULT_PRECISION } from 'constants/index'
import { openErrorToaster, openSuccessToaster } from 'helpers/toaster.helpers'
import { getTranslation } from 'helpers/translations.helpers'
import { capitalize } from 'lodash'
import moment from 'moment'
import { rollerCoaster } from 'components/ui-kit/Icons/png/index'

const convert = new CryptoConvert({
  cryptoInterval: (10 * 1000), // Crypto prices update interval in ms (default 5 seconds on Node.js & 15 seconds on Browsers)
  fiatInterval: (60 * 1e3 * 60), // Fiat prices update interval (default every 1 hour)
  calculateAverage: true, // Calculate the average crypto price from exchanges
  binance: true, // Use binance rates
  bitfinex: true, // Use bitfinex rates
  coinbase: true, // Use coinbase rates
  kraken: true, // Use kraken rates
  HTTPAgent: null
})

// const chains = [arbitrum, mainnet, polygon]

// const { provider } = configureChains(chains, [
//   w3mProvider({ projectId: process..env.REACT_APP_WALLET_CONNECT_PROJECT_ID })
// ])

// export const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId: process..env.REACT_APP_WALLET_CONNECT_PROJECT_ID, appName: 'web3Modal', chains }),
//   provider
// })

// Web3Modal Ethereum Client
// export const ethereumClient = new EthereumClient(wagmiClient, chains)

export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  }
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1])
    if (e) {
      x *= Math.pow(10, e - 1)
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2)
    }
  } else {
    let e = parseInt(x.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      x /= Math.pow(10, e)
      x += (new Array(e + 1)).join('0')
    }
  }
  return x
}

export const closeAllModals = () => {
  // get modals
  const modals = document.getElementsByClassName('modal')

  // on every modal change state like in hidden modal
  for (let i = 0; i < modals.length; i++) {
    modals[i].classList.remove('show')
    modals[i].setAttribute('aria-hidden', 'true')
    modals[i].setAttribute('style', 'display: none')
  }

  // get modal backdrops
  const modalsBackdrops = document.getElementsByClassName('modal-backdrop')

  // remove every modal backdrop
  for (let i = 0; i < modalsBackdrops.length; i++) {
    document.body.removeChild(modalsBackdrops[i])
  }

  // remove body class modal
  document.body.classList.remove('modal-open')
}

export const closeModal = (id) => {
  // get modals
  const modals = document.getElementById(id)

  modals.classList.remove('show')
  modals.setAttribute('aria-hidden', 'true')
  modals.setAttribute('style', 'display: none')

  // get modal backdrops
  const modalsBackdrops = document.getElementsByClassName('modal-backdrop')

  // remove every modal backdrop
  for (let i = 0; i < modalsBackdrops.length; i++) {
    document.body.removeChild(modalsBackdrops[i])
  }

  // remove body class modal
  document.body.classList.remove('modal-open')
}

// const formatterToCurrency = (currency) => {
//   const formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency
//   })
//   return formatter
// }

export const cryptoToFiat = async (from, to, amount) => {
  const testCoinToReal = { LTCT: 'LTC' }
  const fromCoin = testCoinToReal[from] || from
  const toCoin = testCoinToReal[to] || to
  await convert.ready()
  return await convert[fromCoin][toCoin](Number(amount))
  // return formatterToCurrency(toCoin).format(convert[fromCoin][toCoin](Number(amount)))
}

export const restrictInputToNumeric = (event) => {
  const input = event.target
  const value = input.value
  const newValue = value.replace(/\D/g, '') // Remove non-numeric characters
  input.value = newValue
}

export const getPrecision = (amount, precision = DEFAULT_PRECISION) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(amount * precisionDivide) / precisionDivide
  return result
}

export const handleCopyCode = (value, message) => {
  const isCopySuccessfull = copyToClipboard(value)
  if (!isCopySuccessfull) return openErrorToaster({ message: getTranslation('messagesCopyNotSupported') })

  openSuccessToaster({ message })
  return null
}

export const capitalizeWords = (str) => {
  return str.split(' ').map(word => capitalize(word)).join(' ')
}

export const startTime = (time) => {
  return moment().startOf(time).format()
}

export const endTime = (time) => {
  return moment().endOf(time).format()
}

export const cryptoIcon = (id) => {
  switch (id) {
    case '1':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'
    case '2':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png'
    case '3':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png'
    case '4':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png'
    case '5':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png'
    case '6':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png'
    case '7':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png'
    case '8':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/16116.png'
    case '9':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png'
    case '10':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png'
    case '11':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png'
    case '12':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png'
    case '13':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png'
    case '14':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png'
    case '15':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/18876.png'
    case '16':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/21794.png'
    case '17':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png'
    case '18':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png'
    case '19':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png'
    case '20':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png'
    case '21':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1831.png'
    case '22':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/13502.png'
    case '23':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png'
    case '24':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/28301.png'
    case '25':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/7226.png'
    case '26':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/20396.png'
    case '27':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/23121.png'
    case '28':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/28177.png'
    case '29':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/23095.png'
    case '30':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/23149.png'
    case '31':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/22861.png'
    default:
      return rollerCoaster
  }
}

export const cryptoRadialGradientColor = (id) => {
  switch (id) {
    case '1':
      return '247, 147, 26'
    case '2':
      return '236, 239, 240'
    case '3':
      return '186, 159, 51'
    case '4':
      return '52, 93, 157'
    case '5':
      return '240, 185, 11'
    case '6':
      return '111, 115, 119'
    case '7':
      return '51, 93, 210'
    case '8':
      return '119, 99, 229'
    case '9':
      return '1, 51, 174'
    case '10':
      return '255, 0, 19'
    case '11':
      return '230, 0, 122'
    case '12':
      return '230, 0, 122'
    case '13':
      return '247, 147, 26'
    case '14':
      return '129, 60, 223'
    case '15':
      return '3, 70, 201'
    case '16':
      return '188, 188, 188'
    case '17':
      return '25, 105, 255'
    case '18':
      return '236, 178, 68'
    case '19':
      return '39, 160, 239'
    case '20':
      return '76, 150, 65'
    case '21':
      return '247, 147, 26'
    case '22':
      return '255, 255, 255'
    case '23':
      return '232, 65, 66'
    case '24':
      return '255, 232, 79'
    case '25':
      return '0, 237, 254'
    case '26':
      return '112, 201, 187'
    case '27':
      return '233, 99, 33'
    case '28':
      return '230, 218, 254'
    case '29':
      return '254, 200, 1'
    case '30':
      return '242, 227, 204'
    case '31':
      return '122, 43, 249'
    default:
      return '84 184 255'
  }
}

/**
 * To convert a numeric value to standard precision
 * @param {number} amount amount to be converted to precision
 * @param {number} precision digits to be considered for precision
 * @returns {number}
 */
export const getPrecisionNumber = (amount, precision = DEFAULT_PRECISION) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(amount * precisionDivide) / precisionDivide || 0
  return result
}

/**
 * Durstenfeld array shuffling algorithm
 * @param {number} array
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const generateClientSeed = () => {
  const availableChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let clientSeed = ''
  for (let i = 0; i < 32; i++) {
    clientSeed +=
      availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return clientSeed
}

export const countOnes = (s) => {
  let count = 0
  for (const i of s) {
    if (i === '1') {
      count++
    }
  }
  return count
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const toUtcTime = (dateObject = moment(), dateFormat = 'DD/MM/YYYY') => {
  return moment.utc(dateObject).format(dateFormat)
}

export const formatPrice = (price, showCurrency = false, digits = 2) => {
  if (!price) return 0
  const numberTypeValue = typeof price === 'string' ? Number.parseFloat(price) : price

  const settings = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }
  if (showCurrency) {
    settings.style = 'currency'
    settings.currency = 'USD'
  }
  return numberTypeValue.toLocaleString('en-US', settings)
}

export const convertToMillions = (num) => {
  return (Number(num) / 1000000).toFixed(1) + 'M'
}
