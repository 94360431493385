import { TOKEN } from 'constants/index'

/* ==========================================================================
  Auth Token
========================================================================== */
export const getAuthTokenFromSession = () => {
  return JSON.parse(sessionStorage.getItem(TOKEN))
}

export const setAuthTokenInSession = (authAccessToken) => {
  sessionStorage.setItem(TOKEN, JSON.stringify(authAccessToken))
}

export const removeAuthTokenFromSession = () => {
  sessionStorage.removeItem(TOKEN)
}
/* ==========================================================================
  Signin Action
========================================================================== */
export const signIn = ({ token }) => {
  setAuthTokenInSession(token)
}

/* ==========================================================================
  Signout Action
========================================================================== */
export const signOut = () => {
  removeAuthTokenFromSession()
}

export const setItemToSession = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const getFromSession = (key) => {
  return JSON.parse(sessionStorage.getItem(key))
}
