import { app } from "./appPixi.js";
import { balls, pegs, boxes, uiCreated, bgDestroyed, gameAssets } from "./gameUI.js";

const bgAssets = (delta) => {
}

export const tickerFunction = () => {
  app.ticker.add((dt) => {
    if (!uiCreated) return

    boxes.forEach((box) => {
      box.sprite.BoxBounceEffect(dt);
    })

    balls.forEach((ball) => {
      ball.drop(dt);
    })

    if (!bgDestroyed) bgAssets(dt)
  })
}
export function distance (currentPos, targetPos) {
  let distance = Math.pow((targetPos.x - currentPos.x), 2) + Math.pow((targetPos.y - currentPos.y), 2)
  distance = Math.sqrt(distance)
  return distance;
}
