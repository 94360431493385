import React from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import { startCase } from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import defaultMentionStyle from './defaultMentionStyle'
import defaultInputStyle from './defaultInputStyle'
import { MAX_MESSAGE_CHARACTERS_LIMIT } from 'constants/index'

const Mentions = ({ setMessage, message, mentionsData }) => {
  const { t } = useTranslation()
  const { loginData } = useSelector((state) => state.auth)

  const handleChange = (e) => {
    setMessage(prevMsg => e.target.value?.length > MAX_MESSAGE_CHARACTERS_LIMIT ? prevMsg : e.target.value) // not able to type beyond the mx limit
    // setMessage(prevMsg => e.target.value) // we can type more than max_msg_limit but cannot send the message
  }

  const handleDisplayTransform = (id, display) => {
    return `@{${display}}`
  }

  return (
    <MentionsInput
      style={defaultInputStyle}
      value={message}
      onChange={handleChange}
      // singleLine
      forceSuggestionsAboveCursor
      placeholder={
        loginData?.accessToken ? `${startCase(t('saySomething'))}...` : t('loginToChat')
      }
      disabled={!loginData?.accessToken}
    >
      <Mention
        trigger='@'
        style={defaultMentionStyle}
        data={mentionsData}
        displayTransform={handleDisplayTransform}
        appendSpaceOnAdd
        markup='@{__display__}^*+@!#+*^__id__^*+@!#+*^'
      />
    </MentionsInput>
  )
}

export default React.memo(Mentions)
