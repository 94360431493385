import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Tooltip from 'rc-tooltip'
import {
  ChatBubbleIcon,
  ChatCloseIcon,
  CloseGrayIcon, BellSolidIcon
} from 'components/ui-kit/Icons/svg/index'
import { useTranslation } from 'react-i18next'
import {
  setChatCollapse,
  setShowChat,
  setShowNotification
} from 'redux-thunk/redux/slices/settings.slice'
import { getChatGroups } from 'redux-thunk/thunk/chat.thunk'
import { setSelectedChatGroupId } from 'redux-thunk/redux/slices/chat.slice'

const RightSidebarHeader = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isChatCollapse, showChat, showNotification } = useSelector((state) => state.settings)
  const { chatGroups, selectedChatGroupId } = useSelector(
    (state) => state.chat
  )
  const { loginData } = useSelector((state) => state.auth)

  const handleOpenNotifications = useCallback(() => {
    dispatch(setShowChat(false))
    dispatch(setShowNotification(true))
  }, [])

  const handleCloseChat = useCallback(() => {
    dispatch(setShowChat(false))
    dispatch(setShowNotification(false))
    dispatch(setChatCollapse(true))
  }, [])

  const handleClickChat = useCallback(() => {
    dispatch(setShowChat(true))
    dispatch(setShowNotification(false))
  }, [])

  useEffect(() => {
    dispatch(getChatGroups())
  }, [])

  const selectedChatGroupName = chatGroups?.rows?.find(
    (ele) => ele.id.toString() === selectedChatGroupId.toString()
  )?.name

  return (
    <div className='chat-header justify-content-between'>
      <div className='header-buttons-wrap d-flex'>
        <div className='dropdown language-btn'>
          <button
            className={`btn btn-primary dropdown-toggle shadow-none ${showChat ? 'active' : ''}`}
            type='button'
            id='group-dropdown'
            data-bs-toggle='dropdown'
            aria-expanded='false'
            onClick={handleClickChat}
          >
            <ChatBubbleIcon />
            {selectedChatGroupName}
          </button>
          <ul className='dropdown-menu chat-group' aria-labelledby='group-dropdown'>
            {chatGroups?.rows?.length > 0 &&
              chatGroups?.rows?.map((group) => (
                <li key={group?.id}>
                  <NavLink
                    activeClassName='dropdown-item'
                    to='#'
                    onClick={() => {
                      dispatch(setSelectedChatGroupId(group?.id))
                    }}
                  >
                    <img src={group?.groupLogo} width={17} height={17} alt={group?.name} />
                    {group?.name}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
        {loginData?.accessToken && (
          <button
            className={`btn btn-primary shadow-none  ${showNotification ? 'active' : ''}`}
            type='button'
            onClick={handleOpenNotifications}
          >
            <BellSolidIcon />
            {t('notifications')}
          </button>
        )}
      </div>
      <div className='d-flex chat-close-wrap'>
        <Tooltip
          placement='left'
          trigger={['hover']}
          overlayStyle={{ fontSize: '0.7rem' }}
          overlay={<span>{isChatCollapse ? t('expand') : t('collapse')}</span>}
        >
          <NavLink
            to='#'
            activeClassName='chat-collapse-icon d-none'
            onClick={() => dispatch(setChatCollapse(!isChatCollapse))}
          >
            <ChatCloseIcon />
          </NavLink>
        </Tooltip>
        <Tooltip
          placement='left'
          trigger={['hover']}
          overlayStyle={{ fontSize: '0.7rem' }}
          overlay={<span>{t('close')}</span>}
          showArrow
        >
          <NavLink
            to='#'
            activeClassName='chat-close'
            onClick={handleCloseChat}
          >
            <CloseGrayIcon />
          </NavLink>
        </Tooltip>
      </div>
    </div>
  )
}

export default React.memo(RightSidebarHeader)
