import React from 'react'
import './index.scss'

const CasinoCard = (props) => {
  const { image, title, desc, onClick } = props

  return (
    <div
      className='cashCard'
      onClick={onClick}
    >
      <div className='cashCard-icon'>
        <img width={32} height={32} src={image} alt='Coin' />
      </div>
      <h4>{title}</h4>
      <p>{desc}</p>
    </div>
  )
}

export default React.memo(CasinoCard)
