import { Howler } from 'howler'

const BGMSound = new Howl({
  src: ["/assets/pixi/plinko-game/audios/BGM.mp3"],
  loop: true
})

const leftDropSound = new Howl({
  src: ["/assets/pixi/plinko-game/audios/Left-Drop.wav"],
  loop: false
})

const rightDropSound = new Howl({
  src: ["/assets/pixi/plinko-game/audios/Right-Drop.wav"],
  loop: false
})

const orangeBoxSound = new Howl({
  src: ["/assets/pixi/plinko-game/audios/Orange-Box.wav"],
  loop: false
})

const redBoxSound = new Howl({
  src: ["/assets/pixi/plinko-game/audios/Red-Box.wav"],
  loop: false
})

const yellowBoxSound = new Howl({
  src: ["/assets/pixi/plinko-game/audios/Yellow-Box.wav"],
  loop: false
})

export function playBGMSound () {
  BGMSound?.play();
}
export function stopBGMSound () {
  BGMSound?.pause();
}
export function playRightDropSound () {
  rightDropSound?.play();
}
export function playLeftDropSound () {
  leftDropSound?.play();
}
export function playYellowBoxSound () {
  yellowBoxSound?.play();
}
export function playOrangeBoxSound () {
  orangeBoxSound?.play();
}
export function playRedBoxSound () {
  redBoxSound?.play();
}
