import { createAsyncThunk } from '@reduxjs/toolkit'
import { cashOutBetHiLoService, myBetsHiLoService, getUnfinishedBetService, openCardHiLoService, placeBetHiLoService } from 'network/services/hiloGame.service'
import { BET_RESULT } from 'constants/index'
import { setServerSeedHash } from 'redux-thunk/redux/slices/gameSetting.slice'
import { openErrorToaster, openSuccessToaster } from 'helpers/toaster.helpers'
import { getPrecision } from 'utils/helperFunctions.utils'
import { getTranslation } from 'helpers/translations.helpers'

export const placeBetHiLo = createAsyncThunk('hi-lo-game/place-bet', async ({ payload }, thunkApi) => {
  try {
    const res = await placeBetHiLoService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const openCardHiLo = createAsyncThunk('hi-lo-game/open-card', async ({ data, setBetAmount, minBetData }, thunkApi) => {
  try {
    const res = await openCardHiLoService(data)
    const card = document.querySelector('.card-center')
    card.classList.toggle('flipped')
    if (res?.result === BET_RESULT.LOST) {
      openErrorToaster({ message: getTranslation('youLost') })
      thunkApi.dispatch(setServerSeedHash(res?.serverSeed))
      // setBetAmount(Number(minBetData))
    }
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBetsHiLo = createAsyncThunk('hi-lo-game/my-bets', async (data, thunkApi) => {
  try {
    const res = await myBetsHiLoService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const cashOutHiLo = createAsyncThunk('hi-lo-game/cash-out-bet', async ({ data }, thunkApi) => {
  try {
    const res = await cashOutBetHiLoService(data)
    thunkApi.dispatch(setServerSeedHash(res?.serverSeed))
    openSuccessToaster({ message: `Cashed out $${(getPrecision(res?.winningAmount, 2))?.toFixed(2)} successfully` })
    return res
  } catch (error) {
    openErrorToaster({ message: getTranslation('cashOutFailed') })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getUnfinishedBet = createAsyncThunk('hi-lo-game/get-unfinished-bet', async (_, thunkApi) => {
  try {
    const res = await getUnfinishedBetService()
    if (res?.hasUnfinishedBet) {
      openSuccessToaster({ message: getTranslation('betAlreadyPlaced') })
    }
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
