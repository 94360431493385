const getFractionDigits = (value) => {
  if (value >= 1) return 2
  if (value >= 0.01) return 4
  return 8
}

export const formatCryptoPrice = (value) => {
  if (!value) return 0
  const numberTypeValue = typeof value === 'string' ? Number.parseFloat(value) : value
  const fractionDigits = getFractionDigits(numberTypeValue)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

  return formatter.format(numberTypeValue)
}
