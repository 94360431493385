import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import { emailVerifyImage } from 'components/ui-kit/Icons/png/index'
import './verifyEmail.scss'

const VerifyEmailPopup = ({ handleClose }) => {
  const { t } = useTranslation()

  return (
    <CommonPopup
      modalCloseHandler={() => {
        handleClose(false)
      }}
      classes='verify-email'
    >
      <div className='d-flex justify-content-center flex-column text-center'>
        <div className='popup-bg d-flex align-items-center justify-content-center'>
          <img src={emailVerifyImage} />
        </div>
        <div className='popup-content'>
          <h2 className='text-uppercase'>{t('youNearlyThere')}</h2>
          <p>{t('weSendYouVerificationEmail')}</p>
          <button
            className='btn btn-secondary'
            onClick={() => handleClose(false)}
          >
            {t('verifyEmailAddress')}
          </button>
          <p className='mt-3 mb-0 popup-note'>
            {t('pleaseNoteThatTheLinkWillExpire')}
          </p>
        </div>
      </div>
    </CommonPopup>
  )
}

export default VerifyEmailPopup
