import React from 'react'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import './index.scss'

const MainLoader = (props) => {
  const { icon, lottie, onClick, active } = props
  const [dotLottie, setDotLottie] = React.useState(null)
  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie)
  }

  const handlePlay = () => {
    if (dotLottie && !active) {
      dotLottie.setFrame(0)
      dotLottie.play()
    }
  }

  return (
    <button className={`btn btn-primary lottieIconButton ${active ? 'active' : ''} `} onClick={onClick} onMouseEnter={handlePlay}>
      {icon}

      <DotLottieReact
        src={lottie}
        dotLottieRefCallback={dotLottieRefCallback}
        style={{ width: '24px', height: '24px' }}
      />
    </button>
  )
}

export default MainLoader
