export default {
  control: {
    backgroundColor: 'var(--darkGray)',
    width: '100%',
    fontWeight: 'normal'
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 40
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent'
    },
    input: {
      padding: 9,
      border: '1px solid silver'
    }
  },

  '&singleLine': {
    display: 'inline-block',
    width: '100%',

    highlighter: {
      padding: 1,
      border: '2px inset transparent'
    },
    textarea: {

      // backgroundColor: 'var(--darkGray)',
      // border: '2px solid var(--gray-80)',
      // color: 'var(--white)',
      // padding: '0.375rem 0.75rem',
      // borderRadius: '0.625rem',
      // height: '2.5rem',
      // width: '100%',
      // fontSize: '0.875rem',
      // '&:focused': {
      //   color: 'var(--white)',
      //   border: '2px solid var(--secondaryBtnBorder)'
      // }
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'var(--gray-80)',
      border: 'none',
      fontSize: 14,
      maxHeight: '700px',
      overflowY: 'auto',
      borderRadius: '0.313rem'

    },
    item: {
      padding: '5px 15px',
      minWidth: '120px',
      borderBottom: 'none',
      color: 'var(--gray-20)',
      transition: 'all 200ms ease-in-out',
      fontWeight: '700',
      '&focused': {
        backgroundColor: 'var(--darkGray)',
        color: 'var(--white)'
      }
    }
  }
}
