import React from 'react'
import Tooltip from 'rc-tooltip'
import { useTranslation } from 'react-i18next'
import { FavouritesIcon } from 'components/ui-kit/Icons/svg/index'

const FavouriteIcon = ({ fill, searchPopup }) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      placement='bottom'
      trigger={['hover']}
      overlayStyle={{ fontSize: '0.7rem', zIndex: searchPopup ? '9999' : '', outline: 'none' }}
      showArrow={false}
      overlay={
        <span>{fill === 'red' ? t('removeFromFavourite') : t('addToFavourite')}</span>
}
    >
      <FavouritesIcon
        fill={fill}
        style={{ stroke: fill === 'red' ? 'red' : '#8A90A2' }}
      />
    </Tooltip>
  )
}

export default React.memo(FavouriteIcon)
