import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getCasinoProvidersService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/providers', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getGamesListService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/games', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.categoryWiseGamesList
  })
}

export const getFavouriteGamesListService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/get-favourite-game', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.gameBarFavourites
  })
}

export const getCasinoAllCategoriesService = () => {
  return axiosInstance(METHOD_TYPES.get, '/casino/category', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  }
  )
}

export const getUsersCasinoTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/casino-transaction', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const addFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/casino/add-favourite-game', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const removeFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/casino/remove-favourite-game', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getAllFavouriteGamesService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/get-favourite-game', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getCustomGameTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/system/custom-game-transactions', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getCasinoTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/casino-transaction', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getSportsBookTransactionsService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/sportsbook/transactions', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getAllCustomFavouriteGamesService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/common-game/custom-favourite', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const getEvolutionGameIframeUrlService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/evolution/game-launch', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getBetByTokenService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/betby/get-token', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}
