import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import config from 'config/app.config'
import NAMESPACES from 'socket-resources/namespaces/index'
import { BONUS_WAGERING } from 'socket-resources/event-constants/totalBonusAmounts/index'

const useWageringSocketNameSpace = () => {
  const { loginData } = useSelector(state => state.auth) // Fetch the latest token

  const listenWageringUpdateEvent = (cb) => {
    const wageringSocketEvent = io(`${config.SOCKET_URL}${NAMESPACES.WAGERING}`, {
      auth: {
        authorization: `Bearer ${loginData?.accessToken}`
      }
    })

    wageringSocketEvent.on('connect', () => {
      // console.log('Connected to WAGERING namespace with new token')
    })

    wageringSocketEvent.on('disconnect', () => {
      // console.log('Socket disconnected from WAGERING namespace')
    })
    if (cb) {
      wageringSocketEvent.on(BONUS_WAGERING, cb)
    }
    return () => {
      wageringSocketEvent?.off(BONUS_WAGERING, cb)
      wageringSocketEvent?.disconnect()
    }
  }

  return { listenWageringUpdateEvent }
}

export default useWageringSocketNameSpace
