import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Platform = () => {
  const { t } = useTranslation()

  return (
    <ul className='m-0'>
      <li>
        <NavLink to='#'>{t('support')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('faq')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('partnershipProgram')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('blog')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('helpCenter')}</NavLink>
      </li>
    </ul>
  )
}

export default React.memo(Platform)
