import React from 'react'
import { NavLink } from 'react-router-dom'
import { S3_IMAGES_URL } from 'constants/index'
import { useTranslation } from 'react-i18next'

const DepositBuyCrypto = (props) => {
  const { t } = useTranslation()
  const { currencyName, buttonType, showMoonPayWidget } = props

  return (
    <div className='modal-inner-card mt-1 d-md-flex align-items-center justify-content-between mt-1'>
      <div className='payment-left-card d-flex align-items-center'>
        <h4>
          {t('doNotHaveAnyCurrency', {
            currency: currencyName
          })}
        </h4>

      </div>
      <div className='payment-card-center'>
        <NavLink to='#'>
          <img
            src={`${S3_IMAGES_URL.IMAGE_URL}/assets/images/homePage/samsung-pay.webp`}
            alt='Samsung Pay'
          />
        </NavLink>
        <NavLink to='#'>
          <img src={`${S3_IMAGES_URL.IMAGE_URL}/images/visa.webp`} alt='Visa' />
        </NavLink>
        <NavLink to='#'>
          <img
            src={`${S3_IMAGES_URL.IMAGE_URL}/assets/images/homePage/master-card.webp`}
            alt='Master Card'
          />
        </NavLink>
        <NavLink to='#'>
          <img src={`${S3_IMAGES_URL.IMAGE_URL}/assets/images/homePage/i-pay.webp`} alt='I pay' />
        </NavLink>
        <NavLink to='#'>
          <img src={`${S3_IMAGES_URL.IMAGE_URL}/assets/images/homePage/g-pay.webp`} alt='Google pay' />
        </NavLink>
      </div>
      <div className='payment-card-right'>
        <button
          type='button'
          className={`btn btn-${buttonType} text-uppercase`}// btn-info
          onClick={showMoonPayWidget}
        >
          {t('buyCrypto')}
        </button>
      </div>
    </div>
  )
}

export default React.memo(DepositBuyCrypto)
