import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowDownRedIcon, GreenArrowUpIcon, BtcLiveIcon, SearchIconIcon } from 'components/ui-kit/Icons/svg/index' // GreenArrowUpIcon
import useCryptoFutureNameSpace from 'socket-resources/hooks/useCryptoFutureNameSpace'
import { setShowSearchPopup } from 'redux-thunk/redux/slices/settings.slice'
import { formatCryptoPrice } from 'utils/numberformatter'

const LiveValues = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { cryptoCoinsList } = useSelector(state => state.crypto)
  const [liveBitcoinValue, setLiveBitcoinValue] = useState({ currentPrice: 0, basePrice: 0 })
  const [btcId, setBtcId] = useState('1')
  const { listenCryptoFuturePriceEvent } = useCryptoFutureNameSpace()

  const handleCryptoPriceUpdate = (data) => {
    if (data?.data?.ticker?.cryptoFuturesInstrumentId === btcId) {
      setLiveBitcoinValue({
        currentPrice: data?.data?.ticker?.price || 0,
        basePrice: data?.data?.basePrice || 0
      })
    }
  }

  const calculatePercentageChange = useCallback((higherValue, lowerValue) => {
    const percentageChange = (((higherValue - lowerValue) * 100) / higherValue)?.toFixed(2)
    return isNaN(percentageChange) ? '0.00' : percentageChange
  }, [])

  useEffect(() => {
    if (cryptoCoinsList) {
      const [bitcoinObj] = cryptoCoinsList?.filter(el => el?.symbol === 'BTC')
      setBtcId(bitcoinObj?.id)
    }
  }, [cryptoCoinsList])

  useEffect(() => {
    const cleanerCryptoEvent = listenCryptoFuturePriceEvent(handleCryptoPriceUpdate)
    return () => {
      cleanerCryptoEvent()
    }
  }, [])

  return (
    <div className='sidebar-balance-wrap d-flex flex-column gap-3'>
      <div className='d-flex order-md-0 order-1'>
        <div className='currency-icon'>
          <BtcLiveIcon />
        </div>
        <div className='currency-icon-value'>
          <h4 className='m-0'>
            {t('bitcoin')} <span>{t('btc')}</span>
          </h4>
          <p>
            ${formatCryptoPrice(liveBitcoinValue.currentPrice)}

            {liveBitcoinValue?.currentPrice < liveBitcoinValue?.basePrice
              ? (
                <span className='text-red d-flex justify-content-center align-items-center'>
                  <ArrowDownRedIcon width='18' height='18' className='mx-1' />
                  -{calculatePercentageChange(liveBitcoinValue?.basePrice, liveBitcoinValue?.currentPrice)}%
                  {/* -{(((liveBitcoinValue?.basePrice - liveBitcoinValue?.currentPrice) * 100) / (liveBitcoinValue?.basePrice)).toFixed(7)}% */}
                </span>)
              : (
                <span className='text-green d-flex justify-content-center align-items-center'>
                  <GreenArrowUpIcon width='18' height='18' className='mx-1' />
                  +{calculatePercentageChange(liveBitcoinValue?.currentPrice, liveBitcoinValue?.basePrice)}%
                  {/* +{(((liveBitcoinValue?.currentPrice - liveBitcoinValue?.basePrice) * 100) / (liveBitcoinValue?.currentPrice)).toFixed(7)}% */}
                </span>)}
          </p>
        </div>
      </div>
      <div className='search-games w-100 position-relative'>
        <input
          type='text'
          className='form-control form-control-dark'
          placeholder={t('searchGames')}
          onFocus={() => {
            dispatch(setShowSearchPopup(true))
          }}
        />
        <SearchIconIcon className='position-absolute' />
      </div>
    </div>
  )
}

export default LiveValues
