import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setShowTipPopup } from 'redux-thunk/redux/slices/settings.slice'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorMessage from 'components/layout/ErrorMessage'
import { tipUser } from 'redux-thunk/thunk/chat.thunk'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import Loader from 'components/ui-kit/Loader/index'
import { getPrecision } from 'utils/helperFunctions.utils'
import { setShowTipInChat } from 'redux-thunk/redux/slices/chat.slice'
import CommonPopup from 'components/ui-kit/CommonPopup/index'

const TippingPopup = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { userData, showTipInChat } = useSelector((state) => state.chat)
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  )
  const { selectedWallet } = useSelector((state) => state.user)

  const modalCloseHandler = () => {
    dispatch(setShowTipPopup(false))
    const element = document.querySelector('.user-profile-modal')
    element?.classList?.remove('remove-blur')
  }

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t('amountIsRequired'))
      .required(`${t('amountIsRequired')}`)
      .moreThan(1, t('minAmount', { min: 1 }))
      .lessThan(
        Math.min(selectedWallet?.amount, 100),
        t('maxAmount', { max: getPrecision(Math.min(selectedWallet?.amount, 100), 2) })
      )
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const handleTipSubmit = async (values) => {
    modalCloseHandler()
    dispatch(tipUser({ ...values, receiverName: userData?.name, receiverId: userData?.id }))
  }

  const handleChangeCheckboxInput = (e) => {
    dispatch(setShowTipInChat(!e.target.checked))
  }

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      classes='chat-modal tip-modal'
      id-='tip-modal'
      modalBodyClasses='p-0'
    >
      <div className=''>
        <h5>
          {t('tip')} {userData?.name}
        </h5>
      </div>
      <div className='tip-modal-body'>
        <form onSubmit={handleSubmit(handleTipSubmit)}>
          <div className='mb-2 form-group'>
            <div className='mb-2'>
              <label
                htmlFor='amount'
                className='form-label text-uppercase'
              >
                {t('topHeaderAmount')}
              </label>{' '}
              <span className='color-red'>*</span>
              <input
                type='number'
                step='any'
                placeholder={t('topHeaderAmount')}
                className={`form-control form-control-dark shadow-none ${errors?.amount ? 'error-input' : ''}`}
                id='amount'
                name='amount'
                {...register('amount')}
              />
              {errors && errors.amount && (
                <ErrorMessage
                  className='color-red text-danger error-msg login-input mt-2 d-flex'
                  message={errors.amount.message}
                />
              )}
            </div>
            <div className='form-check my-3'>
              <input
                className='form-check-input cursor-pointer'
                type='checkbox'
                value={!showTipInChat}
                id='flexCheckChecked'
                onChange={handleChangeCheckboxInput}
              />
              <label
                className='form-check-label'
                htmlFor='flexCheckChecked'
              >
                {t('doNotShowTip')}
              </label>
            </div>
          </div>
          <div className=''>
            <button
              type='button'
              className='btn btn-primary me-2'
              onClick={modalCloseHandler}
            >
              <span>{t('cancel')}</span>
            </button>
            <button
              type='submit'
              className='btn btn-secondary'
              disabled={loading}
            >
              {loading
                ? (
                  <Loader variant={LOADER_HANDLER_TYPES.submit} />
                  )
                : (
                  <span>{t('sendTip')}</span>
                  )}
            </button>
          </div>
        </form>
      </div>
    </CommonPopup>
  )
}

export default React.memo(TippingPopup)
