import React from 'react'
import './index.scss'
import { HeadingStarIcon } from '../Icons/svg'
const SubpageHeader = ({ title, desc, img, mobileImg, variant, children }) => {
  return (
    <div className={`subpageHeader ${variant ? `subpageHeader-${variant}` : ''}`}>
      <picture>
        <source media='(max-width: 767px)' srcSet={mobileImg} />
        <source media='(min-width: 768px)' srcSet={img} />
        <img className='subpageHeader-image' src={img} alt='' />
      </picture>
      <div className='subpageHeader-content'>
        {title && <h1>{title}<HeadingStarIcon /></h1>}
        {desc && <p>{desc}</p>}
        {children}
      </div>
    </div>
  )
}

export default React.memo(SubpageHeader)
