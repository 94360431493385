import { DEFAULT_GAME_ID, LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllCurrencyService = () => {
  return axiosInstance(METHOD_TYPES.get, 'system/get-all-currency', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getGameSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-game-settings', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getAllSiteinfoService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-all-cms', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getGameThemeSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-all-game-theme-settings', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getEncourageWordsSettingService = () => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/list-uplifting-words', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const setUpdateAffilateCountService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/system/increase-affiliateCount', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getBannerService = () => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-banners', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getSponserService = () => {
  return axiosInstance(METHOD_TYPES.get, '/sponsor/list', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const verifyRoundHashService = (data) => {
  let endPoint = ''
  let payLoadData = {}
  switch (data.gameId) {
    case DEFAULT_GAME_ID.CRASH:
      endPoint = 'crash-game/check-provable-fair'
      payLoadData = {
        roundHash: data.hashCode
      }
      break

    case DEFAULT_GAME_ID.DICE:
      endPoint = 'dice-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed
      }
      break

    case DEFAULT_GAME_ID.SPACE_DICE:
      endPoint = 'space-dice-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed
      }
      break

    case DEFAULT_GAME_ID.BLACKJACK:
      endPoint = 'blackjack-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed
      }
      break

    case DEFAULT_GAME_ID.FLIP_COIN:
      endPoint = 'flip-coin-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed,
        numberOfCoins: data?.numberOfCoins
      }
      break

    case DEFAULT_GAME_ID.HILO:
      endPoint = 'hi-lo-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed
      }
      break

    case DEFAULT_GAME_ID.KENO:
      endPoint = 'keno-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed
      }
      break

    case DEFAULT_GAME_ID.LIMBO:
      endPoint = 'limbo-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed
      }
      break

    case DEFAULT_GAME_ID.VIDEO_POKER:
      endPoint = 'video-poker-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed
      }
      break

    case DEFAULT_GAME_ID.ROULETTE:
      endPoint = 'roulette-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed
      }
      break

    case DEFAULT_GAME_ID.PLINKO:
      endPoint = 'plinko-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed,
        numberOfRows: data?.numberOfRows,
        riskLevel: data?.riskLevel
      }
      break

    case DEFAULT_GAME_ID.MINE:
      endPoint = 'mine-game/check-fairness'
      payLoadData = {
        clientSeed: data?.clientSeed,
        serverSeed: data?.serverSeed,
        mineCount: data?.numberOfMines
      }
      break

    default:
      break
  }
  return axiosInstance(METHOD_TYPES.post, endPoint, payLoadData, {
    server: microServices.SERVICE_URL_1
    // successMessage: SuccessMessage.cancelBetSuccessfully
  })
}
