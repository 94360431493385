import { createSlice } from '@reduxjs/toolkit'
import { getAnnouncements } from 'redux-thunk/thunk/announcement.thunk'

const initialState = {
  announcements: null,
  newAnnouncements: false
}

const {
  actions: {
    setAnnouncements,
    updateAnnouncement,
    setNewAnnouncement,
    resetAnnouncements
  },
  reducer
} = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    resetAnnouncements: (state, action) => ({
      ...state,
      announcements: {
        ...state?.announcements,
        rows: state?.announcements?.rows?.map(element => {
          return {
            ...element,
            seen: false
          }
        })
      }
    }),
    setNewAnnouncement: (state, action) => ({
      ...state,
      newAnnouncements: action.payload
    }),
    setAnnouncements: (state, action) => {
      return {
        ...state,
        announcements: {
          count: state.announcements.rows.length + 1,
          rows: [action.payload, ...state.announcements.rows?.filter(el => el.id !== action.payload.id)]
        }
      }
    },
    updateAnnouncement: (state, action) => {
      const { id } = action.payload
      return {
        ...state,
        announcements: {
          ...state.announcements,
          rows: state.announcements.rows.map(announcement => {
            if (announcement.id === id) {
              return { ...announcement, seen: true }
            }
            return announcement
          })
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnnouncements.fulfilled, (state, action) => {
        return {
          ...state,
          announcements: { ...action.payload, rows: action.payload.rows }
        }
      })
  }
})

export default reducer
export {
  setAnnouncements,
  updateAnnouncement,
  setNewAnnouncement,
  resetAnnouncements
}
