import React from 'react'
import './index.scss'
import { NavLink, useHistory } from 'react-router-dom'
import { usePreLogin } from 'hooks/usePreLogin'

const CasinoCard = (props) => {
  const { variant, title, textSize, text, subText, to, linkText, image, imageSize, isTextNewLine, cardClassName, isTextGradient, redirectPath, onClick } = props
  const history = useHistory()
  const { handleRegister } = usePreLogin()

  const handleClick = () => {
    if (redirectPath) {
      handleRegister(true)
    } else if (to) {
      history.push(to)
    }
  }

  return (
    <div className={`casino-card ${to ? 'casino-card-pointer' : ''} ${isTextNewLine ? 'casino-card-newLine' : ''} ${cardClassName ?? ''} casino-card--${variant || ''}`} onClick={onClick || handleClick}>
      <div className='casino-card-inner d-flex justify-content-between h-full'>
        <div className='casino-card-left'>
          {title
            ? (
              <div className='casino-card-badge'>{title}</div>
              )
            : null}
          <h3
            className={`casino-card-title ${isTextGradient ? 'casino-card-title-gradient' : ''} ${textSize ? `casino-card-title-${textSize}` : ''}`}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {!isTextNewLine
            ? (
              <>
                <p dangerouslySetInnerHTML={{ __html: subText }} />
                {to
                  ? (
                    <NavLink to={to} className='casino-card-link'>
                      {linkText}
                    </NavLink>
                    )
                  : linkText ? (<div className='casino-card-comming'>{linkText}</div>) : undefined}
              </>
              )
            : null}
        </div>
        <div className='casino-card-right'>
          <img
            className={`casino-card-img ${isTextNewLine ? 'casino-card-img-newLine' : ''} ${imageSize ? `casino-card-img-${imageSize}` : ''}`}
            src={image}
            alt={title}
          />
        </div>
      </div>
      {isTextNewLine
        ? (
          <div className='casino-card-footer'>
            <p>{subText}</p>
            {to
              ? (
                <NavLink to={to} className='casino-card-link'>
                  {linkText}
                </NavLink>
                )
              : linkText ? (<div className='casino-card-comming'>{linkText}</div>) : undefined}
          </div>
          )
        : null}
    </div>
  )
}

export default React.memo(CasinoCard)
