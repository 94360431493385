import React from 'react'
import { useDispatch } from 'react-redux'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import { setShowCryptoAcknowledgementPopup } from 'redux-thunk/redux/slices/settings.slice'
import { BrandLogoIcon } from 'components/ui-kit/Icons/svg/index'
import './Acknowledgement.scss'

const AcknowledgementPopup = () => {
  const dispatch = useDispatch()

  return (
    <CommonPopup
      modalContentClass='acknowledgement'
      modalBodyClasses='acknowledgement-body'
      modalDialogClass='acknowledgement-dialog'
      modalCloseHandler={() => {}}
      id='acknowledgement-popup'
      hideCloseButton
    >
      <div>
        <div className='mb-4'>
          <BrandLogoIcon />
        </div>
        <div>
          <p className='ack-text mb-4'>
            Crypto Trading is not an activity covered by our Gaming License and is offered by Clutch independently.
          </p>
          <button
            className='btn btn-secondary ack-btn'
            onClick={() => dispatch(setShowCryptoAcknowledgementPopup(false))}
          >
            I Acknowledge
          </button>
        </div>
      </div>
    </CommonPopup>
  )
}

export default AcknowledgementPopup
