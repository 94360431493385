import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { openErrorToaster } from 'helpers/toaster.helpers'
import { createWithdrawRequest } from 'redux-thunk/thunk/user.thunk'
import ErrorMessage from 'components/layout/ErrorMessage'
import { LeftArrowIcon } from 'components/ui-kit/Icons/svg/index'
import { goldICon } from 'components/ui-kit/Icons/png/index'
import { blockInvalidChar, cryptoToFiat } from 'utils/helperFunctions.utils'
import { CRYPTO_COINS, LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index'
import { setShowCashierPopup } from 'redux-thunk/redux/slices/settings.slice'
import Loader from 'components/ui-kit/Loader/index'

const WithdrawCurrencySection = ({ selectedWithdrawWallet, setSelectedWithdrawWallet }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedWallet } = useSelector(state => state.user)
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const [fiatAmount, setFiatAmount] = useState(0)
  const [fiatFocused, setFiatFocused] = useState(false)

  const PRIMARY_FIAT = 'USD'
  const MIN_WITHDRAW_AMOUNT = 0.00001

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t('amountIsRequired'))
      .required(`${t('amountIsRequired')}`)
      .moreThan(
        MIN_WITHDRAW_AMOUNT,
        t('minWithdrawAmount', { min: MIN_WITHDRAW_AMOUNT })
      ),
    address: yup
      .string()
      .required(t('addressIsRequired'))
      .test('not-equal', t('withdrawAddressCannotBeSame'), (value) => {
        return value?.trim() !== selectedWithdrawWallet?.walletAddress
      })
  })

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    // getValues,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })
  const watchCryptoAmount = watch('amount', 0)

  const handleChangeFiat = (e) => {
    setFiatAmount(e.target.value)
    cryptoToFiat(PRIMARY_FIAT, CRYPTO_COINS[selectedWithdrawWallet?.currency?.code], e.target.value)
      .then((res) => {
        setValue('amount', !res ? 0 : res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleWithdrawRequest = async (values) => {
    if (selectedWallet?.amount >= fiatAmount) {
      if (parseFloat(values.amount) >= MIN_WITHDRAW_AMOUNT) {
        try {
          const params = {
            withdrawalAmount: parseFloat(values.amount),
            walletId: parseInt(selectedWithdrawWallet.id),
            withdrawalAddress: values.address
          }
          dispatch(createWithdrawRequest({ params }))
        } catch (e) {
          openErrorToaster({ message: e.message })
        }
      } else {
        openErrorToaster({
          message: t('withdrawMinAmount', { min: MIN_WITHDRAW_AMOUNT })
        })
      }
    } else {
      openErrorToaster({ message: t('withdrawNoBalance') })
    }
  }

  useEffect(() => {
    if (!fiatFocused) {
      cryptoToFiat(
        CRYPTO_COINS[selectedWithdrawWallet?.currency?.code],
        PRIMARY_FIAT,
        watchCryptoAmount
      )
        .then((res) => {
          setFiatAmount(!res ? 0 : res)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [watchCryptoAmount])

  return (
    <div className='deposit-step-two'>
      <div className='cashier-heading'>
        <h3 className='mb-0 text-uppercase'>
          <NavLink
            to='#'
            className='left-arrow'
            onClick={() => {
              setSelectedWithdrawWallet(null)
            }}
          >
            <LeftArrowIcon />
          </NavLink>
          <div className='currency-icon'>
            <img src={selectedWithdrawWallet.currency.image} alt='Bitcoin' />
          </div>
          {t('withdrawCurrency', { currency: selectedWithdrawWallet?.currency?.name })}
        </h3>
        <NavLink
          to={ROUTE_PATHS.WITHDRAWALS}
          onClick={() => {
            dispatch(setShowCashierPopup(false))
          }}
        >
          {t('viewTransactions')}
        </NavLink>
      </div>
      <form onSubmit={handleSubmit(handleWithdrawRequest)}>
        <div className='modal-inner-card mb-2'>
          <div className='d-flex'>
            <p>
              {t('enterAddressToReceiveFunds', {
                currency: selectedWithdrawWallet?.currency?.name
              })}
            </p>
          </div>
          <div className='change-username'>
            <div className='form-group w-100 mt-4'>
              <label htmlFor='newUser' className='form-label'>
                {t('receivingCurrencyAddress', {
                  currency: selectedWithdrawWallet?.currency?.name
                })}
                <span className='color-red'>*</span>
              </label>
              <input
                type='text'
                className={`form-control shadow-none ${errors?.address ? 'error-input' : ''}`}
                name='address'
                id='address'
                {...register('address')}
                placeholder={t('pasteYourCurrencyAddressHere', {
                  currency: selectedWithdrawWallet?.currency?.name
                })}
              />
              {errors && errors.address && (
                <ErrorMessage
                  className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                  message={errors.address.message}
                />
              )}
            </div>
          </div>
        </div>
        <div className='modal-inner-card mb-2'>
          <div className='currency-converter'>
            <label htmlFor='newUser' className='form-label'>
              {t('withdrawalAmount')}
              <span className='color-red p-0'>*</span>
            </label>

            <div className='d-md-flex align-items-center justify-content-center '>
              <div className='form-group w-100'>
                <input
                  type='number'
                  className='form-control shadow-none'
                  id='newUser'
                  value={fiatAmount}
                  placeholder={t('topHeaderAmount')}
                  onChange={handleChangeFiat}
                  onKeyDown={blockInvalidChar}
                  onFocus={(e) => {
                    setFiatFocused(true)
                  }}
                  onBlur={(e) => {
                    setFiatFocused(false)
                  }}
                />
                <img src={goldICon} alt='Coin' className='me-2' />
              </div>
              <span>=</span>
              <div className='form-group w-100'>
                <input
                  type='number'
                  step='any'
                  className={`form-control shadow-none ${errors?.amount ? 'error-input' : ''}`}
                  id='amount'
                  placeholder={t('topHeaderAmount')}
                  name='amount'
                  onKeyDown={blockInvalidChar}
                  {...register('amount')}
                />
                <img
                  src={selectedWithdrawWallet.currency.image}
                  alt='Coin'
                  className='me-2'
                />
                {errors && errors.amount && (
                  <ErrorMessage
                    className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                    message={errors.amount.message}
                  />
                )}
              </div>
              <div className='modal-btn-wrap'>
                <button
                  type='submit'
                  className='btn btn-secondary'
                  disabled={loading}
                >
                  {loading
                    ? <Loader variant={LOADER_HANDLER_TYPES.submit} />
                    : <span>{t('request')}</span>}
                </button>
              </div>
            </div>
          </div>
          <ul className='bitcoin-instruction-list'>
            <li>
              {t('receiveTheSpecifiedAmount', {
                currency: selectedWithdrawWallet?.currency?.name
              })}
            </li>
            <li>{t('valueMayVary')}</li>
          </ul>
        </div>
      </form>
    </div>
  )
}

export default WithdrawCurrencySection
