import { toast } from 'react-toastify'
import { ToasterError, ToasterInfo, ToasterSuccess, ToasterWarning } from '../components/ui-kit/Icons/svg'

export const openErrorToaster = ({ message }) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    toastId: 'error',
    icon: ({ theme, type }) => <ToasterError />
  })
}

export const openSuccessToaster = ({ message }) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    toastId: 'success',
    icon: ({ theme, type }) => <ToasterSuccess />
  })
}

export const openInfoToaster = ({ message }) => {
  toast.info(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    toastId: 'info',
    icon: ({ theme, type }) => <ToasterInfo />
  })
}

export const openWarningToaster = ({ message }) => {
  toast.warn(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    toastId: 'warning',
    icon: ({ theme, type }) => <ToasterWarning />
  })
}
