import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { ROUTE_PATHS } from 'constants/index'
import SocialLinks from 'pages/Login/components/SocialLinks/index'
import { ClutchGameOn } from 'components/ui-kit/Icons/png/index'
import './index.scss'

const BannerSection = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { loginData } = useSelector((state) => state.auth)

  const registerHandler = () => {
    loginData?.accessToken
      ? history.push(ROUTE_PATHS.CASINO)
      : dispatch(setShowSignupPopup(true))
  }

  return (
    <div className='bannerSection'>
      <div>
        <img src={ClutchGameOn} className='bannerSection-image img-fluid d-block mx-auto mb-1 game-on-logo-img' alt='clutch-game-on' />
      </div>
      <div className='bannerSection-content d-flex flex-wrap mb-4'>
        <Link to={ROUTE_PATHS.HOME} className='text-uppercase flex-grow-1'>{t('play')}</Link>
        <Link to={ROUTE_PATHS.HOME} className='text-uppercase flex-grow-1'>{t('bet')}</Link>
        <Link to={ROUTE_PATHS.HOME} className='text-uppercase flex-grow-1'>{t('trade')}</Link>
        <Link to={ROUTE_PATHS.HOME} className='text-uppercase flex-grow-1'>{t('win')}</Link>
      </div>
      <div className='banner-btn-wrap'>
        <button
          type='button'
          className={`btn btn-secondary w-100 ${
              location.pathname === ROUTE_PATHS.CASINO ? 'd-none' : ''
            }`}
          onClick={registerHandler}
        >
          {loginData?.accessToken ? t('playNow') : t('registerNow')}
        </button>
        {!loginData?.accessToken && <SocialLinks isBannerSection />}
      </div>
    </div>
  )
}

export default React.memo(BannerSection)
