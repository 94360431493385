import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AboutUs = () => {
  const { siteInfo } = useSelector((state) => state.user)

  return (
    <ul className='m-0'>
      {siteInfo
        ?.filter((el) => el.cmsSection === 'aboutUs')
        ?.map((el) => (
          <li key={el.id}>
            <NavLink to={`/site-info/${el.slug}`}>
              {el.title}
            </NavLink>
          </li>
        ))}
    </ul>
  )
}

export default React.memo(AboutUs)
