import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import ErrorMessage from 'components/layout/ErrorMessage'
import { depositAmountRequest } from 'redux-thunk/thunk/user.thunk'
import Loader from 'components/ui-kit/Loader'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { setDepositData, setShowWalletPopup } from 'redux-thunk/redux/slices/wallet.slice'
import { restrictInputToNumeric } from 'utils/helperFunctions.utils'

export const Deposit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { depositData } = useSelector(state => state.wallet)
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const MAX_DEPOSIT_AMOUNT = 100000
  const MIN_DEPOSIT_AMOUNT = 100

  const schema = yup.object().shape({
    amount: yup.number()
      .typeError(t('amountIsRequired'))
      .required(t('amountIsRequired'))
      .moreThan(MIN_DEPOSIT_AMOUNT, t('minDepositAmount', { min: MIN_DEPOSIT_AMOUNT }))
      .lessThan(MAX_DEPOSIT_AMOUNT, t('maxDepositAmount', { max: MAX_DEPOSIT_AMOUNT }))
  })

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const handleDepositSubmit = (values) => {
    dispatch(depositAmountRequest({ values }))
  }

  useEffect(() => {
    if (depositData?.data?.pay_url) {
      window?.open(depositData?.data?.pay_url, '_blank')
      dispatch(setShowWalletPopup(false))
      dispatch(setDepositData())
    }
  }, [depositData?.data?.pay_url])

  return (
    <div className='tab-pane fade show active  text-white' id='pills-deposit' role='tabpanel' aria-labelledby='pills-home-tab'>
      <form onSubmit={handleSubmit(handleDepositSubmit)}>
        <div className='row g-2'>
          <div className='form-group required col-md-12 d-flex flex-column align-items-start'>
            <label className='control-label ms-3 mb-2 amount-input' htmlFor='amount'>{t('depositWithdrawTXAmount')}</label>
            <input
              type='text'
              onInput={(e) => restrictInputToNumeric(e)}
              className={`form-control ${errors?.amount ? 'error-input' : ''}`}
              id='amount'
              name='amount'
              autoComplete='off'
              maxLength={MAX_DEPOSIT_AMOUNT.toString().length + 1}
              {...register('amount')}
            />
            {errors && errors.amount && (
              <ErrorMessage
                className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                message={errors.amount.message}
              />
            )}
          </div>
          <div>
            <button
              type='submit'
              className='btn btn-primary mt-2 mb-3'
              style={{ width: '100%', lineHeight: '40px' }}
            >
              {loading
                ? (
                  <Loader variant={LOADER_HANDLER_TYPES.submit} />
                  )
                : (
                    t('submit')
                  )}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
