import { METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { SuccessMessage } from 'network/messages/successMessages'

export const placeBetCoinFlipGameService = ({ isDemoGame, ...data }) => {
  return axiosInstance(METHOD_TYPES.post, 'flip-coin-game/place-bet', data, {
    server: microServices.SERVICE_URL_1,
    headers: { 'demo-game': isDemoGame },
    successMessage: SuccessMessage.betPlacedSuccessfully
  })
}

export const getMyBetsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/flip-coin-game/my-bets', data, {
    params: data,
    server: microServices.SERVICE_URL_1
  })
}
