import * as PIXI from 'pixi.js'
import { DEFAULT_PLINKO_PINS } from "constants/index.js"
import { app } from "./appPixi.js";
import { config, isPixiStandAlone, path as basePath, gameDisplaySettings } from "./settings.js";
import { AnimationSprite, ObjectPool, countCharacters, getDirection, spriteSettings, updateBallRow } from "./utility.js";
import { distance } from "./gameLogic.js";
import {
  boxColor,
  boxNumbers,
  dispatching
} from "./bridge.js";

let ballPool;
let pegs = [];
let pegsForShine = [];
let balls = [];
let boxes = [];
let path = [];
let totalBalls = 0;
let uiCreated = false;
let boolBallCreated = false;
let isSetupBoxes = false;
let totalLines = isPixiStandAlone ? 16 : DEFAULT_PLINKO_PINS;
export let bgDestroyed = false;
let bgCreated = false;

let pegHolder;

const gameAssets = {
  background: {
    left: {
      one: new PIXI.Sprite(),
      two: new PIXI.Sprite(),
      three: new PIXI.Sprite(),
      four: new PIXI.Sprite(),
    },
    right: {
      one: new PIXI.Sprite(),
      two: new PIXI.Sprite(),
      three: new PIXI.Sprite(),
      four: new PIXI.Sprite(),
    },
  },
  //Fonts
  fonts: {
    regular: {},
  },
};

let pegsInRows = Array.from(
  { length: totalLines },
  (_, rowIndex) => rowIndex + config.pinInFirstLine
);
const containers = {
  rootContainer: new PIXI.Container(),
  bgContainer: null,
  pegsContainer: null,
  ballContainer: [],
  lightningContainer: new PIXI.Container(),
};

function createUI () {
  prepareContainers();
  createPegsHolder();
  createPegs(config.pegs);
  //createBall();
  setupBoxes();
  createBallsPool()
  uiCreated = true;
}
function createPegsHolder () {
  pegHolder = new PIXI.Sprite(PIXI.Assets.get('sqBG'));
  pegHolder.x = gameDisplaySettings.x;
  pegHolder.y = gameDisplaySettings.y;
  pegHolder.anchor.set(0.5);
  pegHolder.width = gameDisplaySettings.size;
  pegHolder.height = pegHolder.width;
  pegHolder.sortableChildren = true;

  // pegHolder.tint = 0x0f0f11;
  containers.rootContainer.addChild(pegHolder);
}

function prepareContainers () {
  app.stage.addChild(containers.rootContainer);
  containers.rootContainer.name = "RootContainer";
  containers.ballContainer.name = "BallContainer";
  containers.rootContainer.position.set(0, 80);
}

function createPegs (pegConfig) {
  let pegNo = 0;
  for (let row = 0; row < totalLines; row++) {
    const numPegs = pegsInRows[row];
    const singleRowPegsContainer = new PIXI.Container();
    let currentPeg = [];
    for (let i = 0; i < numPegs; i++) {
      const peg = {};

      peg.sprite = new PIXI.Sprite(PIXI.Assets.get("pl_pin"));
      peg.sprite.width = config.pegs.pegsWidth + (16 - totalLines) * 6;
      peg.sprite.height = config.pegs.pegsWidth + (16 - totalLines) * 6;
      peg.sprite.anchor.set(0.5);
      peg.sprite.x =
        (i - (numPegs - 1) / 2) *
        ((config.pegs.spacing.width * 16) / totalLines);
      if (row == 0)
        peg.sprite.y =
          row * ((config.pegs.spacing.height * 16) / totalLines) -
          config.topMargin;
      else
        peg.sprite.y =
          (row * (config.pegs.spacing.height - totalLines * 0.45) * 16) /
          totalLines -
          config.topMargin;

      pegNo++;

      peg.states = [];
      peg.shine = new PIXI.AnimatedSprite(
        PIXI.Assets.get("shineEffect").animations.frames
      );
      peg.shine.scale.set(1);  // 1
      peg.shine.alpha = 0.3   // 0.55
      peg.shine.animationSpeed = config.pegs.shine.animationSpeed; //0.7
      peg.shine.visible = false;
      peg.startShine = () => {
        peg.shine.play();
        peg.shine.visible = true;
      };
      peg.shine.onLoop = () => {
        peg.shine.gotoAndStop(1);
        peg.shine.visible = false;
      };

      peg.shine.anchor.set(0.48, 0.58);
      peg.sprite.addChild(peg.shine);

      singleRowPegsContainer.addChild(peg.sprite);
      pegs.push(peg);
      currentPeg.push(peg);
    }
    pegsForShine.push(currentPeg);
    currentPeg = [];
    pegHolder.addChild(singleRowPegsContainer);
  }

}

// let ballScaleForAnimation = 1.9
const globalPoint = new PIXI.Point(0, 0);

function createBallsPool () {
  ballPool = new ObjectPool(() => new AnimationSprite(pegHolder, pegs[0].sprite.y), 50)
}

function animatedBallCreate (testPathAnim) {
  let animatedBallSprite = ballPool.getObject();
  animatedBallSprite.setVisiblity(true)
  animatedBallSprite.animationSprite.scale.set(
    getDirection(testPathAnim, 0) * config.ball.scaleForAnimation[totalLines - 8] * config.ball.ballScale,
    config.ball.scaleForAnimation[totalLines - 8] * config.ball.ballScale
  );
  animatedBallSprite.animationSprite.drop = animatedBallSprite.moveBall;
  animatedBallSprite.animationSprite.onLoop = () => animatedBallSprite.onLoop(testPathAnim);

  balls.push(animatedBallSprite.animationSprite);
}

function createBall () {
  totalBalls++;
  animatedBallCreate(path[totalBalls - 1])
}

function setupBoxes () {
  const boxScale = 1.2;

  let boxHeight = config.box.height - totalLines * config.box.heightScale
  let boxWidth = config.box.width - totalLines * config.box.widthScale;

  for (let box = 0; box < pegsInRows[totalLines - 1] - 1; box++) {
    let sprite = new PIXI.Graphics()
    pegHolder.addChild(sprite)
    sprite.lineStyle(5, 0xFFFFFF, 1);
    sprite.beginFill(0xFFFFFF, boxColor.alpha);
    sprite.drawRoundedRect(-boxWidth / 2, -boxHeight / 2, boxWidth, boxHeight, 15);
    sprite.endFill();

    sprite.scale.set(boxScale)
    sprite.x =
      (box - (pegsInRows[totalLines - 1] - 2) / 2) *
      ((config.pegs.spacing.width * 16) / totalLines);
    sprite.y = sprite.startPos = config.topMargin;
    boxes.push({
      sprite: sprite
    });
    sprite.boxColor = box.white
    sprite.boxState = sprite.doNothing
    sprite.BoxBounceEffect = (delta) => {
      if (sprite.y > sprite.startPos)
        sprite.y -= delta * config.box.boxSpeed
      else {
        sprite.y = sprite.startPos
        sprite.boxState = sprite.doNothing
      }
    }



    let text = new PIXI.Text(
      "",
      new PIXI.TextStyle({
        fill: ["#ffffff"],
        fontSize: totalLines > 12 ? 32 : 42,
        fontWeight: "bold",
      })
    );
    text.anchor.set(0.5);
    sprite.addChild(text)
    text.alpha = 1;

    if (box < boxNumbers.Red || box == pegsInRows[totalLines - 1] - 1 - boxNumbers.Red)
      sprite.tint = boxColor.red
    else if (box < boxNumbers.Orange || box == pegsInRows[totalLines - 1] - 1 - boxNumbers.Orange)
      sprite.tint = boxColor.orange
    else if (box < boxNumbers.Pink || box >= pegsInRows[totalLines - 1] - 1 - boxNumbers.Pink)
      sprite.tint = boxColor.pink
    else if (box < boxNumbers.Purple || box >= pegsInRows[totalLines - 1] - 1 - boxNumbers.Purple)
      sprite.tint = boxColor.purple
    else if (box < boxNumbers.Blue || box >= pegsInRows[totalLines - 1] - 1 - boxNumbers.Blue)
      sprite.tint = boxColor.blue
    else if (box < boxNumbers.Grey || box >= pegsInRows[totalLines - 1] - 1 - boxNumbers.Grey)
      sprite.tint = boxColor.grey
    else
      sprite.tint = boxColor.green

    sprite.boxColor = sprite.tint
  }
  isSetupBoxes = true

}

function setupPayoutText (payout) {
  for (let i = 0; i < boxes.length; i++) {
    if (payout[i] || payout[i] === 0) boxes[i].sprite.children[0].text = 'x' + payout[i]
    else console.error("Sent payout is not a valid number")
  }
}


function startMovingBall (ballPath) {
  path.push([...ballPath]);
}

function changeNumberOfLines (reqLines) {
  isSetupBoxes = false;
  totalLines = reqLines;

  function destroyBgContianer () {
    pegHolder.destroy({
      children: true,
    });
  }
  function createpinsContainer () {
    resetUI();
  }
  function resetValues () {
    containers.ballContainer = [];
    pegs = [];
    boxes = [];
    pegsForShine = [];
    pegsInRows = Array.from(
      { length: totalLines },
      (_, rowIndex) => rowIndex + config.pinInFirstLine
    );
  }
  bgDestroyed = true;
  destroyBgContianer();
  resetValues();
  createpinsContainer();
}

function clearAllBalls () {
  totalBalls = 0;
  containers.ballContainer.forEach((ballContainer) => {
    ballContainer.destroyBall();
  });

  path = [];
}

function scaleFactor () {
  return (config.maxLines + 16) / (totalLines + 16);
}

function gameScaling () {
  let rhs = 1.4 - Math.floor((totalLines - 8) / 2) * 0.1;
  rhs = rhs.toFixed(1);
  containers.rootContainer.scale.set(scaleFactor() * rhs);
}

function boxBounceEffect (index) {
  const box = boxes[index];

  if (!box) return;
  box.sprite.y += 40;  // box.sprite.startPos;
  box.sprite.boxBounceEffectState = box.sprite.BoxBounceEffect;
}

function lightningModeUI (lightData) {
}

function removeLightningMode () {
}

function normalModeUI () {
  pegs.forEach((p) => {
    p.sprite.tint = boxColor.white;

  });
}

function setupScullBoxes (isScull) {
}

function resetUI () {
  createPegsHolder();
  createPegs(config.pegs);
  setupBoxes();
  createBallsPool()
}

export {
  containers,
  createUI,
  uiCreated,
  createBall,
  startMovingBall,
  changeNumberOfLines,
  clearAllBalls,
  setupPayoutText,
  isSetupBoxes,
  boxBounceEffect,
  boolBallCreated,
  totalBalls,
  balls,
  pegs,
  boxes,
  removeLightningMode,
  normalModeUI,
  lightningModeUI,
  gameAssets,
  ballPool,
  pegsForShine,
  totalLines
};

isPixiStandAlone && divButtons();

function divButtons () {
  const createBallDiv = document.createElement("button");
  createBallDiv.innerHTML = "createBall";
  createBallDiv.onclick = () => createBall();
  document.body.appendChild(createBallDiv);

  const boxBounceDiv = document.createElement("button");
  boxBounceDiv.innerHTML = "boxBounce";
  boxBounceDiv.onclick = () =>
    boxBounceEffect(parseInt(prompt("Enter number of boxBounce")));
  document.body.appendChild(boxBounceDiv);

  const linesDiv = document.createElement("button");
  linesDiv.innerHTML = "lines";
  linesDiv.onclick = () =>
    changeNumberOfLines(parseInt(prompt("Enter number of lines")));
  document.body.appendChild(linesDiv);

  const lightningModeDiv = document.createElement("button");
  lightningModeDiv.innerHTML = "lightningMode";
  lightningModeDiv.onclick = () => pixiLightningMode();
  document.body.appendChild(lightningModeDiv);

  const normalModeDiv = document.createElement("button");
  normalModeDiv.innerHTML = "normalMode";
  normalModeDiv.onclick = () => removeLightningMode();
  document.body.appendChild(normalModeDiv);

  const clearAllBallDiv = document.createElement("button");
  clearAllBallDiv.innerHTML = "clearAllBall";
  clearAllBallDiv.onclick = () => clearAllBalls();
  document.body.appendChild(clearAllBallDiv);

  const ballPathOneDiv = document.createElement("button");
  ballPathOneDiv.innerHTML = "ballPathOne";
  ballPathOneDiv.onclick = () => startMovingBall(testPath);
  document.body.appendChild(ballPathOneDiv);

  const ballPathTwoDiv = document.createElement("button");
  ballPathTwoDiv.innerHTML = "ballPathTwo";
  ballPathTwoDiv.onclick = () => startMovingBall(testPath1);
  document.body.appendChild(ballPathTwoDiv);

  const ballPathThreeDiv = document.createElement("button");
  ballPathThreeDiv.innerHTML = "ballPathThree";
  ballPathThreeDiv.onclick = () => startMovingBall(testPath2);
  document.body.appendChild(ballPathThreeDiv);

  const ballPathFourDiv = document.createElement("button");
  ballPathFourDiv.innerHTML = "ballPathFour";
  ballPathFourDiv.onclick = () => startMovingBall(testPath3);
  document.body.appendChild(ballPathFourDiv);
}
