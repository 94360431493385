// // Comment these three lines in standalone mode
import * as PIXI from 'pixi.js'
import { countOnes } from 'utils/helperFunctions.utils.js'
import { appendMyBetsPlinko, setBetLock } from 'redux-thunk/redux/slices/plinkoGame.slice.js'
import store from 'redux-thunk/store'
import { DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from 'constants/index.js'
import { boxBounceEffect, changeNumberOfLines, clearAllBalls, createBall, createUI, lightningModeUI, normalModeUI, removeLightningMode, setupPayoutText, startMovingBall } from "./gameUI.js";
import { playBGMSound, playLeftDropSound, playOrangeBoxSound, playRedBoxSound, playRightDropSound, playYellowBoxSound } from "./soundManager.js";
import { isPixiStandAlone } from "./settings.js";
import { reflectCreditQueueAmount } from 'redux-thunk/redux/slices/user.slice.js';

export const pixiCreateBall = () => createBall()

export const pixiStartMovingBall = (ballPath) => {
  const ballPathArray = ballPath.split('').map(ele => +ele)
  startMovingBall(ballPathArray)
}

export const pixiCancelBall = () => clearAllBalls()

export const pixiChangeNumberOfLines = (lines) => changeNumberOfLines(lines)

export const pixiSetPayouts = (payouts) => setupPayoutText(payouts)

export const boxNumbers = {
  Red: 1,
  Orange: 2,
  Pink: 3,
  Purple: 4,
  Blue: 5,
  Grey: 6,
  Green: 7
}

export const boxColor = {
  yellow: 0xFFBD00,
  orange: 0xBB1CBC,
  red: 0xFD2E6F,
  white: 0xFFFFFF,
  pink: 0x820DFE,
  green: 0x0493ED,
  purple: 0x8841E8,
  blue: 0x4553FC,
  grey: 0x6986EF,
  alpha: 0.6
}

export const fastModeBox = (dropDetails) => {
  // Comment these lines in standalone mode
  const boxNumber = countOnes(dropDetails)
  boxBounceEffect(boxNumber)
}

export const dispatching = () => {
  // Comment this line in standalone mode
  if (!isPixiStandAlone) {
    dispatchRoundEnd()
  }
  store.dispatch(setBetLock())
}

export const dispatchRoundEnd = () => {
  store.dispatch(appendMyBetsPlinko())
  store.dispatch(reflectCreditQueueAmount())
}

export const pixiLightningMode = (
  ballMultipliers = DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  payouts = DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
) => {
}

export const pixiNormalMode = (payouts) => {
  removeLightningMode()
  pixiSetPayouts(payouts)
}

// Sounds
export const playBGM = () => {
  // Background Music
  playBGMSound()
}
export const playRightDrop = () => {
  playRightDropSound()
}
export const playLeftDrop = () => {
  playLeftDropSound()
}
export const playYellowBox = () => {
  playYellowBoxSound()
}
export const playOrangeBox = () => {
  playOrangeBoxSound()
}
export const playRedBox = () => {
  playRedBoxSound()
}
