import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { setShowOtpPopup } from 'redux-thunk/redux/slices/settings.slice'
import { verifyOtp } from 'redux-thunk/thunk/auth.thunk'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import ErrorMessage from 'components/layout/ErrorMessage'
import { useTranslation } from 'react-i18next'

const OtpPopup = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const schema = yup.object().shape({
    otp: yup
      .string()
      .required(t('otpIsRequired'))
      .length(6, t('otpMustBeSixDigits'))
  })

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const handleOtpSubmit = (data) => {
    dispatch(verifyOtp({ OTP: data.otp }))
  }

  const modalCloseHandler = useCallback(() => {
    dispatch(setShowOtpPopup(false))
  }, [])

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      classes=''
      id='otpModal'
      modalBodyClasses='p-0'
    >
      <div className='pt-4 pb-0 px-4'>
        <h4 className='text-uppercase'>
          {t('enterOtp')}
        </h4>
      </div>
      <form onSubmit={handleSubmit(handleOtpSubmit)}>
        <div className='px-4'>
          <div className='form-group mb-2'>
            <div className='mb-2'>
              <input
                type='number'
                className={`form-control form-control-dark shadow-none ${errors?.otp ? 'error-input' : ''}`}
                id='otp'
                name='otp'
                placeholder={t('enterOtp')}
                {...register('otp', { valueAsNumber: true })}
              />
              {errors && errors.otp && (
                <div className='error-container'>
                  <ErrorMessage
                    className='text-danger error-msg'
                    message={errors.otp.message}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='modal-footer border-0 px-4'>
          <button
            type='submit'
            className='btn btn-secondary text-uppercase'
          >
            <span className='text-uppercase'>{t('verifyOtp')}</span>
          </button>
        </div>
      </form>
    </CommonPopup>
  )
}

export default OtpPopup
