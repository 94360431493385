import { signOut } from 'helpers/cookie.helpers'
import { openErrorToaster } from 'helpers/toaster.helpers'
import { getTranslation } from 'helpers/translations.helpers'
import { store } from 'App'
import errorMessages from 'network/messages/errorMessages'
import { stopLoader } from 'redux-thunk/redux/slices/loader.slice'
import { setLoginData } from 'redux-thunk/redux/slices/auth.slice'

export const errorHandler = (error) => {
  if (error.response.status === 500) {
    // Snackbar Internal Server Error
    openErrorToaster({ message: getTranslation(errorMessages.internalServerError) })
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.status === 401) {
    // Snackbar UnAuthed
    openErrorToaster({ message: getTranslation(errorMessages.unAuthorized) })
    signOut()
    store.dispatch(setLoginData(null))
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.status === 429) {
    openErrorToaster({ message: getTranslation(errorMessages.tooManyRequests) })
    store.dispatch(stopLoader(error.response.config.loader))
  } else if (error.response.config?.loader) {
    // Other errors
    store.dispatch(stopLoader(error.response.config.loader))
  }
  // Open Error Toaster
  const errorMessage = error.response.data.errors[0].description
  openErrorToaster({ message: errorMessage })
  return Promise.reject(error.response.data.errors)
}
