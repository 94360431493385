import React, { useMemo } from 'react'
import './index.scss'
import CasinoCard from '../CasinoCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

const CasinoCardSlider = (props) => {
  const { items, id } = props

  const homePromotionsSettings = useMemo(
    () => ({
      modules: [Navigation],
      navigation: {
        nextEl: `.${id}-next`,
        prevEl: `.${id}-prev`
      },
      slidesPerView: 'auto'
    }),
    []
  )
  return (
    <div className='casino-card-slider'>
      <Swiper {...homePromotionsSettings}>
        {items.map((item, key) => (
          <SwiperSlide key={`item-${key}`}>
            <div className='casino-card-slider-slide col'>
              <CasinoCard {...item} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default React.memo(CasinoCardSlider)
