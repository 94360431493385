import { createSlice } from '@reduxjs/toolkit'
import { transactionType } from 'constants/index'
import { cryptoBetPlace, cryptoCoins24hDataThunk, getBetPlaceTransaction, getBetTransactionDetail, getCryptoCoins, updateBetDetail } from 'redux-thunk/thunk/crypto.thunk'

const defaultCryptoState = {
  cryptoData: null,
  betLoader: false,
  betPlaceTransaction: [],
  activePlaceTransaction: [],
  closedPlaceTransaction: [],
  publicBetTransaction: [],
  betTransactionDetail: '',
  updateBet: '',
  cryptoCoinsList: [],
  showHowItWorksPopup: false,
  showRoiCalculator: false,
  showLimitModal: false,
  cryptoCoins24hData: [] // it includes volume, high, low etc.
}

const cryptoSlice = createSlice({
  name: 'cryptoSlice',
  initialState: defaultCryptoState,
  reducers: {
    setShowHowItWorksPopup: (state, action) => ({
      ...state,
      showHowItWorksPopup: action.payload
    }),
    setShowRoiCalculatorPopup: (state, action) => ({
      ...state,
      showRoiCalculator: action.payload
    }),
    setShowLimitPopup: (state, action) => ({
      ...state,
      showLimitModal: action.payload
    }),
    setPublicBetRemove: (state, action) => ({
      ...state,
      publicBetTransaction: action.payload
    }),
    setActiveBetRemove: (state, action) => ({
      ...state,
      activePlaceTransaction: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCryptoCoins.fulfilled, (state, action) => {
        state.cryptoCoinsList = action?.payload
      })
      .addCase(cryptoBetPlace.fulfilled, (state, action) => {
        return {
          ...state,
          cryptoData: action.payload
        }
      })
      .addCase(cryptoBetPlace.pending, (state, action) => {
        const { password, userNameOrEmail } = action.meta.arg
        return {
          ...state,
          cryptoData: { password, userNameOrEmail }
        }
      })
      .addCase(updateBetDetail.fulfilled, (state, action) => {
        return {
          ...state,
          updateBet: action.payload
        }
      })
      .addCase(getBetPlaceTransaction.pending, (state, action) => {
        return {
          ...state,
          betLoader: true
        }
      })
      .addCase(getBetPlaceTransaction.fulfilled, (state, action) => {
        if (action.payload.name === transactionType.PUBLIC_BETS) {
          return {
            ...state,
            publicBetTransaction: action?.payload?.publicBets,
            betLoader: false
          }
        } else if (action.payload.name === transactionType.ACTIVE_BETS) {
          return {
            ...state,
            activePlaceTransaction: action?.payload?.activeBets,
            betLoader: false
          }
        } else if (action.payload.name === transactionType.CLOSED_BETS) {
          return {
            ...state,
            closedPlaceTransaction: action?.payload?.closedBets,
            betLoader: false
          }
        } else {
          return {
            ...state,
            betPlaceTransaction: action?.payload?.leaderboard,
            betLoader: false
          }
        }
      })
      .addCase(getBetTransactionDetail.fulfilled, (state, action) => {
        return {
          ...state,
          betTransactionDetail: action.payload
        }
      })
      .addCase(cryptoCoins24hDataThunk.fulfilled, (state, action) => {
        return {
          ...state,
          cryptoCoins24hData: action.payload
        }
      })
  }
})

export const {
  setShowHowItWorksPopup,
  setShowRoiCalculatorPopup,
  setShowLimitPopup,
  setPublicBetRemove,
  setActiveBetRemove
} = cryptoSlice.actions

export default cryptoSlice.reducer
