import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'
import { SuccessMessage } from 'network/messages/successMessages'
import axios from 'axios'

export const cryptoBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, `${!data.instrumentId ? '/roller-coaster-game/place-bet' : '/crypto-futures-game/place-bet'}`, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.betPlacedSuccessfully
  })
}

export const cryptoCashoutService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, `${!data.instrumentId ? '/roller-coaster-game/cash-out-bet' : '/crypto-futures-game/cash-out'}`, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.betCashedOut
  })
}

export const getBetPlaceTransactionService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, `${data.rollercoaster ? '/roller-coaster-game/bets' : '/crypto-futures-game/bets'}`, {}, {
    server: microServices.SERVICE_URL_1,
    params: data
  })
}

export const getBetTransactionDetailService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, `${!data.instrumentId ? '/roller-coaster-game/bet-detail' : '/crypto-futures-game/bet-detail'}`, {}, {
    server: microServices.SERVICE_URL_1,
    params: data
  })
}

export const updateBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.patch, `${!data.instrumentId ? '/roller-coaster-game/bet-update' : '/crypto-futures-game/bet-update'}`, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const cryptoCoinsService = () => {
  return axiosInstanceService(METHOD_TYPES.get, '/crypto-futures-game/instruments', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  }
  )
}

export const cryptoCoins24hDataService = () => {
  return axios.get('https://fapi.binance.com/fapi/v1/ticker/24hr')
}
