import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import config from 'config/app.config'
import NAMESPACES from 'socket-resources/namespaces/index'
import { WALLET_UPDATE_EVENT } from 'socket-resources/event-constants/wallet/index'

const useWalletSocketNameSpace = () => {
  const { loginData } = useSelector(state => state.auth) // Fetch the latest token

  const listenWalletUpdateEvent = (cb) => {
    const walletSocketInstance = io(`${config.SOCKET_URL}${NAMESPACES.WALLET}`, {
      auth: {
        authorization: `Bearer ${loginData?.accessToken}`
      }
    })
    walletSocketInstance.on('connect', () => {
      // console.log('Connected to WALLET namespace with new token')
    })

    walletSocketInstance.on('disconnect', () => {
      // console.log('Socket disconnected from WALLET namespace')
    })
    if (cb) {
      walletSocketInstance.on(WALLET_UPDATE_EVENT, cb)
    }
    return () => {
      walletSocketInstance?.off(WALLET_UPDATE_EVENT, cb)
      walletSocketInstance?.disconnect()
    }
  }

  return { listenWalletUpdateEvent }
}

export default useWalletSocketNameSpace
