import { useRef } from 'react'

const useSlideGesture = ({ containerRef }) => {
  const isDragging = useRef(false)
  const startX = useRef(0)
  const scrollLeft = useRef(0)

  const handleMouseDown = (e) => {
    isDragging.current = true
    startX.current = e.pageX - containerRef.current.offsetLeft
    scrollLeft.current = containerRef.current.scrollLeft
    containerRef.current.classList.add('nav-scroll-drag')
  }

  const handleMouseUp = () => {
    isDragging.current = false
    containerRef.current.classList.remove('nav-scroll-drag')
  }

  const handleMouseLeave = () => {
    isDragging.current = false
    containerRef.current.classList.remove('nav-scroll-drag')
  }

  const handleMouseMove = (e) => {
    if (!isDragging.current) return
    e.preventDefault()
    const base = e.pageX - containerRef.current.offsetLeft
    const walk = base - startX.current
    containerRef.current.scrollLeft = scrollLeft.current - walk
  }

  return {
    onMouseUp: handleMouseUp,
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseLeave: handleMouseLeave
  }
}

export default useSlideGesture
