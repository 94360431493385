import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Community = () => {
  const { t } = useTranslation()

  return (
    <ul className='m-0'>
      <li>
        <NavLink to='#'>{t('facebook')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('twitter')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('instagram')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('discord')}</NavLink>
      </li>
    </ul>
  )
}

export default React.memo(Community)
