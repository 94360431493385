import React from 'react'
import { MinusIcon, PlusIcon } from '../Icons/svg/index'
import { ACCORDION_BUTTON_COLOR } from 'constants/index'

const AccordionItem = (props) => {
  const {
    accordionId,
    isCollapseAccordion,
    accordionClickHandler,
    title,
    children,
    buttonColor
  } = props

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header'>
        <button
          className={`accordion-button ${
                  isCollapseAccordion[accordionId]
                    ? 'accordionOpen'
                    : 'accordionClose'
                }`}
          type='button'
          onClick={() => accordionClickHandler(accordionId)}
        >
          {title}
          <div className='accordion-icon'>
            {isCollapseAccordion[accordionId]
              ? (
                  buttonColor === ACCORDION_BUTTON_COLOR.GREY
                    ? <MinusIcon />
                    : <MinusIcon stroke='var(--white)' width={26} height={26} />
                )
              : (
                  buttonColor === ACCORDION_BUTTON_COLOR.GREY
                    ? <PlusIcon />
                    : <PlusIcon stroke='var(--white)' width={26} height={26} />
                )}
          </div>
        </button>
      </h2>
      {isCollapseAccordion[accordionId] && (
        <div className='accordion-animation'>
          <div className='accordion-body'>
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

export default AccordionItem
