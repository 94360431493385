import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import Footer from '../Footer/index'
import './mainLayout.scss'
// import { cryptoToFiat } from 'utils/helperFunctions.utils'

const MainLayout = ({ showFooter, children }) => {
  const { showSideNav, showChat, showNotification } = useSelector(
    (state) => state?.settings
  )// isChatCollapse
  const location = useLocation()

  const casinoRegexPattern = `${ROUTE_PATHS.CASINO_PLAYGAME}`.replace(':gameName', '([^/]+)')
  const casinoRegex = new RegExp(`${casinoRegexPattern}$`)
  const isCasinoPlayGame = casinoRegex.test(location.pathname)
  const fullWithLayout = !!location.pathname.match(`${ROUTE_PATHS.CRYPTO}/`) ||
    isCasinoPlayGame ||
    location.pathname === ROUTE_PATHS.SPORTS_BOOK

  return (
    <>
      <div
        className={`page-container ${!showSideNav ? 'sidebar-close' : ''}
          ${(showChat || showNotification) ? 'chat-parent chat-active' : ''}`}
      >
        <div
          className={`${!fullWithLayout ? 'custom-spacer' : ''}
            content-wrapper
            ${location.pathname === ROUTE_PATHS.SPORTS_BOOK ? 'sportsbookwrap' : ''}
            ${location.pathname.includes('crypto') ? 'crypto-main-wrapper' : ''}`}
          style={{ minHeight: 'calc(100vh - 370px)' }}
        >
          {children}
        </div>
        {showFooter ? <Footer /> : <></>}
      </div>
    </>
  )
}

export default MainLayout
